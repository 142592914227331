import apiHelperFactory from "@/http/apiHelperFactory";
import helpers from "@/plugins/helpers";

const suspenseAccountTransactionApi = {
  namespaced: true,

  state: {
    // showDialog
    showDialog: false,

    // dialogStep
    dialogStep: 1,

    // action
    action: "",

    // suspenseAccountTransactionId
    suspenseAccountTransactionId: 0,

    // suspenseAccountTransaction
    suspenseAccountTransaction: {},

    // suspenseAccountTransactions
    suspenseAccountTransactions: [],

    searchQuery: "",
    approverRoles: "",
    selectedCategory: "Uncategorised",
    index: null,
  },

  mutations: {
    // setShowDialog
    setShowDialog(state, payload) {
      state.showDialog = payload;
    },

    // setShowDialog
    setSearchQuery(state, payload) {
      state.searchQuery = payload;
    },
    // setShowDialog
    resetSearchQuery(state) {
      state.searchQuery = "";
    },
    // setShowDialog
    setIndex(state, payload) {
      state.index = payload;
    },
    // setShowDialog
    resetIndex(state) {
      state.index = null;
    },

    // setShowDialog
    setSelectedCategory(state, payload) {
      state.selectedCategory = payload;
    },
    // setShowDialog
    resetSelectedCategory(state) {
      state.selectedCategory = "Uncategorised";
    },

    // setShowDialog
    setApproverRoles(state, payload) {
      state.approverRoles = payload;
    },
    // setShowDialog
    resetApproverRoles(state) {
      state.approverRoles = "";
    },

    // setDialogStep
    setDialogStep(state, payload) {
      state.dialogStep = payload;
    },

    // setAction
    setAction(state, payload) {
      state.action = payload;
    },

    // setSuspenseAccountTransactionId
    setSuspenseAccountTransactionId(state, payload) {
      state.suspenseAccountTransactionId = payload;
    },

    // setSuspenseAccountTransaction
    setSuspenseAccountTransaction(state, payload) {
      state.suspenseAccountTransaction = payload;
    },

    // setSuspenseAccountTransactions
    setSuspenseAccountTransactions(state, payload) {
      state.suspenseAccountTransactions = payload;
    },

    // resetState
    resetState(state) {
      // showDialog
      state.showDialog = false;

      // dialogStep
      state.dialogStep = false;

      // action
      state.action = "";

      // suspenseAccountTransactionId
      state.suspenseAccountTransactionId = 0;

      // suspenseAccountTransaction
      state.suspenseAccountTransaction = {};

      // suspenseAccountTransactions
      state.suspenseAccountTransactions = [];
    },
  },

  actions: {
    // getSuspenseAccountTransactionsReq
    async getSuspenseAccountTransactionsReq({ commit }, payload = {}) {
      // useLoading
      const useLoading = "useLoading" in payload ? payload.useLoading : true;

      // useLoading
      if (useLoading) {
        // startLoading
        commit("app/startLoading", null, { root: true });
      }

      // setUseAppToken
      commit("auth/setUseAppToken", true, { root: true });

      try {
        // getSuspenseAccountTransactionsRes
        const getSuspenseAccountTransactionsRes =
          await apiHelperFactory.getSuspenseAccountTransactions(
            payload.officeId
          );

        // setSuspenseAccountTransactions
        commit(
          "setSuspenseAccountTransactions",
          getSuspenseAccountTransactionsRes.data
        );

        return getSuspenseAccountTransactionsRes;
      } catch (error) {
        if (error.response) {
          // handle response error
        } else if (error.request) {
          // handle request error
        } else {
          // handle other errors
        }
      } finally {
        // useLoading
        if (useLoading) {
          // stopLoading
          commit("app/stopLoading", null, { root: true });
        }

        // setUseAppToken
        commit("auth/setUseAppToken", false, { root: true });
      }
    },

    // createSuspenseAccountTransactionReq
    async createSuspenseAccountTransactionReq({ commit }, payload = {}) {
      // useLoading
      const useLoading = "useLoading" in payload ? payload.useLoading : true;

      // useLoading
      if (useLoading) {
        // startLoading
        commit("app/startLoading", null, { root: true });
      }

      // setUseAppToken
      commit("auth/setUseAppToken", true, { root: true });

      try {
        return await apiHelperFactory.createSuspenseAccountTransaction(
          payload.body,
          payload.officeId
        );
      } catch (error) {
        // error.response
        if (error.response) {
          let res = error.response;

          if (res.status === 400) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 401) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 403) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 404) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 500) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else {
            // handleError
            helpers.handleError(res.status, res.data);
          }
        } else {
          // handleError
          helpers.handleError();
        }
      } finally {
        // useLoading
        if (useLoading) {
          // stopLoading
          commit("app/stopLoading", null, { root: true });
        }

        // setUseAppToken
        commit("auth/setUseAppToken", false, { root: true });
      }
    },

    // updateSuspenseAccountTransactionReq
    async updateSuspenseAccountTransactionReq({ commit }, payload = {}) {
      // useLoading
      const useLoading = "useLoading" in payload ? payload.useLoading : true;

      // useLoading
      if (useLoading) {
        // startLoading
        commit("app/startLoading", null, { root: true });
      }

      // setUseAppToken
      commit("auth/setUseAppToken", true, { root: true });

      try {
        return await apiHelperFactory.updateSuspenseAccountTransaction(
          payload.body,
          payload.officeId,
          payload.suspenseAccountTransactionId
        );
      } catch (error) {
        // error.response
        if (error.response) {
          let res = error.response;

          if (res.status === 400) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 401) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 403) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 404) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 500) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else {
            // handleError
            helpers.handleError(res.status, res.data);
          }
        } else {
          // handleError
          helpers.handleError();
        }
      } finally {
        // useLoading
        if (useLoading) {
          // stopLoading
          commit("app/stopLoading", null, { root: true });
        }

        // setUseAppToken
        commit("auth/setUseAppToken", false, { root: true });
      }
    },

    // deleteSuspenseAccountTransactionReq
    async deleteSuspenseAccountTransactionReq({ commit }, payload = {}) {
      // useLoading
      const useLoading = "useLoading" in payload ? payload.useLoading : true;

      // useLoading
      if (useLoading) {
        // startLoading
        commit("app/startLoading", null, { root: true });
      }

      // setUseAppToken
      commit("auth/setUseAppToken", true, { root: true });

      try {
        return await apiHelperFactory.deleteSuspenseAccountTransaction(
          payload.officeId,
          payload.suspenseAccountTransactionId
        );
      } catch (error) {
        // error.response
        if (error.response) {
          let res = error.response;

          if (res.status === 400) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 401) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 403) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 404) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 500) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else {
            // handleError
            helpers.handleError(res.status, res.data);
          }
        } else {
          // handleError
          helpers.handleError();
        }
      } finally {
        // useLoading
        if (useLoading) {
          // stopLoading
          commit("app/stopLoading", null, { root: true });
        }

        // setUseAppToken
        commit("auth/setUseAppToken", false, { root: true });
      }
    },
  },

  getters: {
    // getShowDialog
    getShowDialog(state) {
      return state.showDialog;
    },

    getSelectedCategory(state) {
      return state.selectedCategory;
    },

    // getDialogStep
    getDialogStep(state) {
      return state.dialogStep;
    },

    // getAction
    getAction(state) {
      return state.action;
    },

    // getSuspenseAccountTransactionId
    getSuspenseAccountTransactionId(state) {
      return state.suspenseAccountTransactionId;
    },

    // getSuspenseAccountTransaction
    getSuspenseAccountTransaction(state) {
      return state.suspenseAccountTransaction;
    },

    // getSuspenseAccountTransactions
    getSuspenseAccountTransactions(state) {
      return state.suspenseAccountTransactions;
    },

    // getSuspenseAccountTransactionById
    getSuspenseAccountTransactionById: (state) => (id) => {
      // foundSuspenseAccountTransaction
      const foundSuspenseAccountTransaction =
        state.suspenseAccountTransactions.find(
          (suspenseAccountTransaction) => suspenseAccountTransaction.id === id
        );

      return foundSuspenseAccountTransaction
        ? foundSuspenseAccountTransaction
        : null;
    },

    // getTotalSuspenseAccountTransactions
    getTotalSuspenseAccountTransactions(state) {
      return state.suspenseAccountTransactions.length;
    },

    // getMappedSuspenseAccountTransactions
    getMappedSuspenseAccountTransactions:
      (state, getters, rootState, rootGetters) =>
      ({
        orderBy = "id",
        sortBy = "desc",
        offset = 0,
        limit = 10,
        categoryFilter = null,
        categoryFilterEquals = true,
        allocatedToFilter = null,
        allocatedToFilterEquals = true,
        searchQuery = state.searchQuery,
        approverRole = state.approverRoles,
      } = {}) => {
        // Filter by category if categoryFilter is provided
        const filteredTransactions = state.suspenseAccountTransactions.filter(
          (transaction) => {
            const transactionCategory = rootGetters[
              "codeValueApi/getNameOfCodeValue"
            ](
              "SuspenseAccountTransactionCategories",
              transaction["SuspenseAccountTransactionCategories_cd_category"]
            );
            const transactionDescription =
              transaction.description.toLowerCase();
            const transactionAmount = transaction.amount.toString();
            const transactionAllocatedTo = transaction.allocated_to;
            const query = searchQuery.toLowerCase();
            const querySelect = approverRole;
            let querySelectMatch;

            if (querySelect !== "All") {
              querySelectMatch = querySelect
                ? transactionCategory.includes(querySelect) ||
                  transactionCategory.includes(querySelect)
                : true;
            } else {
              querySelectMatch = transactionCategory;
            }

            const searchMatch = query
              ? transactionDescription.includes(query) ||
                transactionAllocatedTo.includes(query) ||
                transactionAmount.includes(query) ||
                transaction.reference_id.includes(query)
              : true;

            const categoryMatch = categoryFilter
              ? categoryFilterEquals
                ? transactionCategory === categoryFilter
                : transactionCategory !== categoryFilter
              : true;
            const allocatedToMatch = allocatedToFilter
              ? allocatedToFilterEquals
                ? transactionAllocatedTo === allocatedToFilter
                : transactionAllocatedTo !== allocatedToFilter
              : true;
            if (searchQuery) {
              return categoryMatch && allocatedToMatch && searchMatch;
            } else if (querySelect) {
              return categoryMatch && allocatedToMatch && querySelectMatch;
            } else {
              return categoryMatch && allocatedToMatch;
            }
          }
        );

        // Map transactions
        const mappedTransactions = filteredTransactions.map((transaction) => ({
          id: transaction.id,
          date: helpers.formatDate(transaction.created_at, "DD/MM/YYYY"),
          description: transaction.description,
          memberName: transaction.allocated_to,
          category: rootGetters["codeValueApi/getNameOfCodeValue"](
            "SuspenseAccountTransactionCategories",
            transaction["SuspenseAccountTransactionCategories_cd_category"]
          ),
          amount: transaction.amount,
          amountIncreased: transaction.amount > 0,
          referenceID: transaction.reference_id,
          allocatedTo: transaction.allocated_to
            ? transaction.allocated_to
            : "Not allocated",
          splitted: transaction.splitted,
          opened: false,
        }));

        // Sorting
        const sortedTransactions = [...mappedTransactions].sort((a, b) => {
          if (sortBy === "asc") {
            return a[orderBy] > b[orderBy] ? 1 : -1;
          } else {
            return a[orderBy] < b[orderBy] ? 1 : -1;
          }
        });

        // Limiting with offset
        return sortedTransactions.slice(offset, offset + limit);
      },

    // getTransactionCategoryName
    getTransactionCategoryName:
      (state, getters, rootState, rootGetters) => (transaction) => {
        return rootGetters["codeValueApi/getNameOfCodeValue"](
          "SuspenseAccountTransactionCategories",
          transaction["SuspenseAccountTransactionCategories_cd_category"]
        );
      },

    // getAllSuspenseAccountTransactions
    getAllSuspenseAccountTransactions:
      (state, getters) =>
      (payload = {}) => {
        const params = {
          orderBy: payload.orderBy || "id",
          sortBy: payload.sortBy || "desc",
          offset: payload.offset || 0,
          limit: payload.limit || 10,
        };
        return getters.getMappedSuspenseAccountTransactions(params);
      },

    // getTotalForAllSuspenseAccountTransactions
    getTotalForAllSuspenseAccountTransactions: (state) => {
      return state.suspenseAccountTransactions.length;
    },

    // getCategorisedSuspenseAccountTransactions
    getCategorisedSuspenseAccountTransactions:
      (state, getters) =>
      (payload = {}) => {
        const params = {
          orderBy: payload.orderBy || "id",
          sortBy: payload.sortBy || "desc",
          offset: payload.offset || 0,
          limit: payload.limit || 10,
          categoryFilter: "Uncategorised",
          categoryFilterEquals: false,
        };

        const transactions =
          getters.getMappedSuspenseAccountTransactions(params);

        const data = transactions.map((transaction) => ({
          ...transaction,
          allocated: transaction.allocated_to === "Not allocated",
        }));

        const filteredArray = data.filter(
          (obj) => obj.category === "Investment"
        );
        return filteredArray;
      },

    // getTotalForCategorisedSuspenseAccountTransactions
    getTotalForCategorisedSuspenseAccountTransactions: (state, getters) => {
      return getters.getCategorisedSuspenseAccountTransactions().length;
    },

    // getNotAllocatedSuspenseAccountTransactions
    getNotAllocatedSuspenseAccountTransactions:
      (state, getters) =>
      (payload = {}) => {
        const params = {
          orderBy: payload.orderBy || "id",
          sortBy: payload.sortBy || "desc",
          offset: payload.offset || 0,
          limit: payload.limit || 10,
          allocatedToFilter: "Not allocated",
          allocatedToFilterEquals: true,
        };

        const transactions =
          getters.getMappedSuspenseAccountTransactions(params);

        const dt = transactions.map((transaction) => ({
          ...transaction,
          allocated: false,
          showCol1: false,
        }));

        // Find the index of the object in the array with the matching ID
        const index = dt.findIndex((obj) => obj.id === state.index);

        if (index !== -1) {
          // Update the valueToChange property of the object if the ID is found
          dt[index].category = state.selectedCategory;
        } else {
          console.log("ID not found in the array");
        }

        return dt;
      },

    // getTotalForNotAllocatedSuspenseAccountTransactions
    getTotalForNotAllocatedSuspenseAccountTransactions: (state) => {
      return state.suspenseAccountTransactions.filter(
        (transaction) => transaction.allocated_to === "Not allocated"
      ).length;
    },

    // getUncategorisedSuspenseAccountTransactions
    getUncategorisedSuspenseAccountTransactions:
      (state, getters) =>
      (payload = {}) => {
        const params = {
          orderBy: payload.orderBy || "id",
          sortBy: payload.sortBy || "desc",
          offset: payload.offset || 0,
          limit: payload.limit || 10,
          categoryFilter: "Uncategorised",
          categoryFilterEquals: true,
        };
        console.log(
          "Mapped Transactions:",
          getters.getMappedSuspenseAccountTransactions()
        );
        const transactions =
          getters.getMappedSuspenseAccountTransactions(params);

        const dt = transactions.map((transaction) => ({
          ...transaction,
          allocated: transaction.allocated_to === "Not allocated",
          showCol1: false,
        }));

        // Find the index of the object in the array with the matching ID
        const index = dt.findIndex((obj) => obj.id === state.index);

        if (index !== -1) {
          // Update the valueToChange property of the object if the ID is found
          dt[index].category = state.selectedCategory;
        } else {
          console.log("ID not found in the array");
        }

        return dt;
      },

    // getTotalForUncategorisedSuspenseAccountTransactions
    getTotalForUncategorisedSuspenseAccountTransactions: (state, getters) => {
      return state.suspenseAccountTransactions.filter(
        (transaction) =>
          getters.getTransactionCategoryName(transaction) === "Uncategorised"
      ).length;
    },

    // getContributionsSuspenseAccountTransactions
    getContributionsSuspenseAccountTransactions:
      (state, getters) =>
      (payload = {}) => {
        const params = {
          orderBy: payload.orderBy || "id",
          sortBy: payload.sortBy || "desc",
          offset: payload.offset || 0,
          limit: payload.limit || 10,
          categoryFilter: "Contributions",
          categoryFilterEquals: true,
        };

        const transactions =
          getters.getMappedSuspenseAccountTransactions(params);

        return transactions.map((transaction) => ({
          ...transaction,
          allocated: transaction.allocated_to === "Not allocated",
          showCol1: false,
        }));
      },

    // getTotalForContributionsSuspenseAccountTransactions
    getTotalForContributionsSuspenseAccountTransactions: (state, getters) => {
      return state.suspenseAccountTransactions.filter(
        (transaction) =>
          getters.getTransactionCategoryName(transaction) === "Contributions"
      ).length;
    },
    // getSuspenseAccountTransactions
    getSuspenseAccountT(state) {
      return state.suspenseAccountTransactions.reduce((sum, loan) => {
        if (loan.description !== "Withdrawal") {
          return sum + loan.amount;
        }
        return sum;
      }, 0);
    },
    getSuspenseAccountInv(state) {
      return state.suspenseAccountTransactions.reduce((sum, loan) => {
        if (loan.description === "Investment") {
          return sum + loan.amount;
        }
        return sum;
      }, 0);
    },
    getSuspenseAccountL(state) {
      return state.suspenseAccountTransactions.reduce((sum, loan) => {
        if (loan.description === "Loan") {
          return sum + loan.amount;
        }
        return sum;
      }, 0);
    },
    getWithDraw(state) {
      const withdrawal = state.suspenseAccountTransactions.filter(
        (loan) => loan.description === "Withdrawal"
      );
      console.log("withdrawalwithdrawal", withdrawal);
      return withdrawal.reduce((sum, loan) => {
        return sum + loan.amount;
      }, 0);
    },
  },
};

export default suspenseAccountTransactionApi;
