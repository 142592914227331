// plugins/countryCallingCodess.js

import Vue from "vue";
import { getCountries, getCountryCallingCode } from "libphonenumber-js";

// Assign countryCallingCodess to Vue's prototype
Vue.prototype.$getAllCountryCallingCodes = () => {
  // Use a Set to ensure uniqueness, as multiple countries may share the same calling code
  const callingCodes = new Set();

  getCountries().forEach((countryCode) => {
    callingCodes.add(`+${getCountryCallingCode(countryCode)}`);
  });

  // Convert the Set to an array and sort it
  return Array.from(callingCodes).sort((a, b) => parseInt(a) - parseInt(b));
};
