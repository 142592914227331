import apiHelperFactory from "@/http/apiHelperFactory";
// import helpers from "@/plugins/helpers";

const templateApi = {
  namespaced: true,

  state: {
    // accountTransfersTemplate
    accountTransfersTemplate: {},

    // chargesTemplate
    chargesTemplate: {},
  },

  mutations: {
    // setAccountTransfersTemplate
    setAccountTransfersTemplate(state, payload) {
      state.accountTransfersTemplate = payload;
    },

    // setChargesTemplate
    setChargesTemplate(state, payload) {
      state.chargesTemplate = payload;
    },

    // resetState
    resetState(state) {
      // accountTransfersTemplate
      state.accountTransfersTemplate = {};

      // chargesTemplate
      state.chargesTemplate = {};
    },
  },

  actions: {
    // getAccountTransfersTemplateReq
    async getAccountTransfersTemplateReq({ commit }, payload = {}) {
      // useLoading
      const useLoading = "useLoading" in payload ? payload.useLoading : true;

      // useLoading
      if (useLoading) {
        // startLoading
        commit("app/startLoading", null, { root: true });
      }

      // setUseAppToken
      commit("auth/setUseAppToken", true, { root: true });

      try {
        // getAccountTransfersTemplateRes
        const getAccountTransfersTemplateRes =
          await apiHelperFactory.getAccountTransfersTemplate(
            "params" in payload ? payload.params : {}
          );

        // setAccountTransfersTemplate
        commit(
          "setAccountTransfersTemplate",
          getAccountTransfersTemplateRes.data
        );

        return getAccountTransfersTemplateRes;
      } catch (error) {
        if (error.response) {
          // handle response error
        } else if (error.request) {
          // handle request error
        } else {
          // handle other errors
        }
      } finally {
        // useLoading
        if (useLoading) {
          // stopLoading
          commit("app/stopLoading", null, { root: true });
        }

        // setUseAppToken
        commit("auth/setUseAppToken", false, { root: true });
      }
    },

    // getChargesTemplateReq
    async getChargesTemplateReq({ commit }, payload = {}) {
      // useLoading
      const useLoading = "useLoading" in payload ? payload.useLoading : true;

      // useLoading
      if (useLoading) {
        // startLoading
        commit("app/startLoading", null, { root: true });
      }

      // setUseAppToken
      commit("auth/setUseAppToken", true, { root: true });

      try {
        // getChargesTemplateRes
        const getChargesTemplateRes = await apiHelperFactory.getChargesTemplate(
          "params" in payload ? payload.params : {}
        );

        // setChargesTemplate
        commit("setChargesTemplate", getChargesTemplateRes.data);

        return getChargesTemplateRes;
      } catch (error) {
        if (error.response) {
          // handle response error
        } else if (error.request) {
          // handle request error
        } else {
          // handle other errors
        }
      } finally {
        // useLoading
        if (useLoading) {
          // stopLoading
          commit("app/stopLoading", null, { root: true });
        }

        // setUseAppToken
        commit("auth/setUseAppToken", false, { root: true });
      }
    },
  },

  getters: {
    // getAccountTransfersTemplate
    getAccountTransfersTemplate(state) {
      return state.accountTransfersTemplate;
    },

    // getToClientOptionsAccountTransfersTemplate
    getToClientOptionsAccountTransfersTemplate(state) {
      return "toClientOptions" in state.accountTransfersTemplate
        ? state.accountTransfersTemplate.toClientOptions
        : [];
    },

    // getNamesForToClientOptionsAccountTransfersTemplate
    getNamesForToClientOptionsAccountTransfersTemplate(state, getters) {
      return getters.getToClientOptionsAccountTransfersTemplate.map(
        (client) => {
          return client.id + " " + client.displayName;
        }
      );
    },

    // getNamesBySearchForToClientOptionsAccountTransfersTemplate
    getNamesBySearchForToClientOptionsAccountTransfersTemplate:
      (state, getters) =>
      (search, limit = 10) => {
        if (!search) return []; // Return empty array if search term is not provided.

        const searchLowerCase = search.toLowerCase(); // Ensure case-insensitive search.

        return getters.getNamesForToClientOptionsAccountTransfersTemplate
          .filter((name) => name.toLowerCase().includes(searchLowerCase))
          .slice(0, limit);
      },

    // getIdFromNameForToClientOptionsAccountTransfersTemplate
    getIdFromNameForToClientOptionsAccountTransfersTemplate: () => (name) => {
      const parts = name.split(" ");
      const potentialId = parseInt(parts[0], 10);
      return isNaN(potentialId) ? null : potentialId;
    },

    // doesIdExistForToClientOptionsAccountTransfersTemplate
    doesIdExistForToClientOptionsAccountTransfersTemplate:
      (state, getters) => (id) => {
        return getters.getNamesForToClientOptionsAccountTransfersTemplate.some(
          (name) => {
            const extractedId =
              getters.getIdFromNameForToClientOptionsAccountTransfersTemplate(
                name
              );
            return extractedId === id;
          }
        );
      },

    // getChargesTemplate
    getChargesTemplate(state) {
      return state.chargesTemplate;
    },

    // getCurrencyOptionsForChargesTemplate
    getCurrencyOptionsForChargesTemplate: (state) => {
      return "currencyOptions" in state.chargesTemplate
        ? state.chargesTemplate.currencyOptions
        : [];
    },

    // getCurrencyOptionNamesForChargesTemplate
    getCurrencyOptionNamesForChargesTemplate: (state, getters) => {
      // currencyOptions
      const currencyOptions = getters.getCurrencyOptionsForChargesTemplate;

      return currencyOptions.map((option) => option.name);
    },

    // getCurrencyCodeByNameForChargesTemplate
    getCurrencyCodeByNameForChargesTemplate:
      (state, getters) => (currencyName) => {
        // currencyOptions
        const currencyOptions = getters.getCurrencyOptionsForChargesTemplate;

        // currencyOption
        const currencyOption = currencyOptions.find(
          (option) => option.name === currencyName
        );

        return currencyOption ? currencyOption.code : null;
      },

    // getLoanChargeCalculationTypeOptionsForChargesTemplate
    getLoanChargeCalculationTypeOptionsForChargesTemplate: (state) => {
      return "loanChargeCalculationTypeOptions" in state.chargesTemplate
        ? state.chargesTemplate.loanChargeCalculationTypeOptions
        : [];
    },

    // getLoanChargeCalculationTypeOptionNamesForChargesTemplate
    getLoanChargeCalculationTypeOptionNamesForChargesTemplate: (
      state,
      getters
    ) => {
      // loanChargeCalculationTypeOptions
      const loanChargeCalculationTypeOptions =
        getters.getLoanChargeCalculationTypeOptionsForChargesTemplate;

      return loanChargeCalculationTypeOptions.map((option) => option.value);
    },

    // getLoanChargeCalculationTypeIdByValueForChargesTemplate
    getLoanChargeCalculationTypeIdByValueForChargesTemplate:
      (state, getters) => (calculationTypeValue) => {
        // loanChargeCalculationTypeOptions
        const loanChargeCalculationTypeOptions =
          getters.getLoanChargeCalculationTypeOptionsForChargesTemplate;

        // loanChargeCalculationTypeOption
        const loanChargeCalculationTypeOption =
          loanChargeCalculationTypeOptions.find(
            (option) => option.value === calculationTypeValue
          );

        return loanChargeCalculationTypeOption
          ? loanChargeCalculationTypeOption.id
          : null;
      },

    // getLoanChargeTimeTypeOptionsForChargesTemplate
    getLoanChargeTimeTypeOptionsForChargesTemplate: (state) => {
      return "loanChargeTimeTypeOptions" in state.chargesTemplate
        ? state.chargesTemplate.loanChargeTimeTypeOptions
        : [];
    },

    // getLoanChargeTimeTypeOptionNamesForChargesTemplate
    getLoanChargeTimeTypeOptionNamesForChargesTemplate: (state, getters) => {
      // loanChargeTimeTypeOptions
      const loanChargeTimeTypeOptions =
        getters.getLoanChargeTimeTypeOptionsForChargesTemplate;

      return loanChargeTimeTypeOptions.map((option) => option.value);
    },

    // getLoanChargeTimeTypeIdByValueForChargesTemplate
    getLoanChargeTimeTypeIdByValueForChargesTemplate:
      (state, getters) => (calculationTypeValue) => {
        // loanChargeTimeTypeOptions
        const loanChargeTimeTypeOptions =
          getters.getLoanChargeTimeTypeOptionsForChargesTemplate;

        // loanChargeTimeTypeOption
        const loanChargeTimeTypeOption = loanChargeTimeTypeOptions.find(
          (option) => option.value === calculationTypeValue
        );

        return loanChargeTimeTypeOption ? loanChargeTimeTypeOption.id : null;
      },

    // getChargePaymetModeOptionsForChargesTemplate
    getChargePaymetModeOptionsForChargesTemplate: (state) => {
      return "chargePaymetModeOptions" in state.chargesTemplate
        ? state.chargesTemplate.chargePaymetModeOptions
        : [];
    },

    // getChargePaymetModeOptionNamesForChargesTemplate
    getChargePaymetModeOptionNamesForChargesTemplate: (state, getters) => {
      // chargePaymetModeOptions
      const chargePaymetModeOptions =
        getters.getChargePaymetModeOptionsForChargesTemplate;

      return chargePaymetModeOptions.map((option) => option.value);
    },

    // getChargePaymentModeOptionIdByValueForChargesTemplate
    getChargePaymentModeOptionIdByValueForChargesTemplate:
      (state, getters) => (paymentModeValue) => {
        // chargePaymentModeOptions
        const chargePaymentModeOptions =
          getters.getChargePaymetModeOptionsForChargesTemplate;

        // chargePaymentModeOption
        const chargePaymentModeOption = chargePaymentModeOptions.find(
          (option) => option.value === paymentModeValue
        );

        return chargePaymentModeOption ? chargePaymentModeOption.id : null;
      },
  },
};

export default templateApi;
