const policiesAndSystem = {
  namespaced: true,

  state: {
    // policiesAndSystem dialog
    showPoliciesAndSystemDialog: false,
    showPoliciesAndSystemMobileDialog: false,
    policiesAndSystemDialogStep: 1,
  },

  mutations: {
    // policiesAndSystem dialog
    setShowPoliciesAndSystemDialog(state, payload) {
      state.showPoliciesAndSystemDialog = payload;
    },
    setShowPoliciesAndSystemMobileDialog(state, payload) {
      state.showPoliciesAndSystemMobileDialog = payload;
    },
    setPoliciesAndSystemDialogStep(state, payload) {
      state.policiesAndSystemDialogStep = payload;
    },

    // resetState
    resetState(state) {
      // policiesAndSystem dialog
      state.showPoliciesAndSystemDialog = false;
      state.showPoliciesAndSystemMobileDialog = false;
      state.policiesAndSystemDialogStep = 1;
    },
  },

  actions: {},

  getters: {
    // policiesAndSystem dialog
    getShowPoliciesAndSystemDialog(state) {
      return state.showPoliciesAndSystemDialog;
    },
    getShowPoliciesAndSystemMobileDialog(state) {
      return state.showPoliciesAndSystemMobileDialog;
    },
    getPoliciesAndSystemDialogStep(state) {
      return state.policiesAndSystemDialogStep;
    },
  },
};

export default policiesAndSystem;
