import apiHelperFactoryForBank from "@/http/apiHelperFactoryForBank";

const constitutionApiBank = {
  namespaced: true,

  state: {
    // stokvelDetails: [],
    stokvelConstitutions: {},
  },

  mutations: {
    // setInvestments
    setStokvelConstitutions(state, payload) {
      state.stokvelConstitutions = payload;
    },

    // resetState
    resetState(state) {
      // stokvelConstitutions
      state.stokvelConstitutions = {};
    },
  },

  actions: {
    // getStokvelConstitutionReq
    async getStokvelConstitutionReq({ commit }, payload = {}) {
      // useLoading
      const useLoading = "useLoading" in payload ? payload.useLoading : true;

      // useLoading
      if (useLoading) {
        // startLoading
        commit("app/startLoading", null, { root: true });
      }

      // setUseAppToken
      commit("auth/setUseAppToken", true, { root: true });

      try {
        // getStokvelConstitutionRes
        const getStokvelConstitutionRes =
          await apiHelperFactoryForBank.stokvelConstitution(
            payload.tenantIdentifier
          );

        // setInvestments
        commit("setStokvelConstitutions", getStokvelConstitutionRes.data);

        return getStokvelConstitutionRes;
      } catch (error) {
        if (error.response) {
          // handle response error
        } else if (error.request) {
          // handle request error
        } else {
          // handle other errors
        }
      } finally {
        // useLoading
        if (useLoading) {
          // stopLoading
          commit("app/stopLoading", null, { root: true });
        }

        // setUseAppToken
        commit("auth/setUseAppToken", false, { root: true });
      }
    },
  },

  getters: {
    // getStokvelConstitutions
    getStokvelConstitutions(state) {
      return state.stokvelConstitutions;
    },

    // getStokvelConstitutionsDoc
    getStokvelConstitutionsDoc(state) {
      const stokvelConstitutionsDoc = [
        ...state.tenants.map(
          (stokvelConstitutions) => stokvelConstitutions.documents
        ),
      ];

      return stokvelConstitutionsDoc;
    },
  },
};

export default constitutionApiBank;
