import apiHelperFactory from "@/http/apiHelperFactory";
// import helpers from "@/plugins/helpers";

const savingsAccountTransactionApi = {
  namespaced: true,

  state: {
    // savingsAccountTransaction
    savingsAccountTransaction: {},

    // savingsAccountTransactions
    savingsAccountTransactions: [],
  },

  mutations: {
    // setSavingsAccountTransaction
    setSavingsAccountTransaction(state, payload) {
      state.savingsAccountTransaction = payload;
    },

    // setSavingsAccountTransactions
    setSavingsAccountTransactions(state, payload) {
      state.savingsAccountTransactions = payload;
    },

    // resetState
    resetState(state) {
      // savingsAccountTransaction
      state.savingsAccountTransaction = {};

      // savingsAccountTransactions
      state.savingsAccountTransactions = [];
    },
  },

  actions: {
    // getSavingsAccountTransactionReq
    async getSavingsAccountTransactionsReq({ commit }, payload = {}) {
      // useLoading
      const useLoading = "useLoading" in payload ? payload.useLoading : true;

      // useLoading
      if (useLoading) {
        // startLoading
        commit("app/startLoading", null, { root: true });
      }

      // setUseAppToken
      commit("auth/setUseAppToken", true, { root: true });

      try {
        // getSavingsAccountTransactionRes
        const getSavingsAccountTransactionRes =
          await apiHelperFactory.getSavingsAccountTransaction(
            payload.accountId,
            payload.transactionId
          );

        // setSavingsAccountTransactions
        commit(
          "setSavingsAccountTransaction",
          getSavingsAccountTransactionRes.data
        );

        return getSavingsAccountTransactionRes;
      } catch (error) {
        if (error.response) {
          // handle response error
        } else if (error.request) {
          // handle request error
        } else {
          // handle other errors
        }
      } finally {
        // useLoading
        if (useLoading) {
          // stopLoading
          commit("app/stopLoading", null, { root: true });
        }

        // setUseAppToken
        commit("auth/setUseAppToken", false, { root: true });
      }
    },
  },

  getters: {
    // getSavingsAccountTransaction
    getSavingsAccountTransaction(state) {
      return state.savingsAccountTransaction;
    },

    // getSavingsAccountTransactions
    getSavingsAccountTransactions(state) {
      return state.savingsAccountTransactions;
    },

    // getFirstSavingsAccountTransaction
    getFirstSavingsAccountTransaction(state) {
      return state.savingsAccountTransactions.length > 0
        ? state.savingsAccountTransactions[0]
        : null;
    },
  },
};

export default savingsAccountTransactionApi;
