const administration = {
  namespaced: true,

  state: {
    // uploadFileStep
    uploadFileStep: 1,

    // edit
    showEditMobileDialog: false,

    // dialog
    showImportSuccessDialog: false,
    showUploadNewFileDialog: false,
    showUploadNewFileMobileDialog: false,
    uploadNewFileDialogStep: 1,

    // new stokvel dialog
    showApprovedDialog: false,
    showDeclinedDialog: false,
    showDeclinedMobileDialog: false,
    declinedDialogStep: 1,

    // onBoard Dialogs
    showOnBoardDialog: false,
    showOnBoardMobileDialog: false,
    onBoardDialogStep: 1,

    // offBoard Dialogs
    showOffBoardDialog: false,
    showOffBoardMobileDialog: false,
    offBoardDialogStep: 1,

    // onBoard content
    memberName: "",
    memberSurname: "",
    memberEmail: "",
    memberPhoneNumber: "",
    memberConfirmationText: "",
  },

  mutations: {
    // uploadFileStep
    setUploadFileStep(state, payload) {
      state.uploadFileStep = payload;
    },

    // edit
    setShowEditMobileDialog(state, payload) {
      state.showEditMobileDialog = payload;
    },

    // dialog
    setShowImportSuccessDialog(state, payload) {
      state.showImportSuccessDialog = payload;
    },

    setShowUploadNewFileDialog(state, payload) {
      state.showUploadNewFileDialog = payload;
    },

    setShowUploadNewFileMobileDialog(state, payload) {
      state.showUploadNewFileMobileDialog = payload;
    },

    setUploadNewFileDialogStep(state, payload) {
      state.uploadNewFileDialogStep = payload;
    },

    // new stokvel dialog
    setShowApprovedDialog(state, payload) {
      state.showApprovedDialog = payload;
    },
    setShowDeclinedDialog(state, payload) {
      state.showDeclinedDialog = payload;
    },
    setShowDeclinedMobileDialog(state, payload) {
      state.showDeclinedMobileDialog = payload;
    },

    setDeclinedDialogStep(state, payload) {
      state.declinedDialogStep = payload;
    },

    // onBoard dialog
    setShowOnBoardDialog(state, payload) {
      state.showOnBoardDialog = payload;
    },
    setShowOnBoardMobileDialog(state, payload) {
      state.showOnBoardMobileDialog = payload;
    },

    setOnBoardDialogStep(state, payload) {
      state.onBoardDialogStep = payload;
    },

    // offBoard dialog
    setShowOffBoardDialog(state, payload) {
      state.showOffBoardDialog = payload;
    },
    setShowOffBoardMobileDialog(state, payload) {
      state.showOffBoardMobileDialog = payload;
    },

    setOffBoardDialogStep(state, payload) {
      state.offBoardDialogStep = payload;
    },

    // onBoard content
    // setMemberName
    setMemberName(state, payload) {
      state.memberName = payload;
    },

    // setMemberSurname
    setMemberSurname(state, payload) {
      state.memberSurname = payload;
    },

    // setMemberEmail
    setMemberEmail(state, payload) {
      state.memberEmail = payload;
    },

    // setMemberPhoneNumber
    setMemberPhoneNumber(state, payload) {
      state.memberPhoneNumber = payload;
    },

    // setMemberConfirmationText
    setMemberConfirmationText(state, payload) {
      state.memberConfirmationText = payload;
    },

    // resetState
    resetState(state) {
      // uploadFileStep
      state.uploadFileStep = 1;

      // edit
      state.showEditMobileDialog = false;

      // dialog
      state.showImportSuccessDialog = false;
      state.showUploadNewFileDialog = false;
      state.showUploadNewFileMobileDialog = false;
      state.uploadNewFileDialogStep = 1;

      // new stokvel dialog
      state.showApprovedDialog = false;
      state.showDeclinedDialog = false;
      state.showDeclinedMobileDialog = false;
      state.declinedDialogStep = 1;

      // onBoard dialog
      state.showOnBoardDialog = false;
      state.showOnBoardMobileDialog = false;
      state.onBoardDialogStep = 1;

      // offBoard dialog
      state.showOffBoardDialog = false;
      state.showOffBoardMobileDialog = false;
      state.offBoardDialogStep = 1;

      // onBoard content
      state.memberName = "";
      state.memberSurname = "";
      state.memberEmail = "";
      state.memberPhoneNumber = "";
      state.memberConfirmationText = "";
    },
  },

  actions: {},

  getters: {
    // getTransactionStep
    getImportSuccessDialog(state) {
      return state.showImportSuccessDialog;
    },

    // Edit
    getEditsMobileDialog(state) {
      return state.showEditMobileDialog;
    },

    getUploadFileStep(state) {
      return state.uploadFileStep;
    },

    // dialog
    getShowUploadNewFileDialog(state) {
      return state.showUploadNewFileDialog;
    },

    getShowUploadNewFileMobileDialog(state) {
      return state.showUploadNewFileMobileDialog;
    },

    getUploadNewFileDialogStep(state) {
      return state.uploadNewFileDialogStep;
    },

    // new stokvel dialog
    getShowApprovedDialog(state) {
      return state.showApprovedDialog;
    },
    getShowDeclinedDialog(state) {
      return state.showDeclinedDialog;
    },
    getShowDeclinedMobileDialog(state) {
      return state.showDeclinedMobileDialog;
    },
    getDeclinedDialogStep(state) {
      return state.declinedDialogStep;
    },

    // onBoard dialog
    getShowOnBoardDialog(state) {
      return state.showOnBoardDialog;
    },
    getShowOnBoardMobileDialog(state) {
      return state.showOnBoardMobileDialog;
    },
    getOnBoardDialogStep(state) {
      return state.onBoardDialogStep;
    },

    // offBoard dialog
    getShowOffBoardDialog(state) {
      return state.showOffBoardDialog;
    },
    getShowOffBoardMobileDialog(state) {
      return state.showOffBoardMobileDialog;
    },
    getOffBoardDialogStep(state) {
      return state.offBoardDialogStep;
    },

    // onBoard content
    // getMemberName
    getMemberName(state) {
      return state.memberName;
    },

    // getMemberSurname
    getMemberSurname(state) {
      return state.memberSurname;
    },

    // getMemberEmail
    getMemberEmail(state) {
      return state.memberEmail;
    },

    // getMemberPhoneNumber
    getMemberPhoneNumber(state) {
      return state.memberPhoneNumber;
    },

    // getMemberConfirmationText
    getMemberConfirmationText(state) {
      return state.memberConfirmationText;
    },
  },
};

export default administration;
