const stokvelProcessing = {
  namespaced: true,

  state: {
    // decline
    showDeclineDialog: false,
    showDeclineMobileDialog: false,
    declineDialogStep: 1,

    // approve
    showApproveDialog: false,
    showApproveMobileDialog: false,
    approveDialogStep: 1,

    // upload file
    // uploadFileStep
    uploadFileStep: 1,

    // edit
    showEditMobileDialog: false,

    // dialog
    showImportSuccessDialog: false,
    showUploadNewFileDialog: false,
    showUploadNewFileMobileDialog: false,
    uploadNewFileDialogStep: 1,
  },

  mutations: {
    // decline
    setShowDeclineDialog(state, payload) {
      state.showDeclineDialog = payload;
    },
    setShowDeclineMobileDialog(state, payload) {
      state.showDeclineMobileDialog = payload;
    },
    setDeclineDialogStep(state, payload) {
      state.declineDialogStep = payload;
    },

    // approve
    setShowApproveDialog(state, payload) {
      state.showApproveDialog = payload;
    },
    setShowApproveMobileDialog(state, payload) {
      state.showApproveMobileDialog = payload;
    },
    setApproveDialogStep(state, payload) {
      state.approveDialogStep = payload;
    },

    // upload file
    // uploadFileStep
    setUploadFileStep(state, payload) {
      state.uploadFileStep = payload;
    },

    // edit
    setShowEditMobileDialog(state, payload) {
      state.showEditMobileDialog = payload;
    },

    // dialog
    setShowImportSuccessDialog(state, payload) {
      state.showImportSuccessDialog = payload;
    },

    setShowUploadNewFileDialog(state, payload) {
      state.showUploadNewFileDialog = payload;
    },

    setShowUploadNewFileMobileDialog(state, payload) {
      state.showUploadNewFileMobileDialog = payload;
    },

    setUploadNewFileDialogStep(state, payload) {
      state.uploadNewFileDialogStep = payload;
    },

    resetState(state) {
      // decline
      state.showDeclineDialog = false;
      state.showDeclineMobileDialog = false;
      state.declineDialogStep = 1;

      // approve
      state.showApproveDialog = false;
      state.showApproveMobileDialog = false;
      state.approveDialogStep = 1;

      // upload file
      // uploadFileStep
      state.uploadFileStep = 1;

      // edit
      state.showEditMobileDialog = false;

      // dialog
      state.showImportSuccessDialog = false;
      state.showUploadNewFileDialog = false;
      state.showUploadNewFileMobileDialog = false;
      state.uploadNewFileDialogStep = 1;
    },
  },
  actions: {
    // Asynchronous functions that commit mutations to update the state
  },

  getters: {
    // Computed properties that retrieve data from the state

    // decline
    getShowDeclineDialog(state) {
      return state.showDeclineDialog;
    },
    getShowDeclineMobileDialog(state) {
      return state.showDeclineMobileDialog;
    },
    getDeclineDialogStep(state) {
      return state.declineDialogStep;
    },

    // approve
    getShowApproveDialog(state) {
      return state.showApproveDialog;
    },
    getShowApproveMobileDialog(state) {
      return state.showApproveMobileDialog;
    },
    getApproveDialogStep(state) {
      return state.approveDialogStep;
    },

    // upload file
    // getTransactionStep
    getImportSuccessDialog(state) {
      return state.showImportSuccessDialog;
    },

    // Edit
    getEditsMobileDialog(state) {
      return state.showEditMobileDialog;
    },

    getUploadFileStep(state) {
      return state.uploadFileStep;
    },

    // dialog
    getShowUploadNewFileDialog(state) {
      return state.showUploadNewFileDialog;
    },

    getShowUploadNewFileMobileDialog(state) {
      return state.showUploadNewFileMobileDialog;
    },

    getUploadNewFileDialogStep(state) {
      return state.uploadNewFileDialogStep;
    },
  },
};

export default stokvelProcessing;
