import apiHelperFactory from "@/http/apiHelperFactory";
import helpers from "@/plugins/helpers";
import { mapActions } from "vuex";

const investmentApi = {
  namespaced: true,

  state: {
    // investments
    investments: [],

    // selectedValue
    selectedValue: null,
  },

  mutations: {
    // setInvestments
    setInvestments(state, payload) {
      state.investments = payload;
    },

    // setSelectedValue
    setSelectedValue(state, payload) {
      state.selectedValue = payload;
    },

    setNumPending(state, payload) {
      state.numPending = payload;
    },

    setNumApproved(state, payload) {
      state.numApproved = payload;
    },

    // resetState
    resetState(state) {
      // investments
      state.investments = [];
      state.numApproved = 0;
      state.numPending = 0;
    },
  },

  actions: {
    // getInvestmentsReq
    async getInvestmentsReq({ commit }, payload = {}) {
      // useLoading
      const useLoading = "useLoading" in payload ? payload.useLoading : true;

      // useLoading
      if (useLoading) {
        // startLoading
        commit("app/startLoading", null, { root: true });
      }

      // setUseAppToken
      commit("auth/setUseAppToken", true, { root: true });

      try {
        // getInvestmentsRes
        const getInvestmentsRes = await apiHelperFactory.getInvestments(
          payload.officeId
        );

        // setInvestments
        commit("setInvestments", getInvestmentsRes.data);

        let numApproved = 0;
        let numPending = 0;

        getInvestmentsRes.data.forEach((investment) => {
          if (
            investment.InvestmentStatuses_cd_status &&
            investment.InvestmentStatuses_cd_status == "106" //approved
          ) {
            numApproved++;
          }
          if (
            investment.InvestmentStatuses_cd_status &&
            (investment.InvestmentStatuses_cd_status == "110" || //pending
              investment.InvestmentStatuses_cd_status == "108") //new
          ) {
            numPending++;
          }
        });

        commit("setNumApproved", numApproved);
        commit("setNumPending", numPending);

        return getInvestmentsRes;
      } catch (error) {
        if (error.response) {
          // handle response error
        } else if (error.request) {
          // handle request error
        } else {
          // handle other errors
        }
      } finally {
        // useLoading
        if (useLoading) {
          // stopLoading
          commit("app/stopLoading", null, { root: true });
        }

        // setUseAppToken
        commit("auth/setUseAppToken", false, { root: true });
      }
    },

    // createInvestmentReq
    async createInvestmentReq({ commit }, { payload, officeId }) {
      // useLoading
      const useLoading = "useLoading" in payload ? payload.useLoading : true;

      // useLoading
      if (useLoading) {
        // startLoading
        commit("app/startLoading", null, { root: true });
      }

      // setUseAppToken
      commit("auth/setUseAppToken", true, { root: true });

      try {
        return await apiHelperFactory.createInvestment(payload, officeId);
      } catch (error) {
        // error.response
        if (error.response) {
          let res = error.response;

          if (res.status === 400) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 401) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 403) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 404) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 500) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else {
            // handleError
            helpers.handleError(res.status, res.data);
          }
        } else {
          // handleError
          helpers.handleError();
        }
      } finally {
        // useLoading
        if (useLoading) {
          // stopLoading
          commit("app/stopLoading", null, { root: true });
        }

        // setUseAppToken
        commit("auth/setUseAppToken", false, { root: true });
      }
    },

    // updateInvestmentReq
    async updateInvestmentReq({ commit }, { payload, officeId, investmentId }) {
      // useLoading
      const useLoading = "useLoading" in payload ? payload.useLoading : true;

      // useLoading
      if (useLoading) {
        // startLoading
        commit("app/startLoading", null, { root: true });
      }

      // setUseAppToken
      commit("auth/setUseAppToken", true, { root: true });

      try {
        let response = await apiHelperFactory.updateInvestment(
          payload,
          officeId,
          investmentId
        );
        if (response.status == 200) {
          console.log("succcess");
          return response;
        }
      } catch (error) {
        // error.response
        if (error.response) {
          let res = error.response;

          if (res.status === 400) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 401) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 403) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 404) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 500) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else {
            // handleError
            helpers.handleError(res.status, res.data);
          }
        } else {
          // handleError
          helpers.handleError();
        }
      } finally {
        // useLoading
        if (useLoading) {
          // stopLoading
          commit("app/stopLoading", null, { root: true });
        }

        // setUseAppToken
        commit("auth/setUseAppToken", false, { root: true });
      }
    },
  },

  getters: {
    // getInvestments
    getInvestments(state) {
      return state.investments;
    },

    // getSelectedValue
    getSelectedValue(state) {
      return state.selectedValue;
    },

    // getFirstInvestment
    getFirstInvestment(state) {
      return state.investments.length > 0 ? state.investments[0] : null;
    },

    // getInvestmentById
    getInvestmentByInvestmentId: (state) => (id) => {
      const investment = state.investments.find(
        (investment) => investment.investmentId === id
      );
      console.log("investment >>>> ", investment);
      return investment ? investment : {};
    },

    // getMappedInvestments global admin / admin
    getMappedInvestments(state, getters, rootState, rootGetters) {
      // asset_manager
      // return state.investments.map((investment) => {
      const mappedInvestments = state.investments.map((investment) => {
        const assetManager = rootGetters["codeValueApi/getNameOfCodeValue"](
          "InvestmentAssetManagers",
          investment["InvestmentAssetManagers_cd_asset_manager"]
        );

        // status
        const status = rootGetters["codeValueApi/getNameOfCodeValue"](
          "InvestmentStatuses",
          investment["InvestmentStatuses_cd_status"]
        );

        // type
        const type = rootGetters["codeValueApi/getNameOfCodeValue"](
          "InvestmentTypes",
          investment["InvestmentTypes_cd_type"]
        );

        return {
          id: investment.id,
          office_id: investment.office_id,
          InvestmentAssetManagers_cd_asset_manager:
            investment.InvestmentAssetManagers_cd_asset_manager,
          InvestmentStatuses_cd_status: investment.InvestmentStatuses_cd_status,
          InvestmentTypes_cd_type: investment.InvestmentTypes_cd_type,
          amount: investment.amount,
          assetManager: assetManager,
          status: status,
          type: type,
          memberId: "Unknown",
          stockvelId: "Unknown",
          opened: false,
          selected: false,
        };
      });

      // let numApproved = 0;
      // let numPending = 0;

      // mappedInvestments.forEach((investment) => {
      //   if (investment.status && investment.status == "Approved") {
      //     numApproved++;
      //   }
      //   if (
      //     investment.status &&
      //     (investment.status == "Pending" || investment.status == "New")
      //   ) {
      //     numPending++;
      //   }
      // });

      // commit("setNumApproved", numApproved);
      // commit("setNumPending", numPending);

      return mappedInvestments;
    },

    getAmountInvestments(state, getters) {
      function getTotalAmount(investments) {
        let totalAmount = 0;
        for (let i = 0; i < investments.length; i++) {
          totalAmount += investments[i].amount;
        }
        return totalAmount;
      }
      return getTotalAmount(getters.getMappedInvestments);
    },

    getAmountWithdrawLen(state, getters) {
      function getTotalAmount(investments) {
        let totalAmount = 0;
        for (let i = 0; i < investments.length; i++) {
          if (investments[i].status === "Approved") {
            totalAmount += 1;
          }
        }
        return totalAmount;
      }
      return getTotalAmount(getters.getMappedInvestments);
    },

    getAmountWithdraw(state, getters) {
      function getTotalAmount(investments) {
        let totalAmount = 0;
        for (let i = 0; i < investments.length; i++) {
          if (investments[i].status === "Approved") {
            totalAmount += investments[i].amount;
          }
        }
        return totalAmount;
      }
      return getTotalAmount(getters.getMappedInvestments);
    },

    getNumberPendingInvestments(state) {
      return state.numPending;
    },

    getNumberApprovedInvestments(state) {
      return state.numApproved;
    },

    // getNumberPendingInvestments(state, getters) {
    //   let investments = getters.getMappedInvestments;
    //   console.log("investments", investments);
    //   return investments.reduce((sum, investment) => {
    //     if (
    //       investment.status &&
    //       (investment.status == "Pending" || investment.status == "New")
    //     ) {
    //       return sum + 1;
    //     }
    //     return sum;
    //   }, 0);
    // },

    // getNumberApprovedInvestments(state, getters) {
    //   let investments = getters.getMappedInvestments;
    //   return investments.reduce((sum, investment) => {
    //     if (investment.status && investment.status == "Approved") {
    //       return sum + 1;
    //     }
    //     return sum;
    //   }, 0);
    // },
  },
};

export default investmentApi;
