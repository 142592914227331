import apiHelperFactory from "@/http/apiHelperFactory";
import helpers from "@/plugins/helpers";

const journalEntryApi = {
  namespaced: true,

  state: {
    // journalEntryId
    journalEntryId: 0,

    // journalEntry
    journalEntry: {},

    // journalEntries
    journalEntries: [],

    // totalFilteredRecords
    totalFilteredRecords: 0,
  },

  mutations: {
    // setJournalEntryId
    setJournalEntryId(state, payload) {
      state.journalEntryId = payload;
    },

    // setJournalEntry
    setJournalEntry(state, payload) {
      state.journalEntry = payload;
    },

    // setJournalEntries
    setJournalEntries(state, payload) {
      state.journalEntries = payload;
    },

    // setTotalFilteredRecords
    setTotalFilteredRecords(state, payload) {
      state.totalFilteredRecords = payload;
    },

    // resetState
    resetState(state) {
      // journalEntryId
      state.journalEntryId = 0;

      // journalEntry
      state.journalEntry = {};

      // journalEntries
      state.journalEntries = [];

      // totalFilteredRecords
      state.totalFilteredRecords = 0;
    },
  },

  actions: {
    // getJournalEntriesReq
    async getJournalEntriesReq({ commit }, payload = {}) {
      // useLoading
      const useLoading = "useLoading" in payload ? payload.useLoading : true;

      // useLoading
      if (useLoading) {
        // startLoading
        commit("app/startLoading", null, { root: true });
      }

      // setUseAppToken
      commit("auth/setUseAppToken", true, { root: true });

      try {
        // getJournalEntriesRes
        const getJournalEntriesRes = await apiHelperFactory.getJournalEntries(
          "params" in payload ? payload.params : {}
        );

        // pageItems
        if ("pageItems" in getJournalEntriesRes.data) {
          // setJournalEntries
          commit("setJournalEntries", getJournalEntriesRes.data.pageItems);
        }

        // totalFilteredRecords
        if ("totalFilteredRecords" in getJournalEntriesRes.data) {
          // setTotalFilteredRecords
          commit(
            "setTotalFilteredRecords",
            getJournalEntriesRes.data.totalFilteredRecords
          );
        }

        return getJournalEntriesRes;
      } catch (error) {
        if (error.response) {
          // handle response error
        } else if (error.request) {
          // handle request error
        } else {
          // handle other errors
        }
      } finally {
        // useLoading
        if (useLoading) {
          // stopLoading
          commit("app/stopLoading", null, { root: true });
        }

        // setUseAppToken
        commit("auth/setUseAppToken", false, { root: true });
      }
    },

    // getJournalEntryByIdReq
    async getJournalEntryByIdReq({ commit }, payload = {}) {
      // useLoading
      const useLoading = "useLoading" in payload ? payload.useLoading : true;

      // useLoading
      if (useLoading) {
        // startLoading
        commit("app/startLoading", null, { root: true });
      }

      // setUseAppToken
      commit("auth/setUseAppToken", true, { root: true });

      try {
        // getJournalEntryByIdRes
        const getJournalEntryByIdRes = await apiHelperFactory.getJournalEntry(
          payload.journalEntryId,
          "params" in payload ? payload.params : {}
        );

        // setJournalEntry
        commit("setJournalEntry", getJournalEntryByIdRes.data);

        return getJournalEntryByIdRes;
      } catch (error) {
        if (error.response) {
          // handle response error
        } else if (error.request) {
          // handle request error
        } else {
          // handle other errors
        }
      } finally {
        // useLoading
        if (useLoading) {
          // stopLoading
          commit("app/stopLoading", null, { root: true });
        }

        // setUseAppToken
        commit("auth/setUseAppToken", false, { root: true });
      }
    },

    // createJournalEntryReq
    async createJournalEntryReq({ commit }, payload = {}) {
      // useLoading
      const useLoading = "useLoading" in payload ? payload.useLoading : true;

      // useLoading
      if (useLoading) {
        // startLoading
        commit("app/startLoading", null, { root: true });
      }

      // setUseAppToken
      commit("auth/setUseAppToken", true, { root: true });

      try {
        return await apiHelperFactory.createJournalEntry(payload.body);
      } catch (error) {
        // error.response
        if (error.response) {
          let res = error.response;

          if (res.status === 400) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 401) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 403) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 404) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 500) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else {
            // handleError
            helpers.handleError(res.status, res.data);
          }
        } else {
          // handleError
          helpers.handleError();
        }
      } finally {
        // useLoading
        if (useLoading) {
          // stopLoading
          commit("app/stopLoading", null, { root: true });
        }

        // setUseAppToken
        commit("auth/setUseAppToken", false, { root: true });
      }
    },
  },

  getters: {
    // getJournalEntryId
    getJournalEntryId(state) {
      return state.journalEntryId;
    },

    // getJournalEntry
    getJournalEntry(state) {
      return state.journalEntry;
    },

    // getJournalEntryById
    getJournalEntryById: (state) => (id) => {
      // journalEntry
      const journalEntry = state.journalEntries.find(
        (journalEntry) => journalEntry.id === id
      );

      return journalEntry ? journalEntry : {};
    },

    // getJournalEntries
    getJournalEntries(state) {
      return state.journalEntries;
    },

    // getTotalFilteredRecords
    getTotalFilteredRecords(state) {
      return state.totalFilteredRecords;
    },
  },
};

export default journalEntryApi;
