const adminStokvelApplication = {
  namespaced: true,

  state: {
    slider: "slider-1",
    step: "step-1",
    questionairreStep: "step-1",
    showMobileForm: false,

    //document
    documents: [],
    // BankingDetails
    showBankingDetailsDialog: false,
    showBankingDetailsMobileDialog: false,
    bankingDetailsDialogStep: 1,

    // GroupTrust
    showGroupTrustDialog: false,
    showGroupTrustMobileDialog: false,
    groupTrustDialogStep: 1,

    // accountType
    accountType: "",

    // administrationType
    administrationType: "",

    // bankAccountNumber
    bankAccountNumber: "",

    // bankAccountType
    bankAccountType: "",

    // bankBranchCode
    bankBranchCode: "",

    // bankName
    bankName: "",

    // colour
    colour: "",

    // email
    email: "",

    // name
    name: "",

    // nameAndSurname
    nameAndSurname: "",

    // numberOfMembers
    numberOfMembers: "",

    // password
    password: "",

    // phoneNumber
    phoneNumber: "",

    // role
    role: "",

    // status
    status: "",

    // tenantIdentifier
    tenantIdentifier: "",

    // type
    type: "",

    // eslint-disable-next-line no-undef
    setSelectedFileName: "",
    // eslint-disable-next-line no-undef
    setPdfBase64: "",

    // eslint-disable-next-line no-undef
    setSelectedFileType: "",
  },

  mutations: {
    // setSlider
    setSlider(state, payload) {
      state.slider = payload;
    },

    resetDocuments(state) {
      state.documents = [];
    },
    // setStep
    setStep(state, payload) {
      state.step = payload;
    },

    // setQuestionairreStep
    setQuestionairreStep(state, payload) {
      state.questionairreStep = payload;
    },

    // setShowMobileForm
    setShowMobileForm(state, payload) {
      state.showMobileForm = payload;
    },

    // bankingDetails
    setShowBankingDetailsDialog(state, payload) {
      state.showBankingDetailsDialog = payload;
    },
    setShowBankingDetailsMobileDialog(state, payload) {
      state.showBankingDetailsMobileDialog = payload;
    },
    setBankingDetailsDialogStep(state, payload) {
      state.bankingDetailsDialogStep = payload;
    },

    // groupTrust
    setShowGroupTrustDialog(state, payload) {
      state.showGroupTrustDialog = payload;
    },
    setShowGroupTrustMobileDialog(state, payload) {
      state.showGroupTrustMobileDialog = payload;
    },
    setGroupTrustDialogStep(state, payload) {
      state.groupTrustDialogStep = payload;
    },

    // setAccountType
    setAccountType(state, payload) {
      state.accountType = payload;
    },

    // setAdministrationType
    setAdministrationType(state, payload) {
      state.administrationType = payload;
    },

    // setBankAccountNumber
    setBankAccountNumber(state, payload) {
      state.bankAccountNumber = payload;
    },

    // setBankAccountType
    setBankAccountType(state, payload) {
      state.bankAccountType = payload;
    },

    // setBankBranchCode
    setBankBranchCode(state, payload) {
      state.bankBranchCode = payload;
    },

    // setBankName
    setBankName(state, payload) {
      state.bankName = payload;
    },

    // setColour
    setColour(state, payload) {
      state.colour = payload;
    },

    // setEmail
    setEmail(state, payload) {
      state.email = payload;
    },

    // setName
    setName(state, payload) {
      state.name = payload;
    },

    // setNameAndSurname
    setNameAndSurname(state, payload) {
      state.nameAndSurname = payload;
    },

    // setNumberOfMembers
    setNumberOfMembers(state, payload) {
      state.numberOfMembers = payload;
    },

    // setPassword
    setPassword(state, payload) {
      state.password = payload;
    },

    // setPhoneNumber
    setPhoneNumber(state, payload) {
      state.phoneNumber = payload;
    },

    // setRole
    setRole(state, payload) {
      state.role = payload;
    },

    // setStatus
    setStatus(state, payload) {
      state.status = payload;
    },

    // setTenantIdentifier
    setTenantIdentifier(state, payload) {
      state.tenantIdentifier = payload;
    },

    // setType
    setType(state, payload) {
      state.type = payload;
    },

    // SelectedFileType
    setSelectedFileName(state, payload) {
      state.selectedFileName = payload;
    },

    // SelectedFileType
    setPdfBase64(state, payload) {
      state.pdfBase64 = payload;
    },

    // SelectedFileType
    setSelectedFileType(state, payload) {
      state.selectedFileType = payload;
    },

    // addDocument mutation
    setDocument(state, document) {
      state.documents.push(document);
    },

    // resetState
    resetState(state) {
      state.slider = "slider-1";
      state.step = "step-1";
      state.questionairreStep = "step-1";
      state.showMobileForm = false;

      // bankingDetails
      state.showBankingDetailsDialog = false;
      state.showBankingDetailsMobileDialog = false;
      state.bankingDetailsDialogStep = 1;

      // groupTrust
      state.showGroupTrustDialog = false;
      state.showGroupTrustMobileDialog = false;
      state.groupTrustDialogStep = 1;

      // documents array
      state.documents = [];

      // accountType
      state.accountType = "";

      // administrationType
      state.administrationType = "";

      // bankAccountNumber
      state.bankAccountNumber = "";

      // bankAccountType
      state.bankAccountType = "";

      // bankBranchCode
      state.bankBranchCode = "";

      // bankName
      state.bankName = "";

      // colour
      state.colour = "";

      // email
      state.email = "";

      // name
      state.name = "";

      // nameAndSurname
      state.nameAndSurname = "";

      // numberOfMembers
      state.numberOfMembers = "";

      // password
      state.password = "";

      // phoneNumber
      state.phoneNumber = "";

      // role
      state.role = "";

      // status
      state.status = "";

      // tenantIdentifier
      state.tenantIdentifier = "";

      // type
      state.type = "";

      // eslint-disable-next-line no-undef
      state.setSelectedFileName = "";

      // eslint-disable-next-line no-undef
      state.setPdfBase64 = "";

      // eslint-disable-next-line no-undef
      state.setSelectedFileType = "";
    },
  },

  actions: {},

  getters: {
    // getSlider
    getSlider(state) {
      return state.slider;
    },

    // getStep
    getStep(state) {
      return state.step;
    },

    // questionairreStep
    getQuestionairreStep(state) {
      return state.questionairreStep;
    },

    // getShowMobileForm
    getShowMobileForm(state) {
      return state.showMobileForm;
    },

    // bankingDetails
    getShowBankingDetailsDialog(state) {
      return state.showBankingDetailsDialog;
    },
    getShowBankingDetailsMobileDialog(state) {
      return state.showBankingDetailsMobileDialog;
    },
    getBankingDetailsDialogStep(state) {
      return state.bankingDetailsDialogStep;
    },

    // groupTrust
    getShowGroupTrustDialog(state) {
      return state.showGroupTrustDialog;
    },
    getShowGroupTrustMobileDialog(state) {
      return state.showGroupTrustMobileDialog;
    },
    getGroupTrustDialogStep(state) {
      return state.groupTrustDialogStep;
    },

    // getAccountType
    getAccountType(state) {
      return state.accountType;
    },

    // getAdministrationType
    getAdministrationType(state) {
      return state.administrationType;
    },

    // getBankAccountNumber
    getBankAccountNumber(state) {
      return state.bankAccountNumber;
    },

    // getBankAccountType
    getBankAccountType(state) {
      return state.bankAccountType;
    },

    // getBankBranchCode
    getBankBranchCode(state) {
      return state.bankBranchCode;
    },

    // getBankName
    getBankName(state) {
      return state.bankName;
    },

    // getColour
    getColour(state) {
      return state.colour;
    },

    // getEmail
    getEmail(state) {
      return state.email;
    },

    // getName
    getName(state) {
      return state.name;
    },

    // getNameAndSurname
    getNameAndSurname(state) {
      return state.nameAndSurname;
    },

    // getNumberOfMembers
    getNumberOfMembers(state) {
      return state.numberOfMembers;
    },

    // getPassword
    getPassword(state) {
      return state.password;
    },

    // getPhoneNumber
    getPhoneNumber(state) {
      return state.phoneNumber;
    },

    // getRole
    getRole(state) {
      return state.role;
    },

    // getStatus
    getStatus(state) {
      return state.status;
    },

    // getTenantIdentifier
    getTenantIdentifier(state) {
      return state.tenantIdentifier;
    },

    // getType
    getType(state) {
      return state.type;
    },

    getSelectedFileName(state) {
      return state.selectedFileName;
    },
    // eslint-disable-next-line no-undef
    getPdfBase64(state) {
      return state.pdfBase64;
    },
    // eslint-disable-next-line no-undef
    getSelectedFileType(state) {
      return state.selectedFileType;
    },

    getDocuments(state) {
      console.log("boolean", state.documents > 0);
      return state.documents;
    },
  },
};

export default adminStokvelApplication;
