import apiHelperFactory from "@/http/apiHelperFactory";
// import helpers from "@/plugins/helpers";

const clientsContactDetailsApi = {
  namespaced: true,

  state: {
    // clientsContactDetails
    clientsContactDetails: [],
  },

  mutations: {
    // setClientsContactDetails
    setClientsContactDetails(state, payload) {
      state.clientsContactDetails = payload;
    },

    // resetState
    resetState(state) {
      // clientsContactDetails
      state.clientsContactDetails = [];
    },
  },

  actions: {
    // getClientsContactDetailsReq
    async getClientsContactDetailsReq({ commit }, payload = {}) {
      // setUseAppToken
      commit("auth/setUseAppToken", true, { root: true });

      try {
        // getClientsContactDetailsRes
        const getClientsContactDetailsRes =
          await apiHelperFactory.getClientContactDetails(payload.clientId);

        // setClientsContactDetails
        commit("setClientsContactDetails", getClientsContactDetailsRes.data);

        return getClientsContactDetailsRes;
      } catch (error) {
        if (error.response) {
          // handle response error
        } else if (error.request) {
          // handle request error
        } else {
          // handle other errors
        }
      } finally {
        // setUseAppToken
        commit("auth/setUseAppToken", false, { root: true });
      }
    },
  },

  getters: {
    // getClientsContactDetails
    getClientsContactDetails(state) {
      return state.clientsContactDetails;
    },

    // getFirstClientContactDetails
    getFirstClientContactDetails(state) {
      return state.clientsContactDetails.length > 0
        ? state.clientsContactDetails[0]
        : null;
    },
  },
};

export default clientsContactDetailsApi;
