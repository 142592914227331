import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

import "material-design-icons-iconfont/dist/material-design-icons.css"; // Ensure you are using css-loader

import ArrowDownIcon from "@/components/shared/vuetify-icons/auth/member/sign-up/ArrowDownIcon";
import EyeOffIcon from "@/components/shared/vuetify-icons/auth/member/sign-up/EyeOffIcon";
import MailIcon from "@/components/shared/vuetify-icons/auth/member/login/MailIcon";
import UserIcon from "@/components/shared/vuetify-icons/auth/member/sign-up/UserIcon";
import UserOkIcon from "@/components/shared/vuetify-icons/auth/member/sign-up/UserOkIcon";

import ContributionsIcon from "@/components/shared/vuetify-icons/sidebar/ContributionsIcon";
import DotsVerticalIcon from "@/components/shared/vuetify-icons/sidebar/DotsVerticalIcon";
import FavouritesIcon from "@/components/shared/vuetify-icons/sidebar/FavouritesIcon";
import LoansIcon from "@/components/shared/vuetify-icons/sidebar/LoansIcon";
import MessagesIcon from "@/components/shared/vuetify-icons/sidebar/MessagesIcon";
import MessagesNotOpenedIcon from "@/components/shared/vuetify-icons/sidebar/MessagesNotOpenedIcon";
import NotificationIcon from "@/components/shared/vuetify-icons/sidebar/NotificationIcon";
import OverviewIcon from "@/components/shared/vuetify-icons/sidebar/OverviewIcon";
import OverviewEyeIcon from "@/components/shared/vuetify-icons/sidebar/OverviewEyeIcon";
import SavingsIcon from "@/components/shared/vuetify-icons/sidebar/SavingsIcon";
import SettingsIcon from "@/components/shared/vuetify-icons/sidebar/SettingsIcon";
import TransactIcon from "@/components/shared/vuetify-icons/sidebar/TransactIcon";

import MasterCardIcon from "@/components/shared/vuetify-icons/loans/MasterCardIcon";

import EditIcon from "@/components/shared/vuetify-icons/mix/EditIcon";

import CircleCheckIcon from "@/components/shared/vuetify-icons/transact/CircleCheckIcon";
import TransactTimeIcon from "@/components/shared/vuetify-icons/transact/TransactTimeIcon";
import DataAndUploudsIcon from "@/components/shared/vuetify-icons/sidebar/DataAndUploudsIcon.vue";
import PoliciesAndSystemIcon from "@/components/shared/vuetify-icons/sidebar/PoliciesAndSystemIcon.vue";
import InsuranceIcon from "@/components/shared/vuetify-icons/sidebar/InsuranceIcon.vue";
import MyStokvelIcon from "@/components/shared/vuetify-icons/sidebar/MyStokvelIcon.vue";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#1778F2",
        secondary: "#B0BEC5",
        accent: "#8C9EFF",
        error: "#B71C1C",
        gray25: "#B3B3B3",
        gray50: "#FCFCFC",
        gray75: "#B2BAC0",
        gray100: "#989999",
        gray125: "#8A899C",
        gray150: "#EFEFF3",
        gray200: "#F5F5F5",
        gray225: "#ECECEC",
        gray250: "#616161",
        gray285: "#9291A5",
        gray300: "#FBFBFB",
        gray350: "#CBC9C9",
        gray400: "#F4F3F3",
        gray450: "#868F99",
        gray500: "#7D7E86",
        gray550: "#9A9A9B",
        gray700: "#A8AAAD",
        gray725: "#E5E5EF",
        gray750: "#EEEEEE",
        gray755: "#EFF0F1",
        gray800: "#757575",
        gray835: "#6C6F74",
        gray850: "#BEBEBE",
        gray900: "#7A7D82",
        gray950: "#E7E7E7",

        black300: "#303241",
        black400: "#0D0E13",
        black500: "#0D1829",
        black550: "#1E1B39",
        black600: "#192028",
        black650: "#181818",
        black700: "#1D242E",
        black800: "#090C0F",
        black850: "#121212",
        black900: "#2B2B2C",

        maroon800: "#615E83",

        red400: "#FF6712",
        red500: "#F63F3F",
        red800: "#FF718B",
        red900: "#DC2626",

        green400: "#077169",
        green500: "#6CC236",
        green900: "#059669",

        yellow500: "#F4AB17",
        yellow900: "#FBBC1B",

        blue100: "#0EBDF6",
        blue200: "#E2EEFF",
        blue300: "#1778F2",
        blue400: "#615E83",
        blue500: "#1F82DD",
        blue800: "#002A5C",
        blue900: "#3F79DB",

        white100: "#F2F2F2",
        white200: "#FFFFFF",

        orange100: "#FF843E",

        pink100: "#FFA7A7",
      },
    },
  },
  icons: {
    iconfont: "md",
    values: {
      arrowDownIcon: {
        // name of our custom icon
        component: ArrowDownIcon, // our custom component
      },
      eyeOffIcon: {
        // name of our custom icon
        component: EyeOffIcon, // our custom component
      },
      mailIcon: {
        // name of our custom icon
        component: MailIcon, // our custom component
      },
      userIcon: {
        // name of our custom icon
        component: UserIcon, // our custom component
      },
      userOkIcon: {
        // name of our custom icon
        component: UserOkIcon, // our custom component
      },

      // ContributionsIcon
      contributionsIcon: {
        component: ContributionsIcon,
      },
      // DotsVerticalIcon
      dotsVerticalIcon: {
        component: DotsVerticalIcon,
      },
      // FavouritesIcon
      favouritesIcon: {
        component: FavouritesIcon,
      },
      // LoansIcon
      loansIcon: {
        component: LoansIcon,
      },
      // MessagesIcon
      messagesIcon: {
        component: MessagesIcon,
      },
      // MessagesNotOpenedIcon
      messagesNotOpenedIcon: {
        component: MessagesNotOpenedIcon,
      },
      //NotificationIcon
      notificationIcon: {
        component: NotificationIcon,
      },
      // OverviewIcon
      overviewIcon: {
        component: OverviewIcon,
      },
      // OverviewEyeIcon
      overviewEyeIcon: {
        component: OverviewEyeIcon,
      },
      // SavingsIcon
      savingsIcon: {
        component: SavingsIcon,
      },
      // SettingsIcon
      settingsIcon: {
        component: SettingsIcon,
      },
      // TransactIcon
      transactIcon: {
        component: TransactIcon,
      },

      // MasterCardIcon
      masterCardIcon: {
        component: MasterCardIcon,
      },

      // EditIcon
      editIcon: {
        component: EditIcon,
      },

      // CircleCheckIcon
      circleCheckIcon: {
        component: CircleCheckIcon,
      },

      // TransactTimeIcon
      transactTimeIcon: {
        component: TransactTimeIcon,
      },

      // dataAndUploudsIcon
      dataAndUploudsIcon: {
        component: DataAndUploudsIcon,
      },

      // policiesAndSystemIcon
      policiesAndSystemIcon: {
        component: PoliciesAndSystemIcon,
      },

      // insuranceIcon
      insuranceIcon: {
        component: InsuranceIcon,
      },

      // myStokvelIcon
      myStokvelIcon: {
        component: MyStokvelIcon,
      },
    },
  },
});
