import apiHelperFactory from "@/http/apiHelperFactory";
import apiHelperFactoryForBank from "@/http/apiHelperFactoryForBank";
import helpers from "@/plugins/helpers";

const userApi = {
  namespaced: true,

  state: {
    // users
    users: [],
    Allusers: [],

    userId: 0,
  },

  mutations: {
    // setUsers
    setUsers(state, payload) {
      state.users = payload;
    },
    // setUsers
    setAllUsers(state, payload) {
      state.Allusers = payload;
    },

    // setUserId
    setUserId(state, payload) {
      state.userId = payload;
    },

    // resetState
    resetState(state) {
      // users
      state.users = [];

      // userId
      state.userId = 0;
    },
  },

  actions: {
    // getUsersReq
    async getUsersReq({ commit }, payload = {}) {
      // useLoading
      const useLoading = "useLoading" in payload ? payload.useLoading : true;

      // useLoading
      if (useLoading) {
        // startLoading
        commit("app/startLoading", null, { root: true });
      }

      // setUseAppToken
      commit("auth/setUseAppToken", true, { root: true });

      try {
        // getUsersRes
        const getUsersRes = await apiHelperFactory.getUsers();
        // setUsers
        commit("setUsers", getUsersRes.data);

        return getUsersRes;
      } catch (error) {
        if (error.response) {
          // handle response error
        } else if (error.request) {
          // handle request error
        } else {
          // handle other errors
        }
      } finally {
        // useLoading
        if (useLoading) {
          // stopLoading
          commit("app/stopLoading", null, { root: true });
        }

        // setUseAppToken
        commit("auth/setUseAppToken", false, { root: true });
      }
    },

    // getUsersReq
    async getAllUsersReq({ commit }, payload = {}) {
      // useLoading
      const useLoading = "useLoading" in payload ? payload.useLoading : true;

      // useLoading
      if (useLoading) {
        // startLoading
        commit("app/startLoading", null, { root: true });
      }

      // setUseAppToken
      commit("auth/setUseAppToken", true, { root: true });

      try {
        // getUsersRes
        const getUsersRes = await apiHelperFactoryForBank.retrieveAllAUser();
        // setUsers
        commit("setAllUsers", getUsersRes.data);

        return getUsersRes;
      } catch (error) {
        if (error.response) {
          // handle response error
        } else if (error.request) {
          // handle request error
        } else {
          // handle other errors
        }
      } finally {
        // useLoading
        if (useLoading) {
          // stopLoading
          commit("app/stopLoading", null, { root: true });
        }

        // setUseAppToken
        commit("auth/setUseAppToken", false, { root: true });
      }
    },

    // updateUserRoleReq
    async updateUserRoleReq({ commit }, payload = {}) {
      // useLoading
      const useLoading = "useLoading" in payload ? payload.useLoading : true;

      // useLoading
      if (useLoading) {
        // startLoading
        commit("app/startLoading", null, { root: true });
      }

      // setUseAppToken
      commit("auth/setUseAppToken", true, { root: true });

      try {
        return await apiHelperFactory.updateUserDetails(
          payload.body,
          payload.userId
        );
      } catch (error) {
        // error.response
        if (error.response) {
          let res = error.response;

          if (res.status === 400) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 401) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 403) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 404) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 500) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else {
            // handleError
            helpers.handleError(res.status, res.data);
          }
        } else {
          // handleError
          helpers.handleError();
        }
      } finally {
        // useLoading
        if (useLoading) {
          // stopLoading
          commit("app/stopLoading", null, { root: true });
        }

        // setUseAppToken
        commit("auth/setUseAppToken", false, { root: true });
      }
    },
  },

  getters: {
    // mapUsers
    mapUsers: (state) => {
      return state.users.map((user) => {
        return {
          id: user.id,
          nameAndSurname: user.firstname + " " + user.lastname,
          member: user.username,
          status: user.isSelfServiceUser ? "Approved" : "Pending",
          committeeRole: user.selectedRoles[0]?.name || "None",
          emailAddress: user.email,
        };
      });
    },

    getAllUsers: (state) => {
      return state.Allusers;
    },

    // getUsers
    getUsers:
      (state, getters) =>
      ({ orderBy = "id", sortBy = "desc", offset = 0, limit = 10 }) => {
        // First, map the users using the mapUsers getter
        const mappedUsers = getters.mapUsers;

        // Then sort the mapped users array
        let sortedUsers = [...mappedUsers].sort((a, b) => {
          if (sortBy === "asc") {
            return a[orderBy] > b[orderBy] ? 1 : -1;
          } else {
            return a[orderBy] < b[orderBy] ? 1 : -1;
          }
        });

        // Finally, limit the sorted users array
        sortedUsers = sortedUsers.slice(offset, offset + limit);

        return sortedUsers;
      },

    // getTotalUsers
    getTotalUsers(state, getters) {
      return getters.mapUsers.length;
    },

    // getCommetteUsers
    getCommetteUsers(state, getters) {
      // List of committee roles
      const committeeRoles = [
        "Secretary",
        "Treasurer",
        "Administrator",
        "Chair Person",
        "Super user",
      ];

      // Use the existing mapUsers getter to get all users
      const allUsers = getters.mapUsers;

      // Filter the users by their roles
      const committeeUsers = allUsers.filter((user) => {
        return (
          user.committeeRole && committeeRoles.includes(user.committeeRole)
        );
      });

      return committeeUsers;
    },

    // getUserId
    getUserId(state) {
      return state.userId;
    },
  },
};

export default userApi;
