import apiHelperFactory from "@/http/apiHelperFactory";
import helpers from "@/plugins/helpers";

const accountTransferApi = {
  namespaced: true,

  state: {
    // accountTransfers
    accountTransfers: [],

    // createAccountTransfers
    createAccountTransfers: {},

    // totalFilteredRecords
    totalFilteredRecords: 0,
  },

  mutations: {
    // setAccountTransfers
    setAccountTransfers(state, payload) {
      state.accountTransfers = payload;
    },

    // setCreateAccountTransfers
    setCreateAccountTransfers(state, payload) {
      state.createAccountTransfers = payload;
    },

    // setTotalFilteredRecords
    setTotalFilteredRecords(state, payload) {
      state.totalFilteredRecords = payload;
    },

    // resetState
    resetState(state) {
      // accountTransfers
      state.accountTransfers = [];

      // createAccountTransfers
      state.createAccountTransfer = {};

      // totalFilteredRecords
      state.totalFilteredRecords = 0;
    },
  },

  actions: {
    // getAccountTransfersReq
    async getAccountTransfersReq({ commit }, payload) {
      // startLoading
      commit("app/startLoading", null, { root: true });

      // setUseAppToken
      commit("auth/setUseAppToken", true, { root: true });

      try {
        // params
        let params = {};
        params = payload;

        // Perform the API request using the accountTransfer function
        const getAccountTransfersRes = await apiHelperFactory.accountTransfers(
          params
        );

        if (
          getAccountTransfersRes.data &&
          "pageItems" in getAccountTransfersRes.data
        ) {
          commit("setAccountTransfers", getAccountTransfersRes.data.pageItems);
          // totalFilteredRecords
          if ("totalFilteredRecords" in getAccountTransfersRes.data) {
            // setTotalFilteredRecords
            commit(
              "setTotalFilteredRecords",
              getAccountTransfersRes.data.totalFilteredRecords
            );
          }
        }
        return getAccountTransfersRes;

        // Commit the fetched account transfers to the store
      } catch (error) {
        if (error.response) {
          let res = error.response;

          // handleError
          helpers.handleError(res.status, res.data);
        } else {
          // handleError
          helpers.handleError();
        }
      } finally {
        // stopLoading
        commit("app/stopLoading", null, { root: true });

        // setUseAppToken
        commit("auth/setUseAppToken", false, { root: true });
      }
    },

    // createAccountTransferReq
    async createAccountTransferReq({ commit }, payload = {}) {
      // useLoading
      const useLoading = "useLoading" in payload ? payload.useLoading : true;

      // useLoading
      if (useLoading) {
        // startLoading
        commit("app/startLoading", null, { root: true });
      }

      // setUseAppToken
      commit("auth/setUseAppToken", true, { root: true });

      try {
        return await apiHelperFactory.createAccountTransfer(payload.body);
      } catch (error) {
        // error.response
        if (error.response) {
          let res = error.response;

          if (res.status === 400) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 401) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 403) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 404) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 500) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else {
            // handleError
            helpers.handleError(res.status, res.data);
          }
        } else {
          // handleError
          helpers.handleError();
        }
      } finally {
        // useLoading
        if (useLoading) {
          // stopLoading
          commit("app/stopLoading", null, { root: true });
        }

        // setUseAppToken
        commit("auth/setUseAppToken", false, { root: true });
      }
    },
  },

  getters: {
    // getAccountTransfers
    getAccountTransfers(state) {
      return state.accountTransfers;
    },

    // getCreateAccountTransfer
    getCreateAccountTransfer(state) {
      return state.createAccountTransfer;
    },

    // getTotalFilteredRecords
    getTotalFilteredRecords(state) {
      return state.totalFilteredRecords;
    },
  },
};

export default accountTransferApi;
