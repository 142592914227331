const insurance = {
  namespaced: true,

  state: {
    // currentDialog
    currentDialog: "Get a Quote",

    // showDialog
    showDialog: false,

    // currentDialogStep
    currentDialogStep: 1,
  },

  mutations: {
    // setCurrentDialog
    setCurrentDialog(state, payload) {
      state.currentDialog = payload;
    },

    // ShowDialog
    setShowDialog(state, payload) {
      state.showDialog = payload;
    },

    // setCurrentDialogStep
    setCurrentDialogStep(state, payload) {
      state.currentDialogStep = payload;
    },

    // resetState
    resetState(state) {
      // currentDialog
      state.currentDialog = "Get a Quote";

      // showDialog
      state.showDialog = false;

      // currentDialogStep
      state.currentDialogStep = 1;
    },
  },

  actions: {},

  getters: {
    // getCurrentDialog
    getCurrentDialog(state) {
      return state.currentDialog;
    },

    // getShowDialog
    getShowDialog(state) {
      return state.showDialog;
    },

    // getCurrentDialogStep
    getCurrentDialogStep(state) {
      return state.currentDialogStep;
    },
  },
};

export default insurance;
