import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import checkSession from "./middleware/sessionChecker";
import vuetify from "./plugins/vuetify";
import VueSlider from "vue-slider-component";

export const eventBus = new Vue();

import FileSaver from "file-saver";
import helpers from "./plugins/helpers";
import Donut from "vue-css-donut-chart";
import "vue-css-donut-chart/dist/vcdonut.css";

import Chart from "chart.js/auto";

import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import VueSweetalert2 from "vue-sweetalert2";

import "sweetalert2/dist/sweetalert2.min.css";

import axiosInstance from "./plugins/axios";
import bankApiAxios from "./plugins/bankApiAxios";
import bankAxios from "./plugins/bankAxios";

import VueAxios from "vue-axios";
import apiHelper from "./http/apiHelper";
import apiHelperFactory from "./http/apiHelperFactory";

import ability from "./plugins/ability";
import { abilitiesPlugin } from "@casl/vue";

import "./plugins/moment";
import "./plugins/rules";
import "./plugins/lists";
import "./plugins/countryCallingCodes";
import "./plugins/countryCodes";
import "./plugins/fileDownload";
import "./plugins/fileSaver";
import "./plugins/xlsx";

import moment from "moment";

Vue.use(Donut);
Vue.prototype.$chart = Chart;

const plugin = {
  install() {
    Vue.helpers = helpers;
    Vue.prototype.$helpers = helpers;
    Vue.prototype.$moment = moment;
    Vue.apiHelper = apiHelper;
    Vue.prototype.$apiHelper = apiHelper;

    Vue.apiHelperFactory = apiHelperFactory;
    Vue.prototype.$apiHelperFactory = apiHelperFactory;
  },
};

Vue.use(plugin);

const options = {
  confirmButtonColor: "#192028",
  cancelButtonColor: "#B71C1C",
  confirmButtonText: "Okay",
  // backdrop: "rgba(25, 32, 40, 0.25",
  // customClass: {},
  // background: "#FCFCFC",
};

Vue.use(VueSweetalert2, options);

Vue.use(VueAxios, {
  axios: axiosInstance,
  bankApiAxios: bankApiAxios,
  bankAxios: bankAxios,
});

Vue.use(abilitiesPlugin, ability);

// import "@/assets/scss/shared/vue-slider-component/admin-member/_vue_slider_component.scss";

Vue.component("VueSlider", VueSlider);
Vue.component("loading-overlay", Loading);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
  created() {
    checkSession(this); // Pass the Vue instance

    window.addEventListener("mousemove", () => {
      this.$store.dispatch("auth/updateLastAction");
    });

    window.addEventListener("keypress", () => {
      this.$store.dispatch("auth/updateLastAction");
    });
  },
}).$mount("#app");
