// plugins/lists.js

import Vue from "vue";

// lists
const lists = {
  // banks
  banks: [
    "ABSA",
    "Access Bank (Grobank)",
    "African Bank",
    "Bidvest Bank",
    "Capitec Bank",
    "Discovery Bank",
    "FNB",
    "Finbond",
    "Grindrod Bank",
    "Investec",
    "Mercantile Bank",
    "NBS Bank",
    "Nedbank",
    "Saambou Bank",
    "Standard Bank",
    "TymeBank",
    "U-Bank",
    "Other",
  ],

  // countryCodes
  countryCodes: ["+266", "+27"],

  // stopOrderDates
  stopOrderDates: [
    "1st of every month",
    "2nd of every month",
    "3rd of every month",
    "4th of every month",
    "5th of every month",
    "6th of every month",
    "7th of every month",
    "8th of every month",
    "9th of every month",
    "10th of every month",
    "11th of every month",
    "12th of every month",
    "13th of every month",
    "14th of every month",
    "15th of every month",
    "16th of every month",
    "17th of every month",
    "18th of every month",
    "19th of every month",
    "20th of every month",
    "21st of every month",
    "22nd of every month",
    "23rd of every month",
    "24th of every month",
    "25th of every month",
    "26th of every month",
    "27th of every month",
    "28th of every month",
    "29th of every month",
    "30th of every month",
    "31st of every month",
  ],
};

// Assign lists to Vue's prototype
Vue.prototype.$lists = lists;
