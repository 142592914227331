const adminSignUp = {
  namespaced: true,

  state: {
    slider: "slider-1",
    step: "step-1",
    showMobileForm: false,
  },

  mutations: {
    // setSlider
    setSlider(state, payload) {
      state.slider = payload;
    },

    // setStep
    setStep(state, payload) {
      state.step = payload;
    },

    // setShowMobileForm
    setShowMobileForm(state, payload) {
      state.showMobileForm = payload;
    },

    // resetState
    resetState(state) {
      state.slider = "slider-1";
      state.step = "step-1";
      state.showMobileForm = false;
    },
  },

  actions: {},

  getters: {
    // getSlider
    getSlider(state) {
      return state.slider;
    },

    // getStep
    getStep(state) {
      return state.step;
    },

    // getShowMobileForm
    getShowMobileForm(state) {
      return state.showMobileForm;
    },
  },
};

export default adminSignUp;
