const personalAccounts = {
  namespaced: true,

  state: {
    // invest
    // showinvestDialog
    showInvestDialog: false,

    // showinvestMobileForm
    showInvestMobileForm: false,

    // showInvestMobileSuccess
    showInvestMobileSuccess: false,

    // investDialogStep
    investDialogStep: 1,

    // Withdraw
    // showWithdrawDialog
    showWithdrawDialog: false,

    // showWithdrawMobileForm
    showWithdrawMobileForm: false,

    // showWithdrawMobileSucces
    showWithdrawMobileSuccess: false,

    // withdrawDialogStep
    withdrawDialogStep: 1,

    // Transfer
    // showTransferDialog
    showTransferDialog: false,

    // showTransferMobileForm
    showTransferMobileForm: false,

    // showTransferMobileSuccess
    showTransferMobileSuccess: false,

    // transferDialogStep
    transferDialogStep: 1,

    //Tabs
    // activeDebitTab
    activeDebitTab: false,

    // activePayRollTab
    activePayRollTab: false,

    // activeCashTab
    activeCashTab: false,
  },

  mutations: {
    //invest
    // setShowinvestDialog
    setShowInvestDialog(state, payload) {
      state.showInvestDialog = payload;
    },

    // setShowinvestMobileForm
    setShowInvestMobileForm(state, payload) {
      state.showInvestMobileForm = payload;
    },

    // setinvestDialogStep
    setInvestDialogStep(state, payload) {
      state.investDialogStep = payload;
    },

    // setShowInvestMobileSuccess
    setShowInvestMobileSuccess(state, payload) {
      state.showInvestMobileSuccess = payload;
    },

    // Withdraw
    // setShowWithdrawDialog
    setShowWithdrawDialog(state, payload) {
      state.showWithdrawDialog = payload;
    },

    // setShowWithdrawMobileForm
    setShowWithdrawMobileForm(state, payload) {
      state.showWithdrawMobileForm = payload;
    },

    // setShowWithdrawMobileSuccess
    setShowWithdrawMobileSuccess(state, payload) {
      state.showWithdrawMobileSuccess = payload;
    },

    // setWithdrawDialogStep
    setWithdrawDialogStep(state, payload) {
      state.withdrawDialogStep = payload;
    },

    // Transfer
    // setShowTransferDialog
    setShowTransferDialog(state, payload) {
      state.showTransferDialog = payload;
    },

    // setShowTransferMobileForm
    setShowTransferMobileForm(state, payload) {
      state.showTransferMobileForm = payload;
    },

    // setShowTransferMobileSuccess
    setShowTransferMobileSuccess(state, payload) {
      state.showTransferMobileSuccess = payload;
    },

    // setTransferDialogStep
    setTransferDialogStep(state, payload) {
      state.transferDialogStep = payload;
    },

    //Tabs
    // setActiveDebitTab
    setActiveDebitTab(state, payload) {
      state.activeDebitTab = payload;
    },

    // setActivePayRollTab
    setActivePayRollTab(state, payload) {
      state.activePayRollTab = payload;
    },

    // setActiveCashTab
    setActiveCashTab(state, payload) {
      state.activeCashTab = payload;
    },

    resetState(state) {
      // Invest
      // showinvestDialog
      state.showInvestDialog = false;

      // showinvestMobileForm
      state.showInvestMobileForm = false;

      // showInvestMobileSuccess
      state.showInvestMobileSuccess = false;

      // investDialogStep
      state.investDialogStep = 1;

      // Withdraw
      // showWithdrawDialog
      state.showWithdrawDialog = false;

      // showWithdrawMobileForm
      state.showWithdrawMobileForm = false;

      // showWithdrawMobileSuccess
      state.showWithdrawMobileSuccess = false;

      // withdrawDialogStep
      state.withdrawDialogStep = 1;

      //Transfer
      // showTransferDialog
      state.showTransferDialog = false;

      // showTransferMobileForm
      state.showTransferMobileForm = false;

      // showTransferMobileSuccess
      state.showTransferMobileSuccess = false;

      // transferDialogStep
      state.transferDialogStep = 1;

      //Tabs
      // activeDebitTab
      state.activeDebitTab = false;

      // activePayRollTab
      state.activePayRollTab = false;

      // activeCashTab
      state.activeCashTab = false;
    },
  },

  actions: {},

  getters: {
    // getShowInvestDialog
    getShowInvestDialog(state) {
      return state.showInvestDialog;
    },

    // getShowInvestMobileForm
    getShowInvestMobileForm(state) {
      return state.showInvestMobileForm;
    },

    // getInvestDialogStep
    getInvestDialogStep(state) {
      return state.investDialogStep;
    },

    // getShowWithdrawMobileSuccess
    getShowInvestMobileSuccess(state) {
      return state.showInvestMobileSuccess;
    },

    // getShowWithdrawDialog
    getShowWithdrawDialog(state) {
      return state.showWithdrawDialog;
    },

    // getShowWithdrawMobileForm
    getShowWithdrawMobileForm(state) {
      return state.showWithdrawMobileForm;
    },

    // getShowWithdrawMobileSuccess
    getShowWithdrawMobileSuccess(state) {
      return state.showWithdrawMobileSuccess;
    },

    // getWithdrawDialogStep
    getWithdrawDialogStep(state) {
      return state.withdrawDialogStep;
    },

    // getShowTransferDialog
    getShowTransferDialog(state) {
      return state.showTransferDialog;
    },

    // getShowTransferMobileForm
    getShowTransferMobileForm(state) {
      return state.showTransferMobileForm;
    },

    // getShowTransfMobileSuccess
    getShowTransfMobileSuccess(state) {
      return state.showTransfMobileSuccess;
    },

    // getTransferDialogStep
    getTransferDialogStep(state) {
      return state.transferDialogStep;
    },

    //Tabs
    // getActiveDebitTab
    getActiveDebitTab(state) {
      return state.activeDebitTab;
    },

    // getActivePayRollTab
    getActivePayRollTab(state) {
      return state.activePayRollTab;
    },

    // getActiveDebitTab
    getActiveCashTab(state) {
      return state.activeCashTab;
    },
  },
};

export default personalAccounts;
