import apiHelperFactory from "@/http/apiHelperFactory";

const codeApi = {
  namespaced: true,

  state: {
    // codes
    codes: [],
  },

  mutations: {
    // setCodes
    setCodes(state, payload) {
      state.codes = payload;
    },

    // resetState
    resetState(state) {
      // codes
      state.codes = [];
    },
  },

  actions: {
    // getCodesReq
    async getCodesReq({ commit }, payload = {}) {
      // useLoading
      const useLoading = "useLoading" in payload ? payload.useLoading : true;

      // useLoading
      if (useLoading) {
        // startLoading
        commit("app/startLoading", null, { root: true });
      }

      // setUseAppToken
      commit("auth/setUseAppToken", true, { root: true });

      try {
        // getCodesRes
        const getCodesRes = await apiHelperFactory.getCodes();

        // setCodes
        commit("setCodes", getCodesRes.data);

        return getCodesRes;
      } catch (error) {
        if (error.response) {
          // handle response error
        } else if (error.request) {
          // handle request error
        } else {
          // handle other errors
        }
      } finally {
        // useLoading
        if (useLoading) {
          // stopLoading
          commit("app/stopLoading", null, { root: true });
        }

        // setUseAppToken
        commit("auth/setUseAppToken", false, { root: true });
      }
    },
  },

  getters: {
    // getCodes
    getCodes(state) {
      return state.codes;
    },

    // getCodeByName
    getCodeByName: (state) => (codeName) => {
      return state.codes.find((code) => code.name === codeName) || null;
    },
  },
};

export default codeApi;
