import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";

Vue.use(VueRouter);

// routes
const routes = [
  // public
  {
    path: "/",
    component: () => import("@/layouts/default"),
    children: [
      {
        path: "",
        name: "Home",
        redirect: { name: "MemberLogin" },
      },
      {
        path: "auth/login",
        name: "MemberLogin",
        component: () => import("@/views/auth/member/LoginPage.vue"),
      },
      {
        path: "auth/sign-up",
        name: "MemberSignUp",
        component: () => import("@/views/auth/member/SignUpPage.vue"),
      },
      {
        path: "auth/forgot-password",
        name: "MemberForgotPassword",
        component: () => import("@/views/auth/member/ForgotPasswordPage.vue"),
      },
      {
        path: "auth/reset-password",
        name: "MemberResetPassword",
        component: () => import("@/views/auth/member/ResetPasswordPage.vue"),
      },
      {
        path: "auth/admin/login",
        name: "AdminLogin",
        component: () => import("@/views/auth/admin/LoginPage.vue"),
      },

      {
        path: "auth/admin/invitation-login",
        name: "AdminInvitationLogin",
        component: () => import("@/views/auth/admin/LoginInvitationPage.vue"),
      },

      {
        path: "auth/admin/sign-up",
        name: "AdminSignUp",
        component: () => import("@/views/auth/admin/SignUpPage.vue"),
      },
      {
        path: "auth/admin/forgot-password",
        name: "AdminForgotPassword",
        component: () => import("@/views/auth/admin/ForgotPasswordPage.vue"),
      },
      {
        path: "auth/admin/reset-password",
        name: "AdminResetPassword",
        component: () => import("@/views/auth/admin/ResetPasswordPage.vue"),
      },

      {
        path: "admin/stokvel-application",
        name: "AdminStokvelApplication",
        component: () =>
          import("@/views/auth/admin/StokvelApplicationPage.vue"),
      },
    ],
  },

  // Member
  {
    path: "/member/",
    component: () => import("@/layouts/member.vue"),

    meta: {
      requiresAuth: true,
    },

    children: [
      // Contributions
      {
        path: "contributions",
        name: "MemberContributions",
        component: () =>
          import("@/views/member/contributions/ContributionsPage.vue"),
      },
      // Savings
      {
        path: "investments",
        name: "MemberSavings",
        component: () => import("@/views/member/savings/SavingsPage.vue"),
      },
      // Insurance
      {
        path: "insurance",
        name: "MemberInsurance",
        component: () => import("@/views/member/insurance/InsurancePage.vue"),
      },
      // Loans
      {
        path: "loans",
        name: "MemberLoans",
        component: () => import("@/views/member/loans/LoansPage.vue"),
      },
      // Messages
      {
        path: "messages",
        name: "MemberMessages",
        component: () => import("@/views/member/messages/MessagesPage.vue"),
      },

      // Messages
      {
        path: "messages/compose-message",
        name: "MemberComposeMessages",
        component: () =>
          import("@/views/member/messages/ComposeMessagePage.vue"),
      },

      // Overview
      {
        path: "overview",
        name: "MemberOverview",
        component: () => import("@/views/member/overview/OverviewPage.vue"),
      },
      // Savings
      {
        path: "savings",
        name: "MemberSavingsOld",
        component: () => import("@/views/member/savings/SavingsPage.vue"),
      },
      // Settings
      {
        path: "settings",
        name: "MemberSettings",
        component: () => import("@/views/member/settings/SettingsPage.vue"),
      },
      // Transact
      {
        path: "transact",
        name: "MemberTransact",
        component: () => import("@/views/member/transact/TransactPage.vue"),
      },
      // productPage
      {
        path: "productPage",
        name: "ProductList",
        component: () => import("@/views/member/transact/productPage.vue"),
      },
      // DetailsPage
      {
        path: "product/:category",
        name: "ProductDetail",
        component: () => import("@/views/member/transact/DetailsPage.vue"),
      },
      // Loans Articles
      {
        path: "loans/articles",
        name: "MemberLoansArticles",
        component: () => import("@/views/member/loans/LoansArticlesPage.vue"),
        props: true,
      },
      {
        path: "loans/articles1",
        name: "MemberLoansArticles1",
        component: () => import("@/views/member/loans/Articles1Page.vue"),
        props: true,
      },
      {
        path: "loans/articles2",
        name: "MemberLoansArticles2",
        component: () => import("@/views/member/loans/Articles2Page.vue"),
        props: true,
      },
      {
        path: "loans/articles3",
        name: "MemberLoansArticles3",
        component: () => import("@/views/member/loans/Articles3Page.vue"),
        props: true,
      },
      {
        path: "loans/articles4",
        name: "MemberLoansArticles4",
        component: () => import("@/views/member/loans/Articles4Page.vue"),
        props: true,
      },
      {
        path: "loans/articles5",
        name: "MemberLoansArticles5",
        component: () => import("@/views/member/loans/Articles5Page.vue"),
        props: true,
      },

      // Apply For A Loan
      {
        path: "loans/apply-for-a-loan",
        name: "MemberApplyForALoan",
        component: () => import("@/views/member/loans/ApplyForALoanPage.vue"),
      },

      // Apply For A Loan
      {
        path: "loans/details/:id",
        name: "MemberLoanDetails",
        component: () => import("@/views/member/loans/LoanDetailsPage.vue"),
      },

      // sending and receiving communication
      {
        path: "inbox-messages",
        name: "SendingAndReceiving",
        component: () => import("@/views/member/SendingAndReceivingPage.vue"),
      },

      // my stokvel

      {
        path: "my-stokvel",
        name: "MyStokvel",
        component: () => import("@/views/member/my-stokvel/MyStovelPage.vue"),
      },

      // stokvel-constitution
      {
        path: "my-stokvel/stokvel-constitution",
        name: "MyStokvelDocument",
        component: () =>
          import("@/views/member/my-stokvel/MyStokvelDocumentPage.vue"),
      },

      {
        path: "my-stokvel/stokvel-constitution/stokvel",
        name: "StokvelConstitution",
        component: () => import("@/views/member/my-stokvel/StokvelPage.vue"),
      },
    ],
  },

  // Admin
  {
    path: "/admin/",
    component: () => import("@/layouts/admin.vue"),

    meta: {
      requiresAuth: true,
    },

    children: [
      // Loan Processing
      {
        path: "loan-processing",
        name: "AdminLoanProcessing",
        component: () => import("@/views/admin/LandingPage.vue"),
      },

      // Approve Loan
      {
        path: "loan-processing/approve-loan",
        name: "AdminLoanProcessingApproveLoan",
        component: () => import("@/views/admin/LoanProcessingPage.vue"),
      },

      // Process Request
      {
        path: "loan-processing/process-request/:id",
        name: "AdminLoanProcessingProcessRequest",
        component: () => import("@/views/admin/LoanInformation.vue"),
      },

      // Overview
      {
        path: "overview",
        name: "AdminOverview",
        component: () => import("@/views/admin/overview/OverviewPage.vue"),
      },

      // Adminstration
      {
        //path: "adminstration",
        path: "administration",
        name: "AdminAdminstration",
        component: () =>
          import("@/views/admin/adminstration/AdminstrationPage.vue"),
      },

      // Adminstration
      {
        //path: "adminstration/constitution",
        path: "administration/constitution",
        name: "AdminConstitution",
        component: () =>
          import("@/views/admin/adminstration/ConstitutionPage.vue"),
      },

      // DataAndUploads
      {
        path: "data-and-uploads",
        name: "AdminDataAndUploads",
        component: () =>
          import("@/views/admin/data-and-uploads/DataAndUploadsPage.vue"),
      },

      // pre upload file
      {
        //path: "adminstration/upload-file",
        path: "administration/upload-file",
        name: "AdminPreUploadFile",
        component: () =>
          import("@/views/admin/adminstration/PreUploadPage.vue"),
      },

      // Investment landing
      {
        path: "investment-processing",
        name: "AdminInvestmentProcessing",
        component: () =>
          import(
            "@/views/admin/investment-processing/InvestmentLandingPage.vue"
          ),
      },

      // Investment information
      {
        path: "investment-processing/process-request",
        name: "InvestmentInformationPage",
        component: () =>
          import(
            "@/views/admin/investment-processing/InvestmentInformationPage.vue"
          ),
      },

      // Investment processing
      {
        path: "investment-processing/approve-investment",
        name: "InvestmenProcessingPage",
        component: () =>
          import(
            "@/views/admin/investment-processing/InvestmenProcessingPage.vue"
          ),
      },

      // Messages
      {
        path: "messages",
        name: "AdminMessages",
        component: () => import("@/views/admin/messages/MessagesPage.vue"),
      },

      // Messages
      {
        path: "messages/compose-message",
        name: "AdminComposeMessages",
        component: () =>
          import("@/views/admin/messages/ComposeMessagesPage.vue"),
      },

      // Settings
      {
        path: "settings",
        name: "AdminSettings",
        component: () => import("@/views/admin/settings/SettingsPage.vue"),
      },

      // transactions
      {
        path: "transactions",
        name: "Transactions",
        component: () =>
          import("@/views/admin/transactions/TransactionsPage.vue"),
      },

      // transaction reports
      {
        path: "transactions/reports",
        name: "AdminTransactionsReports",
        component: () =>
          import("@/views/admin/transactions/TransactionsReportsPage.vue"),
      },

      // stokvel-members view
      {
        //path: "adminstration/members",
        path: "administration/members",
        name: "AdminMemberView",
        component: () => import("@/views/admin/member-view/MemberViewPage.vue"),
      },

      // stokvel-members view
      {
        //path: "adminstration/members/member-information/:id",
        path: "administration/members/member-information/:id",
        name: "AdminMemberInformation",
        component: () =>
          import("@/views/admin/member-view/MemberInformationPage.vue"),
      },

      // stokvel-member loan details view
      {
        //path: "adminstration/members/member-information/loan-details/:id",
        path: "administration/members/member-information/loan-details/:id",
        name: "AdminMemberLoanDetails",
        component: () =>
          import("@/views/admin/member-view/LoanDetailsPage.vue"),
      },
    ],
  },

  // Global Admin
  {
    path: "/super/admin/",
    component: () => import("@/layouts/globalAdmin.vue"),

    meta: {
      requiresAuth: true,
    },

    children: [
      // Overview
      {
        path: "overview",
        name: "GlobalAdminOverview",
        component: () =>
          import("@/views/global-admin/overview/OverviewPage.vue"),
      },

      // Messages
      {
        path: "messages",
        name: "GlobalAdminMessages",
        component: () =>
          import("@/views/global-admin/messages/MessagesPage.vue"),
      },

      // Settings
      {
        path: "settings",
        name: "GlobalAdminSettings",
        component: () =>
          import("@/views/global-admin/settings/SettingsPage.vue"),
      },

      // Investment landing
      {
        path: "investment-processing",
        name: "GlobalAdminInvestmentProcessing",
        component: () =>
          import(
            "@/views/global-admin/investment-processing/InvestmentLandingPage.vue"
          ),
      },

      // Investment processing
      {
        path: "investment-processing/approve-investment",
        name: "GlobalInvestmenProcessingPage",
        component: () =>
          import(
            "@/views/global-admin/investment-processing/InvestmenProcessingPage.vue"
          ),
      },
      // Investment information
      {
        path: "investment-processing/process-request/:id",
        name: "GlobalInvestmentInformationPage",
        component: () =>
          import(
            "@/views/global-admin/investment-processing/InvestmentInformationPage.vue"
          ),
      },

      // Investment information
      {
        path: "loan-processing",
        name: "GlobalAdminLoanProcessing",
        component: () =>
          import("@/views/global-admin/loan-processing/LoanProcessing.vue"),
      },
      // Policies And Systems
      // {
      //   path: "policies-and-systems",
      //   name: "GlobalPoliciesAndSystems",
      //   component: () =>
      //     import(
      //       "@/views/global-admin/policies-and-systems/PoliciesAndSystemsPage.vue"
      //     ),
      // },

      // transaction
      {
        path: "transaction",
        name: "GlobalTransaction",
        component: () =>
          import("@/views/global-admin/transaction/TransactionPage.vue"),
      },
      // transaction
      {
        path: "transaction/stokvel-information",
        name: "GlobalStokvelInformation",
        component: () =>
          import("@/views/global-admin/transaction/StokvelInformation.vue"),
      },
      // transaction
      {
        path: "transaction/stokvel-information/stokvel-statement",
        name: "GlobalStokvelStatement",
        component: () =>
          import("@/views/global-admin/transaction/StokvelStatement.vue"),
      },
      // Stokvel Member
      {
        path: "my-stokvels/stokvel-member",
        name: "GlobalAdminMyStokvelMember",
        component: () =>
          import("@/views/global-admin/my-stokvels/StokvelMembersPage.vue"),
      },

      // Stokvel Member
      {
        path: "my-stokvels/upload-file",
        name: "GlobalAdminUpluadFile",
        component: () =>
          import("@/views/global-admin/my-stokvels/GlobalUpluadFile.vue"),
      },
      // Stokvel Processing
      {
        path: "my-stokvels/stokvel-processing/:id",
        name: "GlobalAdminStokvelProcessing",
        component: () =>
          import(
            "@/views/global-admin/my-stokvels/stokvel-processing/StokvelProcessingPage.vue"
          ),
      },
      // My Stokvels
      {
        path: "my-stokvels",
        name: "GlobalAdminMyStokvels",
        component: () =>
          import("@/views/global-admin/my-stokvels/MyStokvelsPage.vue"),
      },
    ],
  },

  // Redirect to login if page not found
  {
    path: "/:catchAll(.*)",
    redirect: { name: "MemberLogin" },
  },
];

// router
const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,

  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

// router.beforeEach
router.beforeEach((to, from, next) => {
  // app/startLoading
  store.commit("app/startLoading");

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (
      !store.state.auth.authenticated &&
      !store.state.authBank.authenticated
    ) {
      next({ path: "/auth/login", query: { redirect: to.fullPath } });
    } else {
      next();
    }
  } else {
    next();
  }
});

// router.afterEach
router.afterEach(() => {
  // app/stopLoading
  store.commit("app/stopLoading");
});

export default router;
