import apiHelperFactoryForBank from "@/http/apiHelperFactoryForBank";
import helpers from "@/plugins/helpers";

const transactionApiBank = {
  namespaced: true,

  state: {
    // transaction
    transaction: [],

    // selectedValue
    selectedValue: null,

    index: null,
    currentDate: "",

    categoryValue: null,
    stokvelType: "",
    searchQuery: "",
    approverRoles: "",
    selectedCategory: "Uncategorised",
  },

  mutations: {
    // setTransaction
    setTransaction(state, payload) {
      state.transaction = payload;
    },

    setApproverRoles(state, payload) {
      state.approverRoles = payload;
    },
    // setShowDialog
    resetApproverRoles(state) {
      state.approverRoles = "";
    },

    setCurrentDate(state, payload) {
      state.currentDate = payload;
    },
    // setShowDialog
    resetCurrentDate(state) {
      state.currentDate = "";
    },

    setStokvelType(state, payload) {
      state.stokvelType = payload;
    },
    // setShowDialog
    resetStokvelType(state) {
      state.stokvelType = "";
    },

    // setSelectedValue
    setSelectedValue(state, payload) {
      state.selectedValue = payload;
    },
    // setSelectedValue
    setIndex(state, payload) {
      state.index = payload;
    },
    // setSelectedValue
    setCategoryValue(state, payload) {
      state.categoryValue = payload;
    },
    // setShowDialog
    setSearchQuery(state, payload) {
      state.searchQuery = payload;
    },
    // setShowDialog
    resetSearchQuery(state) {
      state.searchQuery = "";
    },
    // setShowDialog
    setSelectedCategory(state, payload) {
      state.selectedCategory = payload;
    },
    // setShowDialog
    resetSelectedCategory(state) {
      state.selectedCategory = "Uncategorised";
    },

    // resetState
    resetValues(state) {
      // transaction
      state.categoryValue = null;
      state.index = null;
    },

    // resetState
    resetState(state) {
      // transaction
      state.transaction = [];
    },
  },

  actions: {
    // getTransactionReq
    async getTransactionReq({ commit }, payload = {}) {
      // useLoading
      const useLoading = "useLoading" in payload ? payload.useLoading : true;

      // useLoading
      if (useLoading) {
        // startLoading
        commit("app/startLoading", null, { root: true });
      }

      // setUseAppToken
      commit("auth/setUseAppToken", true, { root: true });

      try {
        // getTransactionRes
        const getTransactionRes = await apiHelperFactoryForBank.getTransactions(
          payload
        );

        // setTransaction
        commit("setTransaction", getTransactionRes.data);

        return getTransactionRes;
      } catch (error) {
        if (error.response) {
          // handle response error
        } else if (error.request) {
          // handle request error
        } else {
          // handle other errors
        }
      } finally {
        // useLoading
        if (useLoading) {
          // stopLoading
          commit("app/stopLoading", null, { root: true });
        }

        // setUseAppToken
        commit("auth/setUseAppToken", false, { root: true });
      }
    },
    // deleteTransactionReq
    async deleteTransactiontReq({ commit }, { id }) {
      // useLoading
      const useLoading = true; // Assuming you always want loading indicators for delete operations

      // useLoading
      if (useLoading) {
        // startLoading
        commit("app/startLoading", null, { root: true });
      }

      // setUseAppToken
      commit("auth/setUseAppToken", true, { root: true });

      try {
        await apiHelperFactoryForBank.deleteTransactionts(id); // Replace with your delete API call
        // Optionally, you can commit an action or mutation to handle success.
        // commit("stokvel/deleteSuccess", stokvelId, { root: true });
      } catch (error) {
        // error.response
        if (error.response) {
          let res = error.response;

          if (res.status === 400) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 401) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 403) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 404) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 500) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else {
            // handleError
            helpers.handleError(res.status, res.data);
          }
        } else {
          // handleError
          helpers.handleError();
        }
      } finally {
        // useLoading
        if (useLoading) {
          // stopLoading
          commit("app/stopLoading", null, { root: true });
        }

        // setUseAppToken
        commit("auth/setUseAppToken", false, { root: true });
      }
    },

    // updateInvestmentReq
    async updateTransactionsReq({ commit }, { payload, transactionId }) {
      // useLoading
      const useLoading = "useLoading" in payload ? payload.useLoading : true;

      // useLoading
      if (useLoading) {
        // startLoading
        commit("app/startLoading", null, { root: true });
      }

      // setUseAppToken
      commit("auth/setUseAppToken", true, { root: true });

      try {
        return await apiHelperFactoryForBank.updateTransactions(
          payload,
          transactionId
        );
      } catch (error) {
        // error.response
        if (error.response) {
          let res = error.response;

          if (res.status === 400) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 401) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 403) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 404) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 500) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else {
            // handleError
            helpers.handleError(res.status, res.data);
          }
        } else {
          // handleError
          helpers.handleError();
        }
      } finally {
        // useLoading
        if (useLoading) {
          // stopLoading
          commit("app/stopLoading", null, { root: true });
        }

        // setUseAppToken
        commit("auth/setUseAppToken", false, { root: true });
      }
    },
  },

  getters: {
    // getTransaction
    getTransaction(state) {
      return state.transaction;
    },

    // getSelectedValue
    getSelectedValue(state) {
      return state.selectedValue;
    },

    // getFirstInvestment
    getFirstInvestment(state) {
      return state.transaction.length > 0 ? state.transaction[0] : null;
    },

    // getInvestmentById
    getInvestmentById: (state) => (id) => {
      // charge
      const transaction = state.transaction.find((trans) => trans.id === id);

      return transaction ? transaction : {};
    },

    // getMappedTransaction
    getMappedTransaction(state) {
      const searchQuery = state.searchQuery;
      const approverRole = state.approverRoles;
      const current = state.currentDate;
      console.log("searchQuery", searchQuery);
      console.log("searchQuery", approverRole);
      const category = {
        Uncategorised: 0,

        Payment: 1,

        Withdrawal: 2,

        Transfer: 3,

        "Admin Charge": 4,

        Loan: 5,
        "Interest Posting": 6,
      };
      if (state.index !== null) {
        state.transaction[state.index].categoryId =
          category[state.categoryValue];
      }
      // asset_manager
      const mapData = state.transaction.map((trans) => {
        const categories = {
          0: "Uncategorised",
          1: "Payment",
          2: "Withdrawal",
          3: "Transfer",
          4: "Admin Charge",
          5: "Loan",
          6: "Interest posting",
        };
        return {
          id: trans.id,
          date: trans.createdAt,
          description: trans.description,
          category: categories[trans.categoryId],
          amount: trans.amount,
          amountIncreased: false,
          allocated: true,
          stokvelID: trans.stokvelId,
          allocatedTo: trans.allocatedTo,
          showCol1: false,
          opened: false,
        };
      });

      function filterDataByTimePeriod(data, timePeriod) {
        const currentDate = new Date();

        switch (timePeriod) {
          case "Day":
            return data.filter((item) =>
              isSameDay(new Date(item.date), currentDate)
            );
          case "Week":
            return data.filter((item) =>
              isSameWeek(new Date(item.date), currentDate)
            );
          case "Month":
            return data.filter((item) =>
              isSameMonth(new Date(item.date), currentDate)
            );
          case "Year":
            return data.filter((item) =>
              isSameYear(new Date(item.date), currentDate)
            );
          default:
            return [];
        }
      }

      function isSameDay(date1, date2) {
        return (
          date1.getDate() === date2.getDate() &&
          date1.getMonth() === date2.getMonth() &&
          date1.getFullYear() === date2.getFullYear()
        );
      }

      function isSameWeek(date1, date2) {
        const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
        const diffDays = Math.round(Math.abs((date1 - date2) / oneDay));
        return diffDays <= 7 && date1.getDay() === date2.getDay();
      }

      function isSameMonth(date1, date2) {
        return (
          date1.getMonth() === date2.getMonth() &&
          date1.getFullYear() === date2.getFullYear()
        );
      }

      function isSameYear(date1, date2) {
        return date1.getFullYear() === date2.getFullYear();
      }

      const filteredData = filterDataByTimePeriod(mapData, current);
      console.log("this is data:::", filteredData);

      function searchArray(array, query) {
        // Ensure the array and query are provided
        if (!array || !query) {
          console.log("Array and query are required parameters.");
          return [];
        }

        // Filter the array based on the search query
        const results = array.filter((item) => {
          // Iterate through each field in the item
          for (const field in item) {
            // Check if the field contains the search query (case-insensitive)
            if (
              item[field] &&
              item[field].toString().toLowerCase().includes(query.toLowerCase())
            ) {
              return true; // Include the item in the results
            }
          }
          return false; // Exclude the item from the results
        });

        return results;
      }

      const searchResults = searchArray(mapData, searchQuery);
      const searchResult = searchArray(mapData, approverRole);
      console.log("searchResult:", searchResult);
      if (searchQuery !== "") {
        return searchResults;
      } else if (approverRole !== "") {
        return searchResult;
      } else if (current !== "") {
        console.log("::::::<>><><>", current);
        console.log("::::::<>><><>", filteredData);
        return filteredData;
      } else {
        return mapData;
      }
    },

    getFilterTransaction(state) {
      const category = {
        Uncategorised: 0,

        Payment: 1,

        Withdrawal: 2,

        Transfer: 3,

        "Admin Charge": 4,

        Loan: 5,
        "Interest Posting": 6,
      };
      if (state.index !== null) {
        state.transaction[state.index].categoryId =
          category[state.categoryValue];
      }
      const transactions = state.transaction.map((trans) => {
        const categories = {
          0: "Uncategorised",
          1: "Payment",
          2: "Withdrawal",
          3: "Transfer",
          4: "Admin Charge",
          5: "Loan",
          6: "Interest Posting",
        };

        return {
          id: trans.id,
          date: trans.createdAt,
          description: trans.description,
          category: categories[trans.categoryId],
          amount: trans.amount,
          amountIncreased: false,
          allocated: true,
          stokvelID: trans.stokvelId,
          allocatedTo: trans.allocatedTo,
          showCol1: false,
          opened: false,
        };
      });

      const filteredTransactions = transactions.filter(
        (tran) => tran.allocatedTo === "Not allocated"
      );

      return filteredTransactions;
    },
    getTransactionLength(state) {
      const transactions = state.transaction.map((trans) => {
        const categories = {
          0: "Uncategorised",
          1: "Payment",
          2: "Withdrawal",
          3: "Transfer",
          4: "Admin Charge",
          5: "Loan",
          6: "Interest posting",
        };

        return {
          id: trans.id,
          date: trans.createdAt,
          description: trans.description,
          category: categories[trans.categoryId],
          amount: trans.amount,
          amountIncreased: false,
          allocated: true,
          stokvelID: trans.stokvelId,
          allocatedTo: trans.allocatedTo,
          showCol1: false,
          opened: false,
        };
      });

      const filteredTransactions = transactions.length;

      return filteredTransactions;
    },
  },
};

export default transactionApiBank;
