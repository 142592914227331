import axios from "axios";
import store from "@/store"; // Import your Vuex store

const baseDomain = process.env.VUE_APP_API_BASE_URL;
const baseURL = `${baseDomain}`;

const instance = axios.create({
  baseURL,
});

instance.defaults.headers.common = {
  ApiKey: process.env.VUE_APP_API_KEY,
  "Access-Control-Allow-Origin": "*",
  "Content-Type": "application/json",
};

// Add a request interceptor
instance.interceptors.request.use(
  function (config) {
    const token = store.state.auth.token;
    const tenantIdentifier = store.state.auth.tenantIdentifier;

    // If token is not empty add it to the headers
    if (token && !store.state.auth.useAppToken) {
      config.headers["Authorization"] = "Basic " + token;
    } else if (store.state.auth.useAppWebUserToken) {
      config.headers["Authorization"] =
        "Basic " + process.env.VUE_APP_APP_WEB_USER_TOKEN;
    } else {
      config.headers["Authorization"] =
        "Basic " + process.env.VUE_APP_APP_TOKEN;
    }

    // If tenantIdentifier is not empty add it to the config params
    if (tenantIdentifier) {
      config.params = {
        ...config.params,
        tenantIdentifier: tenantIdentifier,
      };
    }

    return config;
  },

  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

export default instance;
