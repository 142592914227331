import Vue from "vue";
import Vuex from "vuex";

import createPersistedState from "vuex-persistedstate";

import app from "./modules/app";
import alert from "./modules/alert/alert";

import mobileSidebar from "./modules/app/mobileSidebar";
import snackbar from "./modules/app/snackbar";

// api
import accountTransferApi from "./modules/api/account-transfers/accountTransferApi";
import allocationApi from "./modules/api/allocations/allocationApi";
import bankingDetailApi from "./modules/api/banking-details/bankingDetailApi";
import chargeApi from "./modules/api/charges/chargeApi";
import clientDetailApi from "./modules/api/client-details/clientDetailApi";
import clientApi from "./modules/api/clients/clientApi";
import codeValueApi from "./modules/api/code-values/codeValueApi";
import codeApi from "./modules/api/codes/codeApi";
import contributionApi from "./modules/api/contributions/contributionApi";
import datatableApi from "./modules/api/datatables/datatableApi";
import downloadTemplateApi from "./modules/api/download-templates/downloadTemplateApi";
import employmentVerificationApi from "./modules/api/employment-verifications/employmentVerificationApi";
import glAccountApi from "./modules/api/gl-accounts/glAccountApi";
import importApi from "./modules/api/imports/importApi";
import insuranceApi from "./modules/api/insurances/insuranceApi";
import investmentApi from "./modules/api/investments/investmentApi";
import journalEntryApi from "./modules/api/journal-entries/journalEntryApi";
import loanProductApi from "./modules/api/loan-products/loanProductApi";
import loanApi from "./modules/api/loans/loanApi";
import noteApi from "./modules/api/notes/noteApi";
import notificationApi from "./modules/api/notifications/notificationApi";
import paymentTypeApi from "./modules/api/payment-types/paymentTypeApi";
import roleApi from "./modules/api/roles/roleApi";
import savingsAccountApi from "./modules/api/savings-accounts/savingsAccountApi";
import savingsAccountTransactionApi from "./modules/api/savings-accounts-transactions/savingsAccountTransactionApi";
import savingsProductApi from "./modules/api/savings-products/savingsProductApi";
import suspenseAccountTransactionApi from "./modules/api/suspense-account-transactions/suspenseAccountTransactionApi";
import taskApi from "./modules/api/tasks/taskApi";
import templateApi from "./modules/api/templates/templateApi";
import uploadTemplateApi from "./modules/api/upload-templates/uploadTemplateApi";
import userApi from "./modules/api/users/userApi";
import walletApi from "./modules/api/wallets/walletApi";
import clientsContactDetailsApi from "./modules/api/clients-contact-details/clientsContactDetailsApi.js";

import apiBank from "./modules/apiBank/apiBank";
import authBank from "./modules/apiBank/authBank";
import tenantApiBank from "./modules/apiBank/tenants/tenantApiBank";
import investmentApiBank from "./modules/apiBank/investment/investmentApiBank";
import transactionApiBank from "./modules/apiBank/transaction/transactionApiBank";
import stokvelApiBank from "./modules/apiBank/stokvel/stokvelApiBank";
import constitutionApiBank from "./modules/apiBank/constitutions/constitutionApiBank.js";
import emailsApiBank from "./modules/apiBank/emails/emailsApiBank.js";
import smsApiBank from "./modules/apiBank/sms/smsApiBank.js";
import finclude from "./modules/apiBank/finclude/finclude";

import messagesApiBank from "./modules/apiBank/messages/messagesApiBank";
import sentApiBank from "./modules/apiBank/messages/SentApiBank";
import inboxApiBank from "./modules/apiBank/messages/inboxApiBank";
import ccApiBank from "./modules/apiBank/messages/ccApiBank";
import replyApiBank from "./modules/apiBank/messages/replyApiBank";
import documentApiBank from "./modules/apiBank/messages/documentApiBank";

import adminForgotPassword from "./modules/auth/adminForgotPassword";
import adminLogin from "./modules/auth/adminLogin";
import adminInvitationLogin from "./modules/auth/adminInvitationLogin";
import adminResetPassword from "./modules/auth/adminResetPassword";
import adminSignUp from "./modules/auth/adminSignUp";
import adminStokvelApplication from "./modules/auth/adminStokvelApplication";
import auth from "./modules/auth/auth";
import authSliders from "./modules/auth/authSliders";
import memberForgotPassword from "./modules/auth/memberForgotPassword";
import memberLogin from "./modules/auth/memberLogin";
import memberResetPassword from "./modules/auth/memberResetPassword";
import memberSignUp from "./modules/auth/memberSignUp";
import memberInvite from "./modules/messages/memberInvite";

import contributions from "./modules/contributions/contributions";
import insurance from "./modules/insurance/insurance";
import administration from "./modules/Admin/administration/administration";

import applyForALoan from "./modules/applyForALoan";
import getAQuote from "./modules/getAQuote";
import loanDetails from "./modules/loanDetails";

import transaction from "./modules/transact/transaction";
import savings from "./modules/savings/savings";

import loanProcessing from "./modules/Admin/loan-processing/LoanProcessing";
import investmentProcessing from "./modules/Admin/investment-processing/investmentProcessing.js";

import stokvelAccount from "./modules/savings/stokvel-account/StokvelAccount";
import personalAccounts from "./modules/savings/personal-accounts/PersonalAccounts";

import adminTransaction from "./modules/Admin/transaction/Transaction";
import adminSetting from "./modules/Admin/settings/Settings";
import policiesAndSystem from "./modules/Admin/policies-and-systems/PoliciesAndSystem";

import stokvelProcessing from "./modules/global-admin/stokvelsProcessing";
import globalAdminTransaction from "./modules/global-admin/transactions/GlobalTransactions";
import message from "./modules/global-admin/messages/messages";

Vue.use(Vuex);
export default new Vuex.Store({
  state: {},

  getters: {},

  mutations: {},

  actions: {},

  modules: {
    alert,
    app,

    mobileSidebar,
    snackbar,

    accountTransferApi,
    allocationApi,
    bankingDetailApi,
    chargeApi,
    clientDetailApi,
    clientApi,
    codeValueApi,
    codeApi,
    contributionApi,
    datatableApi,
    downloadTemplateApi,
    employmentVerificationApi,
    glAccountApi,
    importApi,
    insuranceApi,
    investmentApi,
    journalEntryApi,
    loanProductApi,
    loanApi,
    noteApi,
    notificationApi,
    paymentTypeApi,
    roleApi,
    savingsAccountApi,
    savingsAccountTransactionApi,
    savingsProductApi,
    suspenseAccountTransactionApi,
    taskApi,
    templateApi,
    uploadTemplateApi,
    userApi,
    walletApi,
    clientsContactDetailsApi,

    apiBank,
    authBank,
    tenantApiBank,
    investmentApiBank,
    transactionApiBank,
    stokvelApiBank,
    constitutionApiBank,
    emailsApiBank,
    smsApiBank,
    adminForgotPassword,
    adminLogin,
    adminInvitationLogin,
    adminResetPassword,
    adminSignUp,
    adminStokvelApplication,
    auth,
    authSliders,
    memberForgotPassword,
    memberLogin,
    memberResetPassword,
    memberSignUp,
    memberInvite,

    messagesApiBank,
    sentApiBank,
    inboxApiBank,
    ccApiBank,
    replyApiBank,
    documentApiBank,

    contributions,
    insurance,
    administration,
    applyForALoan,
    getAQuote,
    loanDetails,

    finclude,

    transaction,
    stokvelAccount,

    loanProcessing,
    investmentProcessing,

    personalAccounts,
    savings,

    adminTransaction,
    adminSetting,
    policiesAndSystem,

    stokvelProcessing,
    globalAdminTransaction,
    message,
  },

  plugins: [createPersistedState()],
});
