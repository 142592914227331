import moment from "moment";

const app = {
  namespaced: true,

  state: {
    // dateFormat
    dateFormat: "dd MMMM yyyy",

    // locale
    locale: "en",

    // loading
    loading: false,
  },

  mutations: {
    // setDateFormat
    setDateFormat(state, payload) {
      state.dateFormat = payload;
    },

    // setLocale
    setLocale(state, payload) {
      state.locale = payload;
    },

    // setLoading
    setLoading(state, payload) {
      state.loading = payload;
    },

    // startLoading
    startLoading(state) {
      state.loading = true;
    },

    // stopLoading
    stopLoading(state) {
      // dateFormat
      state.dateFormat = "dd MMMM yyyy";

      // locale
      state.locale = "en";

      // loading
      state.loading = false;
    },

    // resetState
    resetState(state) {
      // dateFormat
      state.dateFormat = "dd MMMM yyyy";

      // locale
      state.locale = "en";

      // loading
      state.loading = false;
    },
  },

  actions: {
    // startLoading
    startLoading({ commit }) {
      commit("setLoading", true);
    },

    // stopLoading
    stopLoading({ commit }) {
      commit("setLoading", false);
    },
  },

  getters: {
    // getDateFormat
    getDateFormat(state) {
      return state.dateFormat;
    },

    // getLocale
    getLocale(state) {
      return state.locale;
    },

    // getToday
    getToday(state) {
      moment.locale(state.locale); // Set the locale

      // Convert 'dd MMMM yyyy' to 'DD MMMM YYYY'
      let momentFormat = state.dateFormat
        .replace("dd", "DD")
        .replace("yyyy", "YYYY");

      return moment().format(momentFormat);
    },
  },
};

export default app;
