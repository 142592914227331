import bankAxios from "@/plugins/bankAxios";

const isDevelopment = process.env.NODE_ENV === "development";

export default {
  // post
  post: async (url, body, params = {}, isFile = false) => {
    try {
      let config = {
        params: params,
      };

      if (isFile) {
        let formData = new FormData();

        // Append file to formData
        formData.append("file", body.file);

        // Append other properties to formData
        for (let property in body) {
          if (property !== "file") {
            formData.append(property, body[property]);
          }
        }

        config = {
          ...config,
          headers: { "Content-Type": "multipart/form-data" },
        };

        body = formData;
      }

      const response = await bankAxios.post(url, body, config);

      return response;
    } catch (error) {
      if (isDevelopment) {
        console.error(error);
      }

      throw error;
    }
  },
};
