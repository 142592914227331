import apiHelperFactory from "@/http/apiHelperFactory";
// import helpers from "@/plugins/helpers";

const glAccountApi = {
  namespaced: true,

  state: {
    // glAccountId
    glAccountId: 0,

    // glAccount
    glAccount: {},

    // glAccounts
    glAccounts: [],

    // glAccountsWithAssociation
    glAccountsWithAssociation: [],
  },

  mutations: {
    // setGlAccountId
    setGlAccountId(state, payload) {
      state.glAccountId = payload;
    },

    // setGlAccount
    setGlAccount(state, payload) {
      state.glAccount = payload;
    },

    // setGlAccounts
    setGlAccounts(state, payload) {
      state.glAccounts = payload;
    },

    // setGlAccountsWithAssociation
    setGlAccountsWithAssociation(state, payload) {
      state.glAccountsWithAssociation = payload;
    },

    // addGlAccountsWithAssociation
    addGlAccountsWithAssociation(state, payload) {
      // index
      const index = state.glAccountsWithAssociation.findIndex(
        (glAccount) => glAccount.id === payload.id
      );

      if (index === -1) {
        state.glAccountsWithAssociation.push(payload);
      } else {
        state.glAccountsWithAssociation[index] = payload;
      }
    },

    // resetState
    resetState(state) {
      // glAccountId
      state.glAccountId = 0;

      // glAccount
      state.glAccount = {};

      // glAccounts
      state.glAccounts = [];

      // glAccountsWithAssociation
      state.glAccountsWithAssociation = [];
    },
  },

  actions: {
    // getGlAccountsReq
    async getGlAccountsReq({ commit }, payload = {}) {
      // useLoading
      const useLoading = "useLoading" in payload ? payload.useLoading : true;

      // useLoading
      if (useLoading) {
        // startLoading
        commit("app/startLoading", null, { root: true });
      }

      // setUseAppToken
      commit("auth/setUseAppToken", true, { root: true });

      try {
        // getGlAccountsRes
        const getGlAccountsRes = await apiHelperFactory.getGlAccounts();

        // setGlAccounts
        commit("setGlAccounts", getGlAccountsRes.data);

        return getGlAccountsRes;
      } catch (error) {
        if (error.response) {
          // handle response error
        } else if (error.request) {
          // handle request error
        } else {
          // handle other errors
        }
      } finally {
        // useLoading
        if (useLoading) {
          // stopLoading
          commit("app/stopLoading", null, { root: true });
        }

        // setUseAppToken
        commit("auth/setUseAppToken", false, { root: true });
      }
    },

    // getGlAccountByIdReq
    async getGlAccountByIdReq({ commit }, payload = {}) {
      // useLoading
      const useLoading = "useLoading" in payload ? payload.useLoading : true;

      // useLoading
      if (useLoading) {
        // startLoading
        commit("app/startLoading", null, { root: true });
      }

      // setUseAppToken
      commit("auth/setUseAppToken", true, { root: true });

      try {
        // getGlAccountByIdRes
        const getGlAccountByIdRes = await apiHelperFactory.getGlAccount(
          payload.glAccountId,
          "params" in payload ? payload.params : {}
        );

        // setGlAccount
        commit("setGlAccount", getGlAccountByIdRes.data);

        return getGlAccountByIdRes;
      } catch (error) {
        if (error.response) {
          // handle response error
        } else if (error.request) {
          // handle request error
        } else {
          // handle other errors
        }
      } finally {
        // useLoading
        if (useLoading) {
          // stopLoading
          commit("app/stopLoading", null, { root: true });
        }

        // setUseAppToken
        commit("auth/setUseAppToken", false, { root: true });
      }
    },

    // getGlAccountByIdWithAssociationReq
    async getGlAccountByIdWithAssociationReq({ commit }, payload = {}) {
      // useLoading
      const useLoading = "useLoading" in payload ? payload.useLoading : true;

      // useLoading
      if (useLoading) {
        // startLoading
        commit("app/startLoading", null, { root: true });
      }

      // setUseAppToken
      commit("auth/setUseAppToken", true, { root: true });

      try {
        // getGlAccountByIdRes
        const getGlAccountByIdRes = await apiHelperFactory.getGlAccount(
          payload.glAccountId,
          "params" in payload
            ? payload.params
            : {
                fetchRunningBalance: true,
                template: true,
              }
        );

        // addGlAccountsWithAssociation
        commit("addGlAccountsWithAssociation", getGlAccountByIdRes.data);

        return getGlAccountByIdRes;
      } catch (error) {
        if (error.response) {
          // handle response error
        } else if (error.request) {
          // handle request error
        } else {
          // handle other errors
        }
      } finally {
        // useLoading
        if (useLoading) {
          // stopLoading
          commit("app/stopLoading", null, { root: true });
        }

        // setUseAppToken
        commit("auth/setUseAppToken", false, { root: true });
      }
    },
  },

  getters: {
    // getGlAccountId
    getGlAccountId(state) {
      return state.glAccountId;
    },

    // getGlAccount
    getGlAccount(state) {
      return state.glAccount;
    },

    // getGlAccountById
    getGlAccountById: (state) => (id) => {
      // glAccount
      const glAccount = state.glAccounts.find(
        (glAccount) => glAccount.id === id
      );

      return glAccount ? glAccount : {};
    },

    // getGlAccounts
    getGlAccounts(state) {
      return state.glAccounts;
    },

    // getGlAccountNames
    getGlAccountNames(state) {
      return state.glAccounts.map((glAccount) => glAccount.name);
    },

    // getGlAccountIdByName
    getGlAccountIdByName: (state) => (name) => {
      // glAccountName
      const glAccountName = name.toLowerCase().trim();

      // glAccount
      const glAccount = state.glAccounts.find(
        (glAccount) => glAccount.name.toLowerCase().trim() === glAccountName
      );

      return glAccount ? glAccount.id : null;
    },

    // getGlAccountByIdWithAssociation
    getGlAccountByIdWithAssociation: (state) => (id) => {
      // glAccount
      const glAccount = state.glAccountsWithAssociation.find(
        (glAccount) => glAccount.id === id
      );

      return glAccount ? glAccount : {};
    },

    // getGlAccountsWithAssociation
    getGlAccountsWithAssociation(state) {
      return state.glAccountsWithAssociation;
    },

    // getGlAccountNamesWithAssociation
    getGlAccountNamesWithAssociation(state) {
      return state.glAccountsWithAssociation.map((glAccount) => glAccount.name);
    },

    // getGlAccountIdByNameWithAssociation
    getGlAccountIdByNameWithAssociation: (state) => (name) => {
      // glAccountName
      const glAccountName = name.toLowerCase().trim();

      // glAccount
      const glAccount = state.glAccountsWithAssociation.find(
        (glAccount) => glAccount.name.toLowerCase().trim() === glAccountName
      );

      return glAccount ? glAccount.id : null;
    },

    // getGlAccountByNameWithAssociation
    getGlAccountByNameWithAssociation: (state) => (name) => {
      // glAccountName
      const glAccountName = name.toLowerCase().trim();

      // glAccount
      const glAccount = state.glAccountsWithAssociation.find(
        (glAccount) => glAccount.name.toLowerCase().trim() === glAccountName
      );

      return glAccount ? glAccount : null;
    },
  },
};

export default glAccountApi;
