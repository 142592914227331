import apiHelperFactory from "@/http/apiHelperFactory";
import helpers from "@/plugins/helpers";

const savingsProductApi = {
  namespaced: true,

  state: {
    // savingsProducts
    savingsProducts: [],

    // savingsProduct
    savingsProduct: {},

    // savingsProducts
    savingsProductsTable: [],
  },

  mutations: {
    // setSavingsProducts
    setSavingsProducts(state, payload) {
      state.savingsProducts = payload;
    },

    // setSavingsProduct
    setSavingsProduct(state, payload) {
      state.savingsProduct = payload;
    },

    // setSavingsProductsTable
    setSavingsProductsTable(state, payload) {
      state.savingsProductsTable = payload;
    },

    // resetState
    resetState(state) {
      // savingsProducts
      state.savingsProducts = [];

      // savingsProductsTable
      state.savingsProductsTable = [];
    },
  },

  actions: {
    // getSavingsProductsReq
    async getSavingsProductsReq({ commit }, payload = {}) {
      // useLoading
      const useLoading = "useLoading" in payload ? payload.useLoading : true;

      // useLoading
      if (useLoading) {
        // startLoading
        commit("app/startLoading", null, { root: true });
      }

      // setUseAppToken
      commit("auth/setUseAppToken", true, { root: true });

      try {
        // getSavingsProductsRes
        const getSavingsProductsRes =
          await apiHelperFactory.getSavingsProducts();

        // setSavingsProducts
        commit("setSavingsProducts", getSavingsProductsRes.data);

        return getSavingsProductsRes;
      } catch (error) {
        if (error.response) {
          // handle response error
        } else if (error.request) {
          // handle request error
        } else {
          // handle other errors
        }
      } finally {
        // useLoading
        if (useLoading) {
          // stopLoading
          commit("app/stopLoading", null, { root: true });
        }

        // setUseAppToken
        commit("auth/setUseAppToken", false, { root: true });
      }
    },

    // getSavingsProductByIdReq
    async getSavingsProductByIdReq({ commit }, payload = {}) {
      // useLoading
      const useLoading = "useLoading" in payload ? payload.useLoading : true;

      // useLoading
      if (useLoading) {
        // startLoading
        commit("app/startLoading", null, { root: true });
      }

      // setUseAppToken
      commit("auth/setUseAppToken", true, { root: true });
      console.log("here 1 ");

      try {
        console.log("here 2");
        // getSavingsProductByIdRes
        const getSavingsProductByIdRes =
          await apiHelperFactory.getSavingsProductById(
            payload.savingsProductId
          );
        console.log("here 3");

        // setSelectedLoanProductFullDetails
        commit("setSavingsProduct", getSavingsProductByIdRes.data);

        return getSavingsProductByIdRes;
      } catch (error) {
        if (error.response) {
          // handle response error
        } else if (error.request) {
          // handle request error
        } else {
          // handle other errors
        }
      } finally {
        // useLoading
        if (useLoading) {
          // stopLoading
          commit("app/stopLoading", null, { root: true });
        }

        // setUseAppToken
        commit("auth/setUseAppToken", false, { root: true });
      }
    },

    async updateSavingsProductsReq({ commit }, { payload, savingsProductId }) {
      // startLoading
      commit("app/startLoading", null, { root: true });

      // setUseAppToken
      commit("auth/setUseAppToken", true, { root: true });

      try {
        return await apiHelperFactory.updateSavingsProduct(
          payload,
          savingsProductId
        );
      } catch (error) {
        // error.response
        if (error.response) {
          let res = error.response;

          if (res.status === 400) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 401) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 403) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 404) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 500) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else {
            // handleError
            helpers.handleError(res.status, res.data);
          }
        } else {
          // handleError
          helpers.handleError();
        }
      } finally {
        // setUseAppToken
        commit("auth/setUseAppToken", false, { root: true });

        // stopLoading
        commit("app/stopLoading", null, { root: true });
      }
    },

    // getSavingsProductTableReq
    async getSavingsProductTableReq({ commit }, payload = {}) {
      // useLoading
      const useLoading = "useLoading" in payload ? payload.useLoading : true;

      // useLoading
      if (useLoading) {
        // startLoading
        commit("app/startLoading", null, { root: true });
      }

      // setUseAppToken
      commit("auth/setUseAppToken", true, { root: true });

      try {
        // getSavingsProductTableRes
        const getSavingsProductTableRes =
          await apiHelperFactory.getSavingsProductTable(payload.officeId);

        // setSavingsProducts
        commit("setSavingsProductsTable", getSavingsProductTableRes.data);

        return getSavingsProductTableRes;
      } catch (error) {
        if (error.response) {
          // handle response error
        } else if (error.request) {
          // handle request error
        } else {
          // handle other errors
        }
      } finally {
        // useLoading
        if (useLoading) {
          // stopLoading
          commit("app/stopLoading", null, { root: true });
        }

        // setUseAppToken
        commit("auth/setUseAppToken", false, { root: true });
      }
    },

    // createSavingsProductTableReq
    async createSavingsProductTableReq({ commit }, { payload, officeId }) {
      // useLoading
      const useLoading = "useLoading" in payload ? payload.useLoading : true;

      // useLoading
      if (useLoading) {
        // startLoading
        commit("app/startLoading", null, { root: true });
      }

      // setUseAppToken
      commit("auth/setUseAppToken", true, { root: true });

      try {
        return await apiHelperFactory.createSavingsProductTable(
          payload,
          officeId
        );
      } catch (error) {
        // error.response
        if (error.response) {
          let res = error.response;

          if (res.status === 400) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 401) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 403) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 404) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 500) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else {
            // handleError
            helpers.handleError(res.status, res.data);
          }
        } else {
          // handleError
          helpers.handleError();
        }
      } finally {
        // useLoading
        if (useLoading) {
          // stopLoading
          commit("app/stopLoading", null, { root: true });
        }

        // setUseAppToken
        commit("auth/setUseAppToken", false, { root: true });
      }
    },

    // updateSavingsProductTableReq
    async updateSavingsProductTableReq({ commit }, { payload, officeId }) {
      // useLoading
      const useLoading = "useLoading" in payload ? payload.useLoading : true;

      // useLoading
      if (useLoading) {
        // startLoading
        commit("app/startLoading", null, { root: true });
      }

      // setUseAppToken
      commit("auth/setUseAppToken", true, { root: true });

      try {
        return await apiHelperFactory.updateSavingsProductTable(
          payload,
          officeId
        );
      } catch (error) {
        // error.response
        if (error.response) {
          let res = error.response;

          if (res.status === 400) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 401) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 403) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 404) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 500) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else {
            // handleError
            helpers.handleError(res.status, res.data);
          }
        } else {
          // handleError
          helpers.handleError();
        }
      } finally {
        // useLoading
        if (useLoading) {
          // stopLoading
          commit("app/stopLoading", null, { root: true });
        }

        // setUseAppToken
        commit("auth/setUseAppToken", false, { root: true });
      }
    },
  },

  getters: {
    // getSavingsProducts
    getSavingsProducts(state) {
      console.log("state.savingsProducts", state.savingsProducts);
      return state.savingsProducts;
    },

    // getSavingsProduct
    getSavingsProduct(state) {
      return state.savingsProduct;
    },

    // getSavingsProductsTable
    getSavingsProductsTable(state) {
      return state.savingsProductsTable;
    },

    // getFirstSavingsProduct
    getFirstSavingsProduct(state) {
      return state.savingsProducts.length > 0 ? state.savingsProducts[0] : null;
    },

    getFirstContributionSavingsProduct(state) {
      return state.savingsProducts.length > 0 ? state.savingsProducts[1] : null;
    },
  },
};

export default savingsProductApi;
