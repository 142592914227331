import apiHelperFactoryForBank from "@/http/apiHelperFactoryForBank";
import helpers from "@/plugins/helpers";

const tenantApiBank = {
  namespaced: true,

  state: {
    // tenantName
    tenantName: "",

    // tenantNameForMember
    tenantNameForMember: "",

    // tenant
    tenant: {},

    // tenants
    tenants: [],
  },

  mutations: {
    // setTenantName
    setTenantName(state, payload) {
      state.tenantName = payload;
    },

    // setTenantNameForMember
    setTenantNameForMember(state, payload) {
      state.tenantNameForMember = payload;
    },

    // setTenant
    setTenant(state, payload) {
      state.tenant = payload;
    },

    // setTenants
    setTenants(state, payload) {
      state.tenants = payload;
    },

    // resetState
    resetState(state) {
      // tenants
      // state.tenants = [];

      // tenantName
      state.tenantName = "";

      // tenantNameForMember
      state.tenantNameForMember = "";

      // tenant
      state.tenant = {};
    },
  },

  actions: {
    // getTenantsReq
    async getTenantsReq({ commit }, payload = {}) {
      // useLoading
      const useLoading = "useLoading" in payload ? payload.useLoading : true;

      // useLoading
      if (useLoading) {
        // startLoading
        commit("app/startLoading", null, { root: true });
      }

      // setUseAppToken
      commit("auth/setUseAppToken", true, { root: true });

      try {
        // getTenantsRes
        const getTenantsRes = await apiHelperFactoryForBank.getTenants(
          payload.companyName
        );

        // setTenants
        commit("setTenants", getTenantsRes.data);

        return getTenantsRes;
      } catch (error) {
        if (error.response) {
          // handle response error
        } else if (error.request) {
          // handle request error
        } else {
          // handle other errors
        }
      } finally {
        // useLoading
        if (useLoading) {
          // stopLoading
          commit("app/stopLoading", null, { root: true });
        }

        // setUseAppToken
        commit("auth/setUseAppToken", false, { root: true });
      }
    },

    // loginIntoTenantReq
    async loginIntoTenantReq({ commit, dispatch }, payload = {}) {
      // useLoading
      const useLoading = "useLoading" in payload ? payload.useLoading : true;

      // useLoading
      if (useLoading) {
        // startLoading
        commit("app/startLoading", null, { root: true });
      }

      // setUseAppToken
      commit("auth/setUseAppToken", true, { root: true });

      try {
        // loginIntoTenantRes
        const loginIntoTenantRes =
          await apiHelperFactoryForBank.loginIntoTenant(payload.body);

        // setAuth
        commit("auth/setAuth", loginIntoTenantRes.data, { root: true });

        return loginIntoTenantRes;
      } catch (error) {
        // error.response
        if (error.response) {
          let res = error.response;

          if (res.status === 400) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 401) {
            // handleError
            helpers.handleError(res.status, res.data);

            if (res.status > 10000) {
              // logOutAuto
              dispatch("auth/logOutAuto", null, { root: true });
            }
          } else if (res.status === 403) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 404) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 500) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else {
            // handleError
            helpers.handleError(res.status, res.data);
          }
        } else {
          // handleError
          helpers.handleError();
        }
      } finally {
        // useLoading
        if (useLoading) {
          // stopLoading
          commit("app/stopLoading", null, { root: true });
        }

        // setUseAppToken
        commit("auth/setUseAppToken", false, { root: true });
      }
    },
  },

  getters: {
    // getTenantName
    getTenantName(state) {
      return state.tenantName;
    },

    // getTenantNameForMember
    getTenantNameForMember(state) {
      return state.tenantNameForMember;
    },

    // getTenant
    getTenant(state) {
      return state.tenant;
    },

    // getTenants
    getTenants(state) {
      return state.tenants;
    },

    // getTenantNames
    getTenantNames(state) {
      // tenantNames
      const tenantNames = [...state.tenants.map((tenant) => tenant.name)];

      // Sort the tenant names alphabetically
      tenantNames.sort();

      // Append "Other" to the sorted tenant names
      tenantNames.push("Other");

      return tenantNames;
    },

    // getTenantMainNames
    getTenantMainNames(state) {
      return state.tenants.map((tenant) => tenant.name);
    },

    //getTenantNameById
    getTenantNameById: (state) => (id) => {
      const tenant = state.tenants.find((tenant) => tenant.id === id);

      return tenant ? tenant.name : null;
    },

    //getTenantById
    getTenantById: (state) => (id) => {
      const tenant = state.tenants.find((tenant) => tenant.id === id);

      return tenant ? tenant : null;
    },

    // getTenantIdByName
    getTenantIdByName: (state) => (name) => {
      const tenant = state.tenants.find((tenant) => tenant.name === name);

      return tenant ? tenant.id : null;
    },

    // getTenantByName
    getTenantByName: (state) => (name) => {
      const tenant = state.tenants.find((tenant) => tenant.name === name);

      return tenant ? tenant : null;
    },
  },
};

export default tenantApiBank;
