const contributions = {
  namespaced: true,

  state: {
    // contributionId
    contributionId: 0,

    // currentContribution
    currentContribution: "From My Wallet",

    // showSavedContributions
    showSavedContributions: false,

    // currentDialog
    currentDialog: "Add Contribution",

    // showDialog
    showDialog: false,

    // currentDialogStep
    currentDialogStep: 1,

    // presetContribution
    presetContribution: true,

    // presetAmount
    presetAmount: "1000.00",

    // useFromMyEmployer
    useFromMyEmployer: true,

    // deactivateContribution
    deactivateContribution: true,

    // nameOfEmployer
    nameOfEmployer: "",

    // employerContactNumber
    employerContactNumber: "",

    // idNumberEmployer
    idNumberEmployer: "",

    // mobileMoneyWalletNumber
    mobileMoneyWalletNumber: "",

    // accountNumber
    accountNumber: "",

    // branchCode
    branchCode: "",

    // stopOrderDate
    stopOrderDate: "",

    // accountType
    accountType: "",

    // bankName
    bankName: "",

    // loanAccountNumber
    loanAccountNumber: "",

    // referenceName
    referenceName: "",

    // mobileMoneyServiceProvider
    mobileMoneyServiceProvider: "",
  },

  mutations: {
    // setContributionId
    setContributionId(state, payload) {
      state.contributionId = payload;
    },

    // setCurrentContribution
    setCurrentContribution(state, payload) {
      state.currentContribution = payload;
    },

    // ShowSavedContributions
    setShowSavedContributions(state, payload) {
      state.showSavedContributions = payload;
    },

    // setCurrentDialog
    setCurrentDialog(state, payload) {
      state.currentDialog = payload;
    },

    // ShowDialog
    setShowDialog(state, payload) {
      state.showDialog = payload;
    },

    // setCurrentDialogStep
    setCurrentDialogStep(state, payload) {
      state.currentDialogStep = payload;
    },

    // setPresetContribution
    setPresetContribution(state, payload) {
      state.presetContribution = payload;
    },

    // setPresetAmount
    setPresetAmount(state, payload) {
      state.presetAmount = payload;
    },

    // setUseFromMyEmployer
    setUseFromMyEmployer(state, payload) {
      state.useFromMyEmployer = payload;
    },

    // setDeactivateContribution
    setDeactivateContribution(state, payload) {
      state.deactivateContribution = payload;
    },

    // setNameOfEmployer
    setNameOfEmployer(state, payload) {
      state.nameOfEmployer = payload;
    },

    // setEmployerContactNumber
    setEmployerContactNumber(state, payload) {
      state.employerContactNumber = payload;
    },

    // setIdNumberEmployer
    setIdNumberEmployer(state, payload) {
      state.idNumberEmployer = payload;
    },

    // setMobileMoneyWalletNumber
    setMobileMoneyWalletNumber(state, payload) {
      state.mobileMoneyWalletNumber = payload;
    },

    // setAccountNumber
    setAccountNumber(state, payload) {
      state.accountNumber = payload;
    },

    // setBranchCode
    setBranchCode(state, payload) {
      state.branchCode = payload;
    },

    // setStopOrderDate
    setStopOrderDate(state, payload) {
      state.stopOrderDate = payload;
    },

    // setAccountType
    setAccountType(state, payload) {
      state.accountType = payload;
    },

    // setBankName
    setBankName(state, payload) {
      state.bankName = payload;
    },

    // setLoanAccountNumber
    setLoanAccountNumber(state, payload) {
      state.loanAccountNumber = payload;
    },

    // setReferenceName
    setReferenceName(state, payload) {
      state.referenceName = payload;
    },

    // setMobileMoneyServiceProvider
    setMobileMoneyServiceProvider(state, payload) {
      state.mobileMoneyServiceProvider = payload;
    },

    // resetState
    resetState(state) {
      // contributionId
      state.contributionId = 0;

      // currentContribution
      state.currentContribution = "From My Wallet";

      // showSavedContributions
      state.showSavedContributions = false;

      // currentDialog
      state.currentDialog = "Add Contribution";

      // showDialog
      state.showDialog = false;

      // currentDialogStep
      state.currentDialogStep = 1;

      // presetContribution
      state.presetContribution = false;

      // presetAmount
      state.presetAmount = "1000.00";

      // useFromMyEmployer
      state.useFromMyEmployer = true;

      // deactivateContribution
      state.deactivateContribution = true;

      // nameOfEmployer
      state.nameOfEmployer = "";

      // employerContactNumber
      state.employerContactNumber = "";

      // idNumberEmployer
      state.idNumberEmployer = "";

      // mobileMoneyWalletNumber
      state.mobileMoneyWalletNumber = "";

      // accountNumber
      state.accountNumber = "";

      // branchCode
      state.branchCode = "";

      // stopOrderDate
      state.stopOrderDate = "";

      // accountType
      state.accountType = "";

      // bankName
      state.bankName = "";

      // loanAccountNumber
      state.loanAccountNumber = "";

      // referenceName
      state.referenceName = "";

      // mobileMoneyServiceProvider
      state.mobileMoneyServiceProvider = "";
    },
  },

  actions: {},

  getters: {
    // getContributionId
    getContributionId(state) {
      return state.contributionId;
    },

    // getCurrentContribution
    getCurrentContribution(state) {
      return state.currentContribution;
    },

    // getCurrentContributionForBackend
    getCurrentContributionForBackend: (state) => {
      const userFriendlyToBackendMapping = {
        "From My Wallet": "Wallet",
        "From My Bank Account": "Bank Account",
        "From My Employer": "Payroll",
      };

      return userFriendlyToBackendMapping[state.currentContribution] || null;
    },

    // getShowSavedContributions
    getShowSavedContributions(state) {
      return state.showSavedContributions;
    },

    // getCurrentDialog
    getCurrentDialog(state) {
      return state.currentDialog;
    },

    // getShowDialog
    getShowDialog(state) {
      return state.showDialog;
    },

    // getCurrentDialogStep
    getCurrentDialogStep(state) {
      return state.currentDialogStep;
    },

    // getPresetContribution
    getPresetContribution(state) {
      return state.presetContribution;
    },

    // getPresetAmount
    getPresetAmount(state) {
      return state.presetAmount;
    },

    // getUseFromMyEmployer
    getUseFromMyEmployer(state) {
      return state.useFromMyEmployer;
    },

    // getDeactivateContribution
    getDeactivateContribution(state) {
      return state.deactivateContribution;
    },

    // getNameOfEmployer
    getNameOfEmployer(state) {
      return state.nameOfEmployer;
    },

    // getEmployerContactNumber
    getEmployerContactNumber(state) {
      return state.employerContactNumber;
    },

    // getIdNumberEmployer
    getIdNumberEmployer(state) {
      return state.idNumberEmployer;
    },

    // getMobileMoneyWalletNumber
    getMobileMoneyWalletNumber(state) {
      return state.mobileMoneyWalletNumber;
    },

    // getAccountNumber
    getAccountNumber(state) {
      return state.accountNumber;
    },

    // getBranchCode
    getBranchCode(state) {
      return state.branchCode;
    },

    // getStopOrderDate
    getStopOrderDate(state) {
      return state.stopOrderDate;
    },

    // getAccountType
    getAccountType(state) {
      return state.accountType;
    },

    // getBankName
    getBankName(state) {
      return state.bankName;
    },

    // getLoanAccountNumber
    getLoanAccountNumber(state) {
      return state.loanAccountNumber;
    },

    // getReferenceName
    getReferenceName(state) {
      return state.referenceName;
    },

    // getMobileMoneyServiceProvider
    getMobileMoneyServiceProvider(state) {
      return state.mobileMoneyServiceProvider;
    },
  },
};

export default contributions;
