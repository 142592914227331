const memberInvite = {
  namespaced: true,

  state: {
    // Invite
    // showInviteDialog
    showInviteDialog: false,

    // showInviteMobile
    showInviteMobile: false,

    // InviteDialogStep
    inviteDialogStep: 1,
  },

  mutations: {
    // Invite
    // setShowInviteDialog
    setShowInviteDialog(state, payload) {
      state.showInviteDialog = payload;
    },

    // setShowInviteMobile
    setShowInviteMobile(state, payload) {
      state.showInviteMobile = payload;
    },

    // setInviteDialogStep
    setInviteDialogStep(state, payload) {
      state.inviteDialogStep = payload;
    },

    resetState(state) {
      // Invest

      //Invite
      // showInviteDialog
      state.showInviteDialog = false;

      // showInviteMobile
      state.showInviteMobile = false;

      // inviteDialogStep
      state.inviteDialogStep = 1;
    },
  },

  actions: {},

  getters: {
    // getShowInviteDialog
    getShowInviteDialog(state) {
      return state.showInviteDialog;
    },

    // getShowInviteMobile
    getShowInviteMobile(state) {
      return state.showInviteMobile;
    },

    // getInviteDialogStep
    getInviteDialogStep(state) {
      return state.inviteDialogStep;
    },
  },
};

export default memberInvite;
