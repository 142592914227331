const loanProcessing = {
  namespaced: true,

  state: {
    // decline
    // showLoanProcessingDeclineDialog
    showLoanProcessingDeclineDialog: false,
    loanProcessingDeclineDialogStep: 1,
    showLoanProcessingDeclineMobileForm: false,

    // approve
    showLoanProcessingApproveDialog: false,
    loanProcessingApproveDialogStep: 1,
    showLoanProcessingApproveMobileForm: false,

    // disburse
    showLoanProcessingDisburseDialog: false,
    loanProcessingDisburseDialogStep: 1,
    showLoanProcessingDisburseMobileForm: false,

    // calculator
    showCalculatorDialog: true,
    calculatorDialogStep: 1,

    // amount
    amount: 38000,
    amountMin: 2000,
    amountInterval: 50,
    amountMax: 50000,

    // Loan amount
    loanAmountRequest: 30000,

    // months
    months: 6,
    monthsMin: 0,
    monthsInterval: 1,
    monthsMax: 72,
  },

  mutations: {
    // decline
    setShowLoanProcessingDeclineDialog(state, payload) {
      state.showLoanProcessingDeclineDialog = payload;
    },

    setLoanProcessingDeclineDialogStep(state, payload) {
      state.loanProcessingDeclineDialogStep = payload;
    },

    setShowLoanProcessingDeclineMobileForm(state, payload) {
      state.showLoanProcessingDeclineMobileForm = payload;
    },

    // approve
    setShowLoanProcessingApproveDialog(state, payload) {
      state.showLoanProcessingApproveDialog = payload;
    },

    setLoanProcessingApproveDialogStep(state, payload) {
      state.loanProcessingApproveDialogStep = payload;
    },

    setShowLoanProcessingApproveMobileForm(state, payload) {
      state.showLoanProcessingApproveMobileForm = payload;
    },

    // disburse
    setShowLoanProcessingDisburseDialog(state, payload) {
      state.showLoanProcessingDisburseDialog = payload;
    },

    setLoanProcessingDisburseDialogStep(state, payload) {
      state.loanProcessingDisburseDialogStep = payload;
    },

    setShowLoanProcessingDisburseMobileForm(state, payload) {
      state.showLoanProcessingDisburseMobileForm = payload;
    },

    // calculator
    setShowCalculatorDialog(state, payload) {
      state.showCalculatorDialog = payload;
    },

    setShowCalculatorDialogStep(state, payload) {
      state.calculatorDialogStep = payload;
    },

    // setAmount
    setAmount(state, payload) {
      state.amount = payload;
    },

    // setLoanAmount
    setLoanAmount(state, payload) {
      state.loanAmountRequest = payload;
    },

    // setMonths
    setMonths(state, payload) {
      state.months = payload;
    },

    // clearCalculator
    clearCalculator(state) {
      state.amount = 38000;
      state.loanAmountRequest = 30000;
      state.months = 6;
      state.interestRate = 8;
    },

    // resetState
    resetState(state) {
      // decline
      state.showLoanProcessingDeclineDialog = false;
      state.loanProcessingDeclineDialogStep = 1;
      state.showLoanProcessingDeclineMobileForm = false;

      // approve
      state.showLoanProcessingApproveDialog = false;
      state.loanProcessingApproveDialogStep = 1;
      state.showLoanProcessingApproveMobileForm = false;

      // disburse
      state.showLoanProcessingDisburseDialog = false;
      state.loanProcessingDisburseDialogStep = 1;
      state.showLoanProcessingDisburseMobileForm = false;

      // calculator
      state.showCalculatorDialog = false;
      state.calculatorDialogStep = 1;

      // amount
      state.amount = 38000;
      state.amountMin = 2000;
      state.amountInterval = 50;
      state.amountMax = 50000;

      // Loan amount
      state.loanAmountRequest = 30000;

      // months
      state.months = 6;
      state.monthsMin = 0;
      state.monthsInterval = 1;
      state.monthsMax = 72;
    },
  },

  actions: {},

  getters: {
    // decline
    getShowLoanProcessingDeclineDialog(state) {
      return state.showLoanProcessingDeclineDialog;
    },

    getLoanProcessingDeclineStep(state) {
      return state.loanProcessingDeclineDialogStep;
    },

    getShowLoanProcessingDeclineMobileForm(state) {
      return state.showLoanProcessingDeclineMobileForm;
    },

    // approve
    getShowLoanProcessingApproveDialog(state) {
      return state.showLoanProcessingApproveDialog;
    },

    getLoanProcessingApproveStep(state) {
      return state.loanProcessingApproveDialogStep;
    },

    getShowLoanProcessingApproveMobileForm(state) {
      return state.showLoanProcessingApproveMobileForm;
    },

    // disburse
    getShowLoanProcessingDisburseDialog(state) {
      return state.showLoanProcessingDisburseDialog;
    },

    getLoanProcessingDisburseStep(state) {
      return state.loanProcessingDisburseDialogStep;
    },

    getShowLoanProcessingDisburseMobileForm(state) {
      return state.showLoanProcessingDisburseMobileForm;
    },

    // Calculator
    getShowCalculatorDialog(state) {
      return state.showCalculatorDialog;
    },

    getCalculatorStep(state) {
      return state.calculatorDialogStep;
    },

    // getAmount
    getAmount(state) {
      return state.amount;
    },

    // getAmount
    getLoanAmount(state) {
      return state.loanAmountRequest;
    },

    // getMonths
    getMonths(state) {
      return state.months;
    },
  },
};

export default loanProcessing;
