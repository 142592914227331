import apiHelperFactory from "@/http/apiHelperFactory";
import helpers from "@/plugins/helpers";

const noteApi = {
  namespaced: true,

  state: {
    // noteId
    noteId: 0,

    // note
    note: {},

    // notes
    notes: [],
  },

  mutations: {
    // setNoteId
    setNoteId(state, payload) {
      state.noteId = payload;
    },

    // setNote
    setNote(state, payload) {
      state.note = payload;

      // noteId
      if ("resourceId" in payload) {
        state.noteId = payload.resourceId;
      }

      // noteId
      if ("id" in payload) {
        state.noteId = payload.id;
      }
    },

    // setNotes
    setNotes(state, payload) {
      state.notes = payload;
    },

    // resetState
    resetState(state) {
      // noteId
      state.noteId = 0;

      // note
      state.note = {};

      // notes
      state.notes = [];
    },
  },

  actions: {
    // getNotesReq
    async getNotesReq({ commit }, payload = {}) {
      // useLoading
      const useLoading = "useLoading" in payload ? payload.useLoading : true;

      // useLoading
      if (useLoading) {
        // startLoading
        commit("app/startLoading", null, { root: true });
      }

      // setUseAppToken
      commit("auth/setUseAppToken", true, { root: true });

      try {
        // getNotesRes
        const getNotesRes = await apiHelperFactory.getNotes(
          payload.resource,
          payload.resourceId
        );

        // setNotes
        commit("setNotes", getNotesRes.data);

        return getNotesRes;
      } catch (error) {
        if (error.response) {
          // handle response error
        } else if (error.request) {
          // handle request error
        } else {
          // handle other errors
        }
      } finally {
        // useLoading
        if (useLoading) {
          // stopLoading
          commit("app/stopLoading", null, { root: true });
        }

        // setUseAppToken
        commit("auth/setUseAppToken", false, { root: true });
      }
    },

    // getNoteReq
    async getNoteReq({ commit }, payload = {}) {
      // useLoading
      const useLoading = "useLoading" in payload ? payload.useLoading : true;

      // useLoading
      if (useLoading) {
        // startLoading
        commit("app/startLoading", null, { root: true });
      }

      // setUseAppToken
      commit("auth/setUseAppToken", true, { root: true });

      try {
        // getNoteRes
        const getNoteRes = await apiHelperFactory.getNote(
          payload.resource,
          payload.resourceId,
          payload.noteId
        );

        // setNote
        commit("setNote", getNoteRes.data);

        return getNoteRes;
      } catch (error) {
        if (error.response) {
          // handle response error
        } else if (error.request) {
          // handle request error
        } else {
          // handle other errors
        }
      } finally {
        // useLoading
        if (useLoading) {
          // stopLoading
          commit("app/stopLoading", null, { root: true });
        }

        // setUseAppToken
        commit("auth/setUseAppToken", false, { root: true });
      }
    },

    // createNoteReq
    async createNoteReq({ commit }, payload = {}) {
      // useLoading
      const useLoading = "useLoading" in payload ? payload.useLoading : true;

      // useLoading
      if (useLoading) {
        // startLoading
        commit("app/startLoading", null, { root: true });
      }

      // setUseAppToken
      commit("auth/setUseAppToken", true, { root: true });

      try {
        // createNoteReq
        const createNoteReq = await apiHelperFactory.createNote(
          payload.body,
          payload.resource,
          payload.resourceId
        );

        // status
        if (
          createNoteReq.status == 200 &&
          "data" in createNoteReq &&
          "resourceId" in createNoteReq.data
        ) {
          // setNoteId
          commit("setNoteId", createNoteReq.data.resourceId);
        }

        // setNote
        commit("setNote", createNoteReq.data);

        return createNoteReq;
      } catch (error) {
        // error.response
        if (error.response) {
          let res = error.response;

          if (res.status === 400) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 401) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 403) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 404) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 500) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else {
            // handleError
            helpers.handleError(res.status, res.data);
          }
        } else {
          // handleError
          helpers.handleError();
        }
      } finally {
        // useLoading
        if (useLoading) {
          // stopLoading
          commit("app/stopLoading", null, { root: true });
        }

        // stopLoading
        commit("app/stopLoading", null, { root: true });
      }
    },

    // updateNoteReq
    async updateNoteReq({ commit }, payload = {}) {
      // useLoading
      const useLoading = "useLoading" in payload ? payload.useLoading : true;

      // useLoading
      if (useLoading) {
        // startLoading
        commit("app/startLoading", null, { root: true });
      }

      // setUseAppToken
      commit("auth/setUseAppToken", true, { root: true });

      try {
        return await apiHelperFactory.updateNote(
          payload.body,
          payload.resource,
          payload.resourceId,
          payload.noteId
        );
      } catch (error) {
        // error.response
        if (error.response) {
          let res = error.response;

          if (res.status === 400) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 401) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 403) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 404) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 500) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else {
            // handleError
            helpers.handleError(res.status, res.data);
          }
        } else {
          // handleError
          helpers.handleError();
        }
      } finally {
        // useLoading
        if (useLoading) {
          // stopLoading
          commit("app/stopLoading", null, { root: true });
        }

        // stopLoading
        commit("app/stopLoading", null, { root: true });
      }
    },

    // deleteNoteReq
    async deleteNoteReq({ commit }, payload = {}) {
      // useLoading
      const useLoading = "useLoading" in payload ? payload.useLoading : true;

      // useLoading
      if (useLoading) {
        // startLoading
        commit("app/startLoading", null, { root: true });
      }

      // setUseAppToken
      commit("auth/setUseAppToken", true, { root: true });

      try {
        return await apiHelperFactory.deleteNote(
          payload.resource,
          payload.resourceId,
          payload.noteId
        );
      } catch (error) {
        // error.response
        if (error.response) {
          let res = error.response;

          if (res.status === 400) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 401) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 403) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 404) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 500) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else {
            // handleError
            helpers.handleError(res.status, res.data);
          }
        } else {
          // handleError
          helpers.handleError();
        }
      } finally {
        // useLoading
        if (useLoading) {
          // stopLoading
          commit("app/stopLoading", null, { root: true });
        }

        // stopLoading
        commit("app/stopLoading", null, { root: true });
      }
    },
  },

  getters: {
    // getNoteId
    getNoteId(state) {
      return state.noteId;
    },

    // getNote
    getNote(state) {
      return state.note;
    },

    // getNotes
    getNotes(state) {
      return state.notes;
    },
  },
};

export default noteApi;
