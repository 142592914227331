// plugins/rules.js

import Vue from "vue";
import helpers from "./helpers";

// rules
const rules = {
  // firstNameRules
  firstNameRules: [
    (v) => !!v || "",
    (v) =>
      /^[a-zA-Z][a-zA-Z0-9 \-']+$/.test(v) ||
      "Only english letters, apostrophes and hyphens are allowed",
    (v) => v.length >= 3 || "Can not be less than 3 characters",
    (v) => v.length <= 100 || "Can not be more than 100 characters",
  ],

  // lastNameRules
  lastNameRules: [
    (v) => !!v || "",
    (v) =>
      /^[a-zA-Z][a-zA-Z0-9 \-']+$/.test(v) ||
      "Only english letters, apostrophes and hyphens are allowed",
    (v) => v.length >= 3 || "Can not be less than 3 characters",
    (v) => v.length <= 100 || "Can not be more than 100 characters",
  ],

  // employmentIDStokvelIdentifierRules
  employmentIDStokvelIdentifierRules: [(v) => !!v || ""],

  // emailRules
  // emailRules: [
  //   (v) => !!v || "",
  //   (v) => v.length <= 50 || "Max 50 characters",
  //   (v) => {
  //     const pattern =
  //       /^(([^<>()[\]\\.,;:\s@"]+(\.[^<script>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  //     return pattern.test(v) || "Invalid e-mail.";
  //   },
  //   (v) => v.length <= 100 || "Can not be more than 100 characters",
  // ],

  emailRules: [
    (v) => !!v || "",
    (v) => v.length <= 50 || "Max 50 characters",
    (v) => {
      const pattern =
        // eslint-disable-next-line no-useless-escape
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@([a-zA-Z\-0-9]+(\.[a-zA-Z\-0-9]+)+)$/;
      return pattern.test(v) || "Invalid e-mail.";
    },
    (v) => v.length <= 100 || "Can not be more than 100 characters",
  ],

  // roleRules
  roleRules: [(v) => !!v || ""],

  // idNumberRules
  idNumberRules: [
    (v) => !!v || "",
    (v) => (v && v.length === 13) || "ID number must be 13 digits",
    (v) => (v && helpers.validateSAID(v)) || "Invalid SA ID number",
  ],

  // idNumberEmployerRules
  idNumberEmployerRules: [
    (v) => (v && v.length === 13) || "ID number must be 13 digits",
    (v) => (v && helpers.validateSAID(v)) || "Invalid SA ID number",
  ],

  // phoneRules: [
  //   (v) => !!v || "Phone number is required",
  //   (v) =>
  //     (v &&
  //       /^(\+\d{1,2}\s?)?(\(\d{3}\)|\d{3})[- .]?\d{3}[- .]?\d{4}$/.test(v)) ||
  //     "Phone number must be valid",
  //   (v) => (v && /^\d{10}$/.test(v)) || "Phone number must have 10 digits", // Ensure 10 digits
  // ],

  phoneRules: [
    (v) => !!v || "Phone number is required",
    (v) =>
      (v &&
        /^(\+\d{1,2}\s?)?(\(\d{3}\)|\d{3})[- .]?\d{3}[- .]?\d{1,7}$/.test(v)) ||
      "Phone number must be valid",
    (v) => /^\+?\d{8,11}$/.test(v) || "Phone number must be valid",
  ],

  // passwordRules
  passwordRules: [
    (v) => !!v || "",
    (v) => v.length >= 8 || "8 to 30 characters",
    (v) => v.length <= 30 || "8 to 30 characters",
    (v) =>
      /^(?=.*?[A-Z])/.test(v) ||
      "Сontains at least one uppercase english letters",
    (v) =>
      /^(?=.*?[a-z])/.test(v) ||
      "Сontains at least one lowercase english letters",
    (v) => /^(?=.*?[0-9])/.test(v) || "Сontains at least one number",
  ],

  // bankAccountTypeRules
  bankAccountTypeRules: [(v) => !!v || ""],

  // bankRules
  bankRules: [(v) => !!v || ""],

  // cardNumberRules
  cardNumberRules: [
    (v) => !!v || "",
    (v) =>
      (v && /^\d{4} \d{4} \d{4} \d{4}$/.test(v)) ||
      "Must be in 'XXXX XXXX XXXX XXXX' format",
  ],

  // expiryMonthAndYearRules
  expiryMonthAndYearRules: [
    (v) => !!v || "",
    (v) => (v && /^\d{2}\/\d{2}$/.test(v)) || "Must be in MM/YY format",
  ],

  // cvcRules
  cvcRules: [
    (v) => !!v || "",
    (v) => (v && v.length == 3) || "Must be 3 digits",
    (v) => (v && /^\d+$/.test(v)) || "Must only contain digits",
  ],

  // mobileMoneyServiceProviderRules
  mobileMoneyServiceProviderRules: [(v) => !!v || ""],

  // referenceNameRules
  referenceNameRules: [
    (v) => !!v || "",
    (v) =>
      /^[a-zA-Z][a-zA-Z0-9 \-']+$/.test(v) ||
      "Only english letters, apostrophes and hyphens are allowed",
    (v) => v.length >= 3 || "Can not be less than 3 characters",
    (v) => v.length <= 100 || "Can not be more than 100 characters",
  ],

  // stopOrderDateRules
  stopOrderDateRules: [(v) => !!v || ""],

  // nameOfEmployerRules
  nameOfEmployerRules: [
    (v) => !!v || "",
    (v) =>
      /^[a-zA-Z][a-zA-Z0-9 \-']+$/.test(v) ||
      "Only english letters, apostrophes and hyphens are allowed",
    (v) => v.length >= 3 || "Can not be less than 3 characters",
    (v) => v.length <= 100 || "Can not be more than 100 characters",
  ],

  // employerContactNumberRules
  employerContactNumberRules: [(v) => !!v || ""],

  // employeeNameAndSurnameRules
  employeeNameAndSurnameRules: [
    (v) => !!v || "",
    (v) =>
      /^[a-zA-Z][a-zA-Z0-9 \-']+$/.test(v) ||
      "Only english letters, apostrophes and hyphens are allowed",
    (v) => v.length >= 3 || "Can not be less than 3 characters",
    (v) => v.length <= 100 || "Can not be more than 100 characters",
  ],

  // accountHolderNameRules
  accountHolderNameRules: [
    (v) => !!v || "",
    (v) =>
      /^[a-zA-Z][a-zA-Z0-9 \-']+$/.test(v) ||
      "Only english letters, apostrophes and hyphens are allowed",
    (v) => v.length >= 3 || "Can not be less than 3 characters",
    (v) => v.length <= 100 || "Can not be more than 100 characters",
  ],

  // amountRules
  amountRules: [(v) => !!v || "", (v) => v > 0 || "Must be greater than 0"],

  // transferDescriptionRules
  transferDescriptionRules: [(v) => !!v || ""],
};

// Assign rules to Vue's prototype
Vue.prototype.$rules = rules;
