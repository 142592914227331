const savings = {
  namespaced: true,

  state: {
    // showTransferDialog
    showTransferDialog: false,

    // showTransferMobileForm
    showTransferMobileForm: false,

    // transferDialogStep
    transferDialogStep: 1,

    // showinvestDialog
    showInvestDialog: false,

    // showinvestMobileForm
    showInvestMobileForm: false,

    // investDialogStep
    investDialogStep: 1,
  },

  mutations: {
    // setShowTransferDialog
    setShowTransferDialog(state, payload) {
      state.showTransferDialog = payload;
    },

    // setShowTransferMobileForm
    setShowTransferMobileForm(state, payload) {
      state.showTransferMobileForm = payload;
    },

    // setTransferDialogStep
    setTransferDialogStep(state, payload) {
      state.transferDialogStep = payload;
    },

    // setShowinvestDialog
    setShowInvestDialog(state, payload) {
      state.showInvestDialog = payload;
    },

    // setShowinvestMobileForm
    setShowInvestMobileForm(state, payload) {
      state.showInvestMobileForm = payload;
    },

    // setinvestDialogStep
    setInvestDialogStep(state, payload) {
      state.investDialogStep = payload;
    },

    resetState(state) {
      // showTransferDialog
      state.showTransferDialog = false;

      // showTransferMobileForm
      state.showTransferMobileForm = false;

      // transferDialogStep
      state.transferDialogStep = 1;

      // showinvestDialog
      state.showInvestDialog = false;

      // showinvestMobileForm
      state.showInvestMobileForm = false;

      // investDialogStep
      state.investDialogStep = 1;
    },
  },

  actions: {},

  getters: {
    // getShowTransferDialog
    getShowTransferDialog(state) {
      return state.showTransferDialog;
    },

    // getShowTransferMobileForm
    getShowTransferMobileForm(state) {
      return state.showTransferMobileForm;
    },

    // getTransferDialogStep
    getTransferDialogStep(state) {
      return state.transferDialogStep;
    },

    // getShowinvestDialog
    getShowInvestDialog(state) {
      return state.showInvestDialog;
    },

    // getShowinvestMobileForm
    getShowInvestMobileForm(state) {
      return state.showInvestMobileForm;
    },

    // getinvestDialogStep
    getInvestDialogStep(state) {
      return state.investDialogStep;
    },
  },
};

export default savings;
