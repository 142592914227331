const getAQuote = {
  namespaced: true,

  state: {
    // showGetAQuoteDialog
    showGetAQuoteDialog: false,

    // getAQuoteDialogStep
    getAQuoteDialogStep: 1,
  },

  mutations: {
    // setShowGetAQuoteDialog
    setShowGetAQuoteDialog(state, payload) {
      state.showGetAQuoteDialog = payload;
    },

    // setGetAQuoteDialogStep
    setGetAQuoteDialogStep(state, payload) {
      state.getAQuoteDialogStep = payload;
    },

    // resetState
    resetState(state) {
      // showGetAQuoteDialog
      state.showGetAQuoteDialog = false;

      // getAQuoteDialogStep
      state.getAQuoteDialogStep = 1;
    },
  },

  actions: {},

  getters: {
    // getShowGetAQuoteDialog
    getShowGetAQuoteDialog(state) {
      return state.showGetAQuoteDialog;
    },

    // getGetAQuoteDialogStep
    getGetAQuoteDialogStep(state) {
      return state.getAQuoteDialogStep;
    },
  },
};

export default getAQuote;
