const message = {
  namespaced: true,

  state: {
    // resolve
    showResolveDialog: false,
    showResolveMobileDialog: false,
    resolveDialogStep: 1,
    showComposeDialog: false,
    showComposeMobileDialog: false,
    ComposeDialogStep: 1,
  },

  mutations: {
    // resolve
    setShowResolveDialog(state, payload) {
      state.showResolveDialog = payload;
    },
    setShowResolveMobileDialog(state, payload) {
      state.showResolveMobileDialog = payload;
    },
    setResolveDialogStep(state, payload) {
      state.resolveDialogStep = payload;
    },

    setShowComposeDialog(state, payload) {
      state.showComposeDialog = payload;
    },
    setShowComposeMobileDialog(state, payload) {
      state.showComposeMobileDialog = payload;
    },
    setComposeDialogStep(state, payload) {
      state.ComposeDialogStep = payload;
    },

    resetState(state) {
      // resolve
      state.showResolveDialog = false;
      state.showResolveMobileDialog = false;
      state.resolveDialogStep = 1;
      state.showComposeDialog = false;
      state.showComposeMobileDialog = false;
      state.ComposeDialogStep = 1;
    },
  },
  actions: {
    // Asynchronous functions that commit mutations to update the state
  },

  getters: {
    // resolve
    getShowResolveDialog(state) {
      return state.showResolveDialog;
    },
    getShowResolveMobileDialog(state) {
      return state.showResolveMobileDialog;
    },
    getResolveDialogStep(state) {
      return state.resolveDialogStep;
    },
    // resolve
    getShowComposeDialog(state) {
      return state.showComposeDialog;
    },
    getShowComposeMobileDialog(state) {
      return state.showComposeMobileDialog;
    },
    getComposeDialogStep(state) {
      return state.ComposeDialogStep;
    },
  },
};

export default message;
