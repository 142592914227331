import apiHelperFactory from "@/http/apiHelperFactory";
import helpers from "@/plugins/helpers";

const notificationApi = {
  namespaced: true,

  state: {
    // notifications
    notifications: [],
  },

  mutations: {
    // setNotifications
    setNotifications(state, payload) {
      state.notifications = payload;
    },

    // setEmailNotifications
    setEmailNotifications(state, payload) {
      state.emailNotifications = payload;
    },

    // setSMSNotifications
    setSMSNotifications(state, payload) {
      state.smsNotifications = payload;
    },

    // setLoanNotifications
    setLoanNotifications(state, payload) {
      state.loanNotifications = payload;
    },

    // setSavingsNotifications
    setSavingsNotifications(state, payload) {
      state.savingsNotifications = payload;
    },

    // resetState
    resetState(state) {
      // notifications
      state.notifications = [];
    },
  },

  actions: {
    // getNotificationsReq
    async getNotificationsReq({ commit }, payload = {}) {
      // useLoading
      const useLoading = "useLoading" in payload ? payload.useLoading : true;

      // useLoading
      if (useLoading) {
        // startLoading
        commit("app/startLoading", null, { root: true });
      }

      // setUseAppToken
      commit("auth/setUseAppToken", true, { root: true });

      try {
        // getNotificationsRes
        const getNotificationsRes = await apiHelperFactory.getNotifications(
          payload.clientId
        );

        // setNotifications
        commit("setNotifications", getNotificationsRes.data);

        return getNotificationsRes;
      } catch (error) {
        if (error.response) {
          // handle response error
        } else if (error.request) {
          // handle request error
        } else {
          // handle other errors
        }
      } finally {
        // useLoading
        if (useLoading) {
          // stopLoading
          commit("app/stopLoading", null, { root: true });
        }

        // setUseAppToken
        commit("auth/setUseAppToken", false, { root: true });
      }
    },

    // createNotificationReq
    async createNotificationReq({ commit }, { payload, clientId }) {
      // startLoading
      commit("app/startLoading", null, { root: true });

      // setUseAppToken
      commit("auth/setUseAppToken", true, { root: true });

      try {
        return await apiHelperFactory.createNotification(payload, clientId);
      } catch (error) {
        // error.response
        if (error.response) {
          let res = error.response;

          if (res.status === 400) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 401) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 403) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 404) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 500) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else {
            // handleError
            helpers.handleError(res.status, res.data);
          }
        } else {
          // handleError
          helpers.handleError();
        }
      } finally {
        // setUseAppToken
        commit("auth/setUseAppToken", false, { root: true });

        // stopLoading
        commit("app/stopLoading", null, { root: true });
      }
    },

    // updateNotificationReq
    async updateNotificationReq({ commit }, { payload, clientId }) {
      // startLoading
      commit("app/startLoading", null, { root: true });

      // setUseAppToken
      commit("auth/setUseAppToken", true, { root: true });

      try {
        return await apiHelperFactory.updateNotification(payload, clientId);
      } catch (error) {
        // error.response
        if (error.response) {
          let res = error.response;

          if (res.status === 400) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 401) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 403) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 404) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 500) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else {
            // handleError
            helpers.handleError(res.status, res.data);
          }
        } else {
          // handleError
          helpers.handleError();
        }
      } finally {
        // setUseAppToken
        commit("auth/setUseAppToken", false, { root: true });

        // stopLoading
        commit("app/stopLoading", null, { root: true });
      }
    },
  },

  getters: {
    // getNotifications
    getNotifications(state) {
      return state.notifications;
    },
  },
};

export default notificationApi;
