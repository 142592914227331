const loanDetails = {
  namespaced: true,

  state: {
    // showLoanDetailsDialog
    showLoanDetailsDialog: false,

    // showSettleUpNowDialog
    showSettleUpNowDialog: false,

    // settleUpNowDialogStep
    settleUpNowDialogStep: 1,

    // showEarlyPaymentOptionsDialog
    showEarlyPaymentOptionsDialog: false,

    // earlyPaymentOptionsDialogStep
    earlyPaymentOptionsDialogStep: 1,

    // earlyPaymentOptionsAmount
    earlyPaymentOptionsAmount: "",

    // currentStatus
    currentStatus: {
      status: "Pending Approval",
      title: "Loan Application Pending Approval",
      message:
        "Your application has been processed and is currently waiting for approval.",
    },

    // statuses
    statuses: [
      {
        status: "Being Processed",
        title: "Loan Application being processed",
        message:
          "An email confirmation with the terms and conditions of the loan quotation has been sent to your email address.",
      },
      {
        status: "Pending Approval",
        title: "Loan Application Pending Approval",
        message:
          "Your application has been processed and is currently waiting for approval.",
      },
      {
        status: "Approved",
        title: "Loan Application approved",
        message:
          "An email confirmation with the terms and conditions of the loan quotation has been sent to your email address.",
      },
      {
        status: "Active",
        title: "Loan disbursed",
        message:
          "The loan amount you were approved for has been deposited into your chosen account please wait 24-48 hours for the money to reflect.",
      },
      {
        status: "Rejected",
        title: "Loan application declined",
        message:
          "We are sorry to say that you do not qualify for this loan. Please try again another time.",
      },
      {
        status: "Withdrawn by applicant",
        title: "Loan application withdrew",
        message: "We are sorry, but you withdrew your loan application",
      },
      {
        status: "Closed (obligations met)",
        title: "Loan application closed",
        message: "Your loan application has been successfully closed",
      },
    ],
  },

  mutations: {
    // setShowLoanDetailsDialog
    setShowLoanDetailsDialog(state, payload) {
      state.showLoanDetailsDialog = payload;
    },

    // setShowSettleUpNowDialog
    setShowSettleUpNowDialog(state, payload) {
      state.showSettleUpNowDialog = payload;
    },

    // setSettleUpNowDialogStep
    setSettleUpNowDialogStep(state, payload) {
      state.settleUpNowDialogStep = payload;
    },

    // setShowEarlyPaymentOptionsDialog
    setShowEarlyPaymentOptionsDialog(state, payload) {
      state.showEarlyPaymentOptionsDialog = payload;
    },

    // setEarlyPaymentOptionsDialogStep
    setEarlyPaymentOptionsDialogStep(state, payload) {
      state.earlyPaymentOptionsDialogStep = payload;
    },

    // setEarlyPaymentOptionsAmount
    setEarlyPaymentOptionsAmount(state, payload) {
      state.earlyPaymentOptionsAmount = payload;
    },

    // setCurrentStatus
    setCurrentStatus(state, payload) {
      state.currentStatus = payload;
    },

    // resetState
    resetState(state) {
      // showLoanDetailsDialog
      state.showLoanDetailsDialog = false;

      // showSettleUpNowDialog
      state.showSettleUpNowDialog = false;

      // settleUpNowDialogStep
      state.settleUpNowDialogStep = 1;

      // showEarlyPaymentOptionsDialog
      state.showEarlyPaymentOptionsDialog = false;

      // earlyPaymentOptionsDialogStep
      state.earlyPaymentOptionsDialogStep = 1;

      // earlyPaymentOptionsAmount
      state.earlyPaymentOptionsAmount = "";

      // currentStatus
      state.currentStatus = {
        status: "Being Processed",
        title: "Loan Application being processed",
        message:
          "An email confirmation with the terms and conditions of the loan quotation has been sent to your email address.",
      };

      // statuses
      // state.statusess = [
      //   {
      //     status: "Being Processed",
      //     title: "Loan Application being processed",
      //     message:
      //       "An email confirmation with the terms and conditions of the loan quotation has been sent to your email address.",
      //   },
      //   {
      //     status: "Pending Approval",
      //     title: "Loan Application Pending Approval",
      //     message:
      //       "An email confirmation with the terms and conditions of the loan quotation has been sent to your email address.",
      //   },
      //   {
      //     status: "Approved",
      //     title: "Loan Application approved",
      //     message:
      //       "An email confirmation with the terms and conditions of the loan quotation has been sent to your email address.",
      //   },
      // ];
      state.statuses = [
        {
          status: "Being Processed",
          title: "Loan Application being processed",
          message:
            "An email confirmation with the terms and conditions of the loan quotation has been sent to your email address.",
        },
        {
          status: "Pending Approval",
          title: "Loan Application Pending Approval",
          message:
            "Your application has been processed and is currently waiting for approval.",
        },
        {
          status: "Approved",
          title: "Loan Application approved",
          message:
            "An email confirmation with the terms and conditions of the loan quotation has been sent to your email address.",
        },
        {
          status: "Active",
          title: "Loan disbursed",
          message:
            "The loan amount you were approved for has been deposited into your chosen account please wait 24-48 hours for the money to reflect.",
        },
        {
          status: "Rejected",
          title: "Loan application declined",
          message:
            "We are sorry to say that you do not qualify for this loan. Please try again another time.",
        },
        {
          status: "Withdrawn by applicant",
          title: "Loan application withdrew",
          message: "We are sorry, but you withdrew your loan application ",
        },
        {
          status: "Closed (obligations met)",
          title: "Loan application closed",
          message: "Your loan application has been successfully closed",
        },
      ];
    },
  },

  actions: {},

  getters: {
    // getShowLoanDetailsDialog
    getShowLoanDetailsDialog(state) {
      return state.showLoanDetailsDialog;
    },

    // getShowSettleUpNowDialog
    getShowSettleUpNowDialog(state) {
      return state.showSettleUpNowDialog;
    },

    // getSettleUpNowDialogStep
    getSettleUpNowDialogStep(state) {
      return state.settleUpNowDialogStep;
    },

    // getShowEarlyPaymentOptionsDialog
    getShowEarlyPaymentOptionsDialog(state) {
      return state.showEarlyPaymentOptionsDialog;
    },

    // getEarlyPaymentOptionsDialogStep
    getEarlyPaymentOptionsDialogStep(state) {
      return state.earlyPaymentOptionsDialogStep;
    },

    // getEarlyPaymentOptionsAmount
    getEarlyPaymentOptionsAmount(state) {
      return state.earlyPaymentOptionsAmount;
    },

    // getCurrentStatus
    getCurrentStatus(state) {
      return state.currentStatus;
    },
  },
};

export default loanDetails;
