const mobileSidebar = {
  namespaced: true,

  state: {
    // showMobileSidebar
    showMobileSidebar: false,
  },

  mutations: {
    // setShowMobileSidebar
    setShowMobileSidebar(state, payload) {
      state.showMobileSidebar = payload;
    },

    // resetState
    resetState(state) {
      // showMobileSidebar
      state.showMobileSidebar = false;
    },
  },

  actions: {},

  getters: {
    // getShowMobileSidebar
    getShowMobileSidebar(state) {
      return state.showMobileSidebar;
    },
  },
};

export default mobileSidebar;
