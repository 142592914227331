import store from "@/store";
import moment from "moment";

export default function checkSession() {
  setInterval(() => {
    const now = moment();
    const diffMinutes = now.diff(store.state.auth.lastAction, "minutes");

    if (
      diffMinutes >= store.state.auth.sessionDuration &&
      store.state.auth.authenticated
    ) {
      store.dispatch("auth/logOutAuto");
    }
  }, 1000); // check every second
}
