const memberSignUp = {
  namespaced: true,

  state: {
    step: "step-1",
    showMobileForm: false,
    showChooseMethod: true,
    method: "sign-up-with-google",

    // firstName
    firstName: "",

    // lastName
    lastName: "",

    // employmentIDStokvelIdentifier
    employmentIDStokvelIdentifier: "",

    // email
    email: "",

    // stokvel
    stokvel: "",

    // username
    username: "",

    // phone
    phone: "",

    // password
    password: "",

    // gender
    gender: "",

    // age
    age: "",
  },

  mutations: {
    // setStep
    setStep(state, payload) {
      state.step = payload;
    },

    // setShowMobileForm
    setShowMobileForm(state, payload) {
      state.showMobileForm = payload;
    },

    // setShowChooseMethod
    setShowChooseMethod(state, payload) {
      state.showChooseMethod = payload;
    },

    // setMethod
    setMethod(state, payload) {
      state.method = payload;
    },

    // setFirstName
    setFirstName(state, payload) {
      state.firstName = payload;
    },

    // setLastName
    setLastName(state, payload) {
      state.lastName = payload;
    },

    // setEmploymentIDStokvelIdentifier
    setEmploymentIDStokvelIdentifier(state, payload) {
      state.employmentIDStokvelIdentifier = payload;
    },

    // setEmail
    setEmail(state, payload) {
      state.email = payload;
    },

    // setStokvel
    setStokvel(state, payload) {
      state.stokvel = payload;
    },

    // setUsername
    setUsername(state, payload) {
      state.username = payload;
    },

    // setPhone
    setPhone(state, payload) {
      state.phone = payload;
    },

    // setPassword
    setPassword(state, payload) {
      state.password = payload;
    },

    // setGender
    setGender(state, payload) {
      state.gender = payload;
    },

    // setAge
    setAge(state, payload) {
      state.age = payload;
    },

    // resetState
    resetState(state) {
      state.step = "step-1";
      state.showMobileForm = false;
      state.showChooseMethod = false;
      state.method = "sign-up-with-google";

      // firstName
      state.firstName = "";

      // lastName
      state.lastName = "";

      // employmentIDStokvelIdentifier
      state.employmentIDStokvelIdentifier = "";

      // email
      state.email = "";

      // stokvel
      state.stokvel = "";

      // username
      state.username = "";

      // phone
      state.phone = "";

      // password
      state.password = "";

      // gender
      state.gender = "";

      // age
      state.age = "";
    },
  },

  actions: {},

  getters: {
    // getStep
    getStep(state) {
      return state.step;
    },

    // getShowMobileForm
    getShowMobileForm(state) {
      return state.showMobileForm;
    },

    // getShowChooseMethod
    getShowMChooseMethod(state) {
      return state.showChooseMethod;
    },

    // getMethod
    getMethod(state) {
      return state.method;
    },

    // getFirstName
    getFirstName(state) {
      return state.firstName;
    },

    // getLastName
    getLastName(state) {
      return state.lastName;
    },

    // getEmploymentIDStokvelIdentifier
    getEmploymentIDStokvelIdentifier(state) {
      return state.employmentIDStokvelIdentifier;
    },

    // getEmail
    getEmail(state) {
      return state.email;
    },

    // getStokvel
    getStokvel(state) {
      return state.stokvel;
    },

    // getUsername
    getUsername(state) {
      return state.username;
    },

    // getPhone
    getPhone(state) {
      return state.phone;
    },

    // getPassword
    getPassword(state) {
      return state.phone;
    },

    // getGender
    getGender(state) {
      return state.gender;
    },

    // getAge
    getAge(state) {
      return state.age;
    },
  },
};

export default memberSignUp;
