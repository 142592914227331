import apiHelperFactoryForBank from "@/http/apiHelperFactoryForBank";
import helpers from "@/plugins/helpers";

const stokvelApiBank = {
  namespaced: true,

  state: {
    // stokvels
    stokvels: [],
    docId: null,
    // selectedValue
    selectedValue: null,
    tempStokvel: {},
    token: null,
    otp: null,
    phone: "",

    // stokvelDetails: [],
    stokvelApplicationDocuments: [],
  },

  mutations: {
    // setStokvels
    setStokvels(state, payload) {
      state.stokvels = payload;
    },
    setOtp(state, payload) {
      state.otp = payload;
    },
    setPhone(state, payload) {
      state.phone = payload;
    },
    // setStokvels
    setDocId(state, payload) {
      state.docId = payload;
    },

    setToken(state, payload) {
      state.token = payload;
    },

    setTempStokvel(state, payload) {
      state.tempStokvel = payload;
    },

    // setSelectedValue
    setSelectedValue(state, payload) {
      state.selectedValue = payload;
    },

    // setStokvelApplicationDocuments
    setStokvelApplicationDocuments(state, payload) {
      state.stokvelApplicationDocuments = payload;
    },

    resetToken(state) {
      state.token = null;
    },

    // resetState
    resetState(state) {
      // stokvels
      state.stokvels = [];

      // stokvelApplicationDocuments
      state.stokvelApplicationDocuments = [];
    },
  },

  actions: {
    // getStokvelsReq
    async getStokvelsReq({ commit }, payload = {}) {
      // useLoading
      const useLoading = "useLoading" in payload ? payload.useLoading : true;

      // useLoading
      if (useLoading) {
        // startLoading
        commit("app/startLoading", null, { root: true });
      }

      // setUseAppToken
      commit("auth/setUseAppToken", true, { root: true });

      try {
        // getStokvelsRes
        const getStokvelsRes = await apiHelperFactoryForBank.getStokvels(
          payload
        );

        // setStokvels
        commit("setStokvels", getStokvelsRes.data);

        return getStokvelsRes;
      } catch (error) {
        if (error.response) {
          // handle response error
        } else if (error.request) {
          // handle request error
        } else {
          // handle other errors
        }
      } finally {
        // useLoading
        if (useLoading) {
          // stopLoading
          commit("app/stopLoading", null, { root: true });
        }

        // setUseAppToken
        commit("auth/setUseAppToken", false, { root: true });
      }
    },

    // comleteStokvelReq
    async completeStokvelReq({ commit }, { payload }) {
      // useLoading
      const useLoading = "useLoading" in payload ? payload.useLoading : true;

      // useLoading
      if (useLoading) {
        // startLoading
        commit("app/startLoading", null, { root: true });
      }

      // setUseAppToken
      commit("auth/setUseAppToken", true, { root: true });

      try {
        // comleteStokvel
        const comleteStokvelRes = await apiHelperFactoryForBank.comleteStokvel(
          payload
        );

        // forgotPasswordRes
        return comleteStokvelRes;
      } catch (error) {
        // error.response
        if (error.response) {
          let res = error.response;

          if (res.status === 400) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 401) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 403) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 404) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 500) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else {
            // handleError
            helpers.handleError(res.status, res.data);
          }
        } else {
          // handleError
          helpers.handleError();
        }
      } finally {
        // useLoading
        if (useLoading) {
          // stopLoading
          commit("app/stopLoading", null, { root: true });
        }

        // setUseAppToken
        commit("auth/setUseAppToken", false, { root: true });
      }
    },

    //deleteStokvel
    async deleteStokvelReq({ commit }, { stokvelId }) {
      // useLoading
      const useLoading = true; // Assuming you always want loading indicators for delete operations

      // useLoading
      if (useLoading) {
        // startLoading
        commit("app/startLoading", null, { root: true });
      }

      // setUseAppToken
      commit("auth/setUseAppToken", true, { root: true });

      try {
        await apiHelperFactoryForBank.deleteStokvels(stokvelId); // Replace with your delete API call
        // Optionally, you can commit an action or mutation to handle success.
        // commit("stokvel/deleteSuccess", stokvelId, { root: true });
      } catch (error) {
        // error.response
        if (error.response) {
          let res = error.response;

          if (res.status === 400) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 401) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 403) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 404) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 500) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else {
            // handleError
            helpers.handleError(res.status, res.data);
          }
        } else {
          // handleError
          helpers.handleError();
        }
      } finally {
        // useLoading
        if (useLoading) {
          // stopLoading
          commit("app/stopLoading", null, { root: true });
        }

        // setUseAppToken
        commit("auth/setUseAppToken", false, { root: true });
      }
    },

    // getStokvelApplicationDocumentsReq
    async getStokvelApplicationDocumentsReq(
      // eslint-disable-next-line no-unused-vars
      { commit },
      payload = {}
    ) {
      // useLoading
      const useLoading = "useLoading" in payload ? payload.useLoading : true;

      // useLoading
      if (useLoading) {
        // startLoading
        commit("app/startLoading", null, { root: true });
        console.log("here2::::");
      }

      // setUseAppToken
      commit("auth/setUseAppToken", true, { root: true });

      try {
        // getStokvelApplicationDocumentsRes
        const getStokvelApplicationDocumentsRes =
          await apiHelperFactoryForBank.stokvelApplicationDocuments();

        // const filteredDocuments = getStokvelApplicationDocumentsRes.data[1].stokvel.id;
        // setStokvelApplicationDocuments
        commit(
          "setStokvelApplicationDocuments",
          getStokvelApplicationDocumentsRes.data
        );

        return getStokvelApplicationDocumentsRes;
      } catch (error) {
        if (error.response) {
          // handle response error
        } else if (error.request) {
          // handle request error
        } else {
          // handle other errors
        }
      } finally {
        // useLoading
        if (useLoading) {
          // stopLoading
          commit("app/stopLoading", null, { root: true });
        }

        // setUseAppToken
        commit("auth/setUseAppToken", false, { root: true });
      }
    },
    // getUserDetails
    // eslint-disable-next-line no-unused-vars
    async getUserDocuments({ commit, state, rootState }) {
      // startLoading
      commit("app/startLoading", null, { root: true });

      try {
        const docId = state.docId;
        // retrieveAUserRes
        const retrieveAUserRes =
          await apiHelperFactoryForBank.stokvelApplicationDocumentsById(docId);

        // setUserDetails
        commit("setStokvelApplicationDocuments", retrieveAUserRes.data);

        return retrieveAUserRes;
      } catch (error) {
        if (error.response) {
          // handle response error
        } else if (error.request) {
          // handle request error
        } else {
          // handle other errors
        }
      } finally {
        // stopLoading
        commit("app/stopLoading", null, { root: true });
      }
    },

    // getUserDetails
    // eslint-disable-next-line no-unused-vars
    async getStokvelByToken({ commit, state, rootState }) {
      // startLoading
      commit("app/startLoading", null, { root: true });

      try {
        const docId = state.token;
        console.log(">>>>>>>>>>>>>>>>>>>>:::::", docId);
        // retrieveAUserRes
        const retrieveStokvelRes = await apiHelperFactoryForBank.stokvelByToken(
          docId
        );
        console.log(">>>>>>>>>>>>>>>>>>>>:::::", retrieveStokvelRes);
        // setUserDetails
        commit("setTempStokvel", retrieveStokvelRes.data);

        return retrieveStokvelRes;
      } catch (error) {
        if (error.response) {
          // handle response error
        } else if (error.request) {
          // handle request error
        } else {
          // handle other errors
        }
      } finally {
        // stopLoading
        commit("app/stopLoading", null, { root: true });
      }
    },

    // updateStokvelReq
    async updateStokvelReq({ commit }, { payload, stokvelId }) {
      // useLoading
      const useLoading = "useLoading" in payload ? payload.useLoading : true;

      // useLoading
      if (useLoading) {
        // startLoading
        commit("app/startLoading", null, { root: true });
      }

      // setUseAppToken
      commit("auth/setUseAppToken", true, { root: true });

      try {
        return await apiHelperFactoryForBank.updateStokvels(payload, stokvelId);
      } catch (error) {
        // error.response
        if (error.response) {
          let res = error.response;

          if (res.status === 400) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 401) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 403) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 404) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 500) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else {
            // handleError
            helpers.handleError(res.status, res.data);
          }
        } else {
          // handleError
          helpers.handleError();
        }
      } finally {
        // useLoading
        if (useLoading) {
          // stopLoading
          commit("app/stopLoading", null, { root: true });
        }

        // setUseAppToken
        commit("auth/setUseAppToken", false, { root: true });
      }
    },
  },

  getters: {
    // getStokvel
    getStokvel(state) {
      return state.stokvels;
    },
    // getStokvel
    getOpt(state) {
      return state.otp;
    },
    getPhone(state) {
      return state.phone;
    },
    // getStokvel
    geTempStokvel(state) {
      return state.tempStokvel;
    },

    // getSelectedValue
    getSelectedValue(state) {
      return state.selectedValue;
    },

    // getMappedStokvel
    getMappedStokvel(state) {
      // asset_manager
      return state.stokvels.map((stokvel) => {
        return {
          id: stokvel.id,
          stokveName: stokvel.name,
          totalAmt: stokvel.totalAmt,
          stokvelID: stokvel.id,
          typeOfStokvel: stokvel.type,
          members: stokvel.numberOfMembers,
          accountType: stokvel.accountType,
          status: stokvel.status,
          colour: stokvel.colour,
          opened: false,
          selected: false,
        };
      });
    },

    // getStokvelById
    getStokvelById: (state) => (id) => {
      // charge
      const stokvel = state.stokvels.find((stokvel) => stokvel.id === id);

      return stokvel ? stokvel : {};
    },

    // getMappedStokvel
    getFilterStokvel(state) {
      // asset_manager
      const groupTrustStokvels = state.stokvels.filter(
        (stokvel) => stokvel.status === "Pending"
      );
      return groupTrustStokvels.map((stokvel) => {
        return {
          id: stokvel.id,
          stokveName: stokvel.name,
          totalAmt: stokvel.totalAmt,
          stokvelID: stokvel.id,
          typeOfStokvel: stokvel.type,
          members: stokvel.numberOfMembers,
          accountType: stokvel.accountType,
          status: stokvel.status,
          colour: stokvel.colour,
          document: stokvel.document,
          opened: false,
          selected: false,
        };
      });
    },

    // getStokvelApplicationDocuments
    getStokvelApplicationDocuments(state) {
      return state.stokvelApplicationDocuments;
    },

    // getStokvelDocumentsById
    getStokvelDocumentsById: (state) => (id) => {
      const stokvelDocuments = state.stokvelApplicationDocuments.filter(
        (stokvelDocument) => stokvelDocument.stokvelId === id
      );
      return stokvelDocuments ? stokvelDocuments : {};
    },

    // getStokvelByTenantIdentifier
    getStokvelByTenantIdentifier: (state) => (tenantIdentifier) => {
      // charge
      const stokvel = state.stokvels.find(
        (stokvel) => stokvel.tenantIdentifier === tenantIdentifier
      );

      return stokvel ? stokvel : {};
    },
  },
};
export default stokvelApiBank;
