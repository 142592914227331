const globalAdminTransaction = {
  namespaced: true,

  state: {
    // category dialog
    showCategoryDialog: false,
    showCategoryMobileDialog: false,
    categoryDialogStep: 1,

    // stokvelId dialog
    showStokvelIdDialog: false,
    showStokvelIdMobileDialog: false,
    stokvelIdDialogStep: 1,

    // UmbrellaTrust dialog
    showUmbrellaTrustDialog: false,
    showUmbrellaTrustMobileDialog: false,
    umbrellaTrustDialogStep: 1,

    // Investment dialog
    showInvestmentDialog: false,
    showInvestmentMobileDialog: false,
    investmentDialogStep: 1,

    // umbrella side desktop bar
    showUmbrellaTrustSidebar: false,

    // StandAlone desktop bar
    showStandAloneSidebar: false,
  },

  mutations: {
    // category dialog
    setShowCategoryDialog(state, payload) {
      state.showCategoryDialog = payload;
    },
    setShowCategoryMobileDialog(state, payload) {
      state.showCategoryMobileDialog = payload;
    },
    setCategoryDialogStep(state, payload) {
      state.categoryDialogStep = payload;
    },

    // StokvelId dialog
    setShowStokvelIdDialog(state, payload) {
      state.showStokvelIdDialog = payload;
    },
    setShowStokvelIdMobileDialog(state, payload) {
      state.showStokvelIdMobileDialog = payload;
    },
    setStokvelIdDialogStep(state, payload) {
      state.stokvelIdDialogStep = payload;
    },

    // UmbrellaTrust dialog
    setShowUmbrellaTrustDialog(state, payload) {
      state.showUmbrellaTrustDialog = payload;
    },
    setShowUmbrellaTrustMobileDialog(state, payload) {
      state.showUmbrellaTrustMobileDialog = payload;
    },
    setUmbrellaTrustDialogStep(state, payload) {
      state.umbrellaTrustDialogStep = payload;
    },

    // Investment dialog
    setShowInvestmentDialog(state, payload) {
      state.showInvestmentDialog = payload;
    },
    setShowInvestmentMobileDialog(state, payload) {
      state.showInvestmentMobileDialog = payload;
    },
    setInvestmentDialogStep(state, payload) {
      state.investmentDialogStep = payload;
    },

    // umbrella side desktop bar
    setShowUmbrellaTrustSidebar(state, payload) {
      state.showUmbrellaTrustSidebar = payload;
    },

    // umbrella side desktop bar
    setShowStandAloneSidebar(state, payload) {
      state.showStandAloneSidebar = payload;
    },

    // resetState
    resetState(state) {
      // category dialog
      state.showCategoryDialog = false;
      state.showCategoryMobileDialog = false;
      state.categoryDialogStep = 1;

      // StokvelId dialog
      state.showStokvelIdDialog = false;
      state.showStokvelIdMobileDialog = false;
      state.stokvelIdDialogStep = 1;

      // UmbrellaTrust dialog
      state.showUmbrellaTrustDialog = false;
      state.showUmbrellaTrustMobileDialog = false;
      state.umbrellaTrustDialogStep = 1;

      // Investment dialog
      state.showInvestmentDialog = false;
      state.showInvestmentMobileDialog = false;
      state.investmentDialogStep = 1;

      // umbrella side desktop bar
      state.showUmbrellaTrustSidebar = false;

      // umbrella side desktop bar
      state.showStandAloneSidebar = false;
    },
  },

  actions: {},

  getters: {
    // category dialog
    getShowCategoryDialog(state) {
      return state.showCategoryDialog;
    },
    getShowCategoryMobileDialog(state) {
      return state.showCategoryMobileDialog;
    },
    getCategoryDialogStep(state) {
      return state.categoryDialogStep;
    },

    // StokvelId dialog
    getShowStokvelIdDialog(state) {
      return state.showStokvelIdDialog;
    },
    getShowStokvelIdMobileDialog(state) {
      return state.showStokvelIdMobileDialog;
    },
    getStokvelIdDialogStep(state) {
      return state.stokvelIdDialogStep;
    },

    // UmbrellaTrust dialog
    getShowUmbrellaTrustDialog(state) {
      return state.showUmbrellaTrustDialog;
    },
    getShowUmbrellaTrustMobileDialog(state) {
      return state.showUmbrellaTrustMobileDialog;
    },
    getUmbrellaTrustDialogStep(state) {
      return state.umbrellaTrustDialogStep;
    },

    // Investment dialog
    getShowInvestmentDialog(state) {
      return state.showInvestmentDialog;
    },
    getShowInvestmentMobileDialog(state) {
      return state.showInvestmentMobileDialog;
    },
    getInvestmentDialogStep(state) {
      return state.investmentDialogStep;
    },

    // umbrella side desktop bar
    getShowUmbrellaTrustSidebar(state) {
      return state.showUmbrellaTrustSidebar;
    },

    // StandAlone desktop bar
    getShowStandAloneSidebar(state) {
      return state.showStandAloneSidebar;
    },
  },
};

export default globalAdminTransaction;
