import apiHelperFactory from "@/http/apiHelperFactory";
import helpers from "@/plugins/helpers";

const taskApi = {
  namespaced: true,

  state: {
    // showDialog
    showDialog: false,

    // action
    action: "",

    // taskId
    taskId: 0,

    // task
    task: {},

    // tasks
    tasks: [],
  },

  mutations: {
    // setShowDialog
    setShowDialog(state, payload) {
      state.showDialog = payload;
    },

    // setAction
    setAction(state, payload) {
      state.action = payload;
    },

    // setTaskId
    setTaskId(state, payload) {
      state.taskId = payload;
    },

    // setTask
    setTask(state, payload) {
      state.task = payload;
    },

    // setTasks
    setTasks(state, payload) {
      state.tasks = payload;
    },

    // resetState
    resetState(state) {
      // showDialog
      state.showDialog = 0;

      // action
      state.action = "";

      // taskId
      state.taskId = 0;

      // task
      state.task = {};

      // tasks
      state.tasks = [];
    },
  },

  actions: {
    // getTasksReq
    async getTasksReq({ commit }, payload = {}) {
      // useLoading
      const useLoading = "useLoading" in payload ? payload.useLoading : true;

      // useLoading
      if (useLoading) {
        // startLoading
        commit("app/startLoading", null, { root: true });
      }

      // setUseAppToken
      commit("auth/setUseAppToken", true, { root: true });

      try {
        // getTasksRes
        const getTasksRes = await apiHelperFactory.getTasks(payload.clientId);

        // setTasks
        commit("setTasks", getTasksRes.data);

        return getTasksRes;
      } catch (error) {
        if (error.response) {
          // handle response error
        } else if (error.request) {
          // handle request error
        } else {
          // handle other errors
        }
      } finally {
        // useLoading
        if (useLoading) {
          // stopLoading
          commit("app/stopLoading", null, { root: true });
        }

        // setUseAppToken
        commit("auth/setUseAppToken", false, { root: true });
      }
    },

    // createTaskReq
    async createTaskReq({ commit }, { payload, clientId }) {
      // startLoading
      commit("app/startLoading", null, { root: true });

      // setUseAppToken
      commit("auth/setUseAppToken", true, { root: true });

      try {
        return await apiHelperFactory.createTask(payload, clientId);
      } catch (error) {
        // error.response
        if (error.response) {
          let res = error.response;

          if (res.status === 400) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 401) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 403) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 404) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 500) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else {
            // handleError
            helpers.handleError(res.status, res.data);
          }
        } else {
          // handleError
          helpers.handleError();
        }
      } finally {
        // setUseAppToken
        commit("auth/setUseAppToken", false, { root: true });

        // stopLoading
        commit("app/stopLoading", null, { root: true });
      }
    },

    // updateTaskReq
    async updateTaskReq({ commit }, { payload, clientId, taskId }) {
      // startLoading
      commit("app/startLoading", null, { root: true });

      // setUseAppToken
      commit("auth/setUseAppToken", true, { root: true });

      try {
        return await apiHelperFactory.updateTask(payload, clientId, taskId);
      } catch (error) {
        // error.response
        if (error.response) {
          let res = error.response;

          if (res.status === 400) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 401) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 403) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 404) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 500) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else {
            // handleError
            helpers.handleError(res.status, res.data);
          }
        } else {
          // handleError
          helpers.handleError();
        }
      } finally {
        // setUseAppToken
        commit("auth/setUseAppToken", false, { root: true });

        // stopLoading
        commit("app/stopLoading", null, { root: true });
      }
    },

    // deleteTaskReq
    async deleteTaskReq({ commit }, { payload }) {
      // startLoading
      commit("app/startLoading", null, { root: true });

      // setUseAppToken
      commit("auth/setUseAppToken", true, { root: true });

      try {
        return await apiHelperFactory.deleteTask(
          payload.clientId,
          payload.taskId
        );
      } catch (error) {
        // error.response
        if (error.response) {
          let res = error.response;

          if (res.status === 400) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 401) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 403) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 404) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 500) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else {
            // handleError
            helpers.handleError(res.status, res.data);
          }
        } else {
          // handleError
          helpers.handleError();
        }
      } finally {
        // setUseAppToken
        commit("auth/setUseAppToken", false, { root: true });

        // stopLoading
        commit("app/stopLoading", null, { root: true });
      }
    },
  },

  getters: {
    // getShowDialog
    getShowDialog(state) {
      return state.showDialog;
    },

    // getAction
    getAction(state) {
      return state.action;
    },

    // getTaskId
    getTaskId(state) {
      return state.taskId;
    },

    // getTask
    getTask(state) {
      return state.task;
    },

    // getTasks
    getTasks(state) {
      return state.tasks;
    },

    // getFirstTask
    getFirstTask(state) {
      return state.tasks.find((task) => task.archive === "false") || null;
    },

    // getTaskById
    getTaskById: (state) => (id) => {
      let foundTask = state.tasks.find(
        (task) => task.id === id && task.archive === "false"
      );

      return foundTask ? foundTask : null;
    },

    // getCompletedTasks
    getCompletedTasks(state) {
      return state.tasks
        .filter(
          (task) =>
            task.complete === "true" &&
            task.ignore === "false" &&
            task.archive === "false"
        )
        .sort((a, b) => b.id - a.id); // Sort tasks by ID in descending order
    },

    // getPendingTasks
    getPendingTasks(state) {
      return state.tasks
        .filter(
          (task) =>
            task.complete === "false" &&
            task.ignore === "false" &&
            task.archive === "false"
        )
        .sort((a, b) => b.id - a.id); // Sort tasks by ID in descending order
    },
  },
};

export default taskApi;
