import apiHelperFactory from "@/http/apiHelperFactory";

const datatableApi = {
  namespaced: true,

  state: {
    // datatables
    datatables: [],
  },

  mutations: {
    // setDatatables
    setDatatables(state, payload) {
      state.datatables = payload;
    },

    // resetState
    resetState(state) {
      // datatables
      state.datatables = [];
    },
  },

  actions: {
    // getDatatablesReq
    async getDatatablesReq({ commit }, payload) {
      // startLoading
      commit("app/startLoading", null, { root: true });

      // setUseAppToken
      commit("auth/setUseAppToken", true, { root: true });

      try {
        // getDatatablesRes
        const getDatatablesRes = await apiHelperFactory.getDatatables(
          payload.clientId
        );

        // setDatatables
        commit("setDatatables", getDatatablesRes.data);

        return getDatatablesRes;
      } catch (error) {
        if (error.response) {
          // handle response error
        } else if (error.request) {
          // handle request error
        } else {
          // handle other errors
        }
      } finally {
        // stopLoading
        commit("app/stopLoading", null, { root: true });

        // setUseAppToken
        commit("auth/setUseAppToken", false, { root: true });
      }
    },
  },

  getters: {
    // getDatatables
    getDatatables(state) {
      return state.datatables;
    },
  },
};

export default datatableApi;
