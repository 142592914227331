import axios from "@/plugins/axios";

const isDevelopment = process.env.NODE_ENV === "development";

export default {
  // get
  get: async (url, params, encode = true, responseType = "json") => {
    try {
      let fullUrl = url;

      let config = {
        responseType: responseType,
      };

      if (encode) {
        // Let axios do the URL encoding
        const response = await axios.get(url, { ...config, params });
        return response;
      } else {
        // Manually create the URL with the parameters
        const queryParams = Object.entries(params)
          .map(([key, value]) => `${key}=${value}`)
          .join("&");
        if (queryParams) {
          fullUrl = `${url}?${queryParams}`;
        }

        const response = await axios.get(fullUrl, config);
        return response;
      }
    } catch (error) {
      if (isDevelopment) {
        console.error(error);
      }

      throw error;
    }
  },

  getBlob: async (url, params, encode = true, responseType = "blob") => {
    try {
      let fullUrl = url;

      let config = {
        responseType: responseType,
      };

      if (encode) {
        // Let axios do the URL encoding
        const response = await axios.get(url, { ...config, params });
        console.log("reponse", response);
        return response;
      } else {
        // Manually create the URL with the parameters
        const queryParams = Object.entries(params)
          .map(([key, value]) => `${key}=${value}`)
          .join("&");
        if (queryParams) {
          fullUrl = `${url}?${queryParams}`;
        }

        const response = await axios.get(fullUrl, config);
        return response;
      }
    } catch (error) {
      if (isDevelopment) {
        console.error(error);
      }

      throw error;
    }
  },

  gets: async (url, params, encode = true, responseType = "text/plain") => {
    try {
      let fullUrl = url;

      let config = {
        responseType: responseType,
      };

      if (responseType === "text/plain") {
        config.headers = {
          Accept: "text/plain",
        };
      }

      if (encode) {
        // Let axios do the URL encoding
        const response = await axios.get(url, { ...config, params });
        return response;
      } else {
        // Manually create the URL with the parameters
        const queryParams = Object.entries(params)
          .map(([key, value]) => `${key}=${value}`)
          .join("&");
        if (queryParams) {
          fullUrl = `${url}?${queryParams}`;
        }

        const response = await axios.get(fullUrl, config);
        return response;
      }
    } catch (error) {
      if (isDevelopment) {
        console.error(error);
      }

      throw error;
    }
  },
  // post
  post: async (url, body, params = {}, isFile = false) => {
    try {
      let config = {
        params: params,
      };

      if (isFile) {
        let formData = new FormData();

        // Append file to formData
        formData.append("file", body.file);

        // Append other properties to formData
        for (let property in body) {
          if (property !== "file") {
            formData.append(property, body[property]);
          }
        }

        config = {
          ...config,
          headers: { "Content-Type": "multipart/form-data" },
        };

        body = formData;
      }

      const response = await axios.post(url, body, config);

      return response;
    } catch (error) {
      if (isDevelopment) {
        console.error(error);
      }

      throw error;
    }
  },

  // put
  put: async (url, body) => {
    try {
      const response = await axios.put(url, body);

      return response;
    } catch (error) {
      if (isDevelopment) {
        console.error(error);
      }

      throw error;
    }
  },

  // delete
  delete: async (url) => {
    try {
      const response = await axios.delete(url);

      return response;
    } catch (error) {
      if (isDevelopment) {
        console.error(error);
      }

      throw error;
    }
  },
};
