import axios from "axios";
import store from "@/store";

const baseDomain = process.env.VUE_APP_BANK_API_BASE_URL;
const baseURL = `${baseDomain}`;

const bankApiAxios = axios.create({
  baseURL,
});

bankApiAxios.defaults.headers.common = {
  ApiKey: process.env.VUE_APP_BANK_API_KEY,
  "Access-Control-Allow-Origin": "*",
  "Content-Type": "application/json",
};

// Add a request interceptor
bankApiAxios.interceptors.request.use(
  function (config) {
    // token
    const token = store.state.authBank.token;

    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }

    return config;
  },

  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

export default bankApiAxios;
