const adminTransaction = {
  namespaced: true,

  state: {
    // category dialog
    showCategoryDialog: false,
    showCategoryMobileDialog: false,
    categoryDialogStep: 1,

    // stokvelId dialog
    showStokvelIdDialog: false,
    showStokvelIdMobileDialog: false,
    stokvelIdDialogStep: 1,

    // UmbrellaTrust dialog
    showUmbrellaTrustDialog: false,
    showUmbrellaTrustMobileDialog: false,
    umbrellaTrustDialogStep: 1,

    // UmbrellaTrust dialog
    showSplitDialog: false,
    showSplitMobileDialog: false,
    splitDialogStep: 1,

    // amountForLoans
    amountForLoans: "",

    // amountForInvestments
    amountForInvestments: "",

    // amountForCashInBank
    amountForCashInBank: "",

    // umbrella side desktop bar
    showUmbrellaTrustSidebar: false,

    // StandAlone desktop bar
    showStandAloneSidebar: false,

    // totalOpeningBalance
    totalOpeningBalance: 7500000,

    // interestEarnedOnLending
    interestEarnedOnLending: 3000000,

    // interestEarnedOnLoans
    interestEarnedOnLoans: 2000000,

    // interestEarnedOnInvestments
    interestEarnedOnInvestments: 6000000,

    // interestEarnedOnCashInBank
    interestEarnedOnCashInBank: 3000000,

    // interestEarnedOnDefalults
    interestEarnedOnDefalults: 5000000,
  },

  mutations: {
    // category dialog
    setShowCategoryDialog(state, payload) {
      state.showCategoryDialog = payload;
    },
    setShowCategoryMobileDialog(state, payload) {
      state.showCategoryMobileDialog = payload;
    },
    setCategoryDialogStep(state, payload) {
      state.categoryDialogStep = payload;
    },

    // StokvelId dialog
    setShowStokvelIdDialog(state, payload) {
      state.showStokvelIdDialog = payload;
    },
    setShowStokvelIdMobileDialog(state, payload) {
      state.showStokvelIdMobileDialog = payload;
    },
    setStokvelIdDialogStep(state, payload) {
      state.stokvelIdDialogStep = payload;
    },

    // UmbrellaTrust dialog
    setShowUmbrellaTrustDialog(state, payload) {
      state.showUmbrellaTrustDialog = payload;
    },
    setShowUmbrellaTrustMobileDialog(state, payload) {
      state.showUmbrellaTrustMobileDialog = payload;
    },
    setUmbrellaTrustDialogStep(state, payload) {
      state.umbrellaTrustDialogStep = payload;
    },

    // setShowSplitDialog dialog
    setShowSplitDialog(state, payload) {
      state.showSplitDialog = payload;
    },
    setShowSplitMobileDialog(state, payload) {
      state.showSplitMobileDialog = payload;
    },
    setSplitDialogStep(state, payload) {
      state.splitDialogStep = payload;
    },

    // setAmountForLoans
    setAmountForLoans(state, payload) {
      state.amountForLoans = payload;
    },

    // setAmountForInvestments
    setAmountForInvestments(state, payload) {
      state.amountForInvestments = payload;
    },

    // setAmountForCashInBank
    setAmountForCashInBank(state, payload) {
      state.amountForCashInBank = payload;
    },

    // umbrella side desktop bar
    setShowUmbrellaTrustSidebar(state, payload) {
      state.showUmbrellaTrustSidebar = payload;
    },

    // umbrella side desktop bar
    setShowStandAloneSidebar(state, payload) {
      state.showStandAloneSidebar = payload;
    },

    // setInterestEarnedReport
    setInterestEarnedReport(state, payload) {
      state.totalOpeningBalance = payload;
      state.interestEarnedOnLending = payload;
      state.interestEarnedOnLoans = payload;
      state.interestEarnedOnInvestments = payload;
      state.interestEarnedOnCashInBank = payload;
      state.interestEarnedOnDefalults = payload;
    },

    // resetState
    resetState(state) {
      // category dialog
      state.showCategoryDialog = false;
      state.showCategoryMobileDialog = false;
      state.categoryDialogStep = 1;

      // StokvelId dialog
      state.showStokvelIdDialog = false;
      state.showStokvelIdMobileDialog = false;
      state.stokvelIdDialogStep = 1;

      // UmbrellaTrust dialog
      state.showUmbrellaTrustDialog = false;
      state.showUmbrellaTrustMobileDialog = false;
      state.umbrellaTrustDialogStep = 1;

      state.showSplitDialog = false;
      state.showSplitMobileDialog = false;
      state.splitDialogStep = 1;

      // amountForLoans
      state.amountForLoans = "";

      // amountForInvestments
      state.amountForInvestments = "";

      // amountForCashInBank
      state.amountForCashInBank = "";

      // umbrella side desktop bar
      state.showUmbrellaTrustSidebar = false;

      // umbrella side desktop bar
      state.showStandAloneSidebar = false;

      // totalOpeningBalance
      state.totalOpeningBalance = 7500000;

      // interestEarnedOnLending
      state.interestEarnedOnLending = 3000000;

      // interestEarnedOnLoans
      state.interestEarnedOnLoans = 2000000;

      // interestEarnedOnInvestments
      state.interestEarnedOnInvestments = 6000000;

      // interestEarnedOnCashInBank
      state.interestEarnedOnCashInBank = 3000000;

      // interestEarnedOnDefalults
      state.interestEarnedOnDefalults = 5000000;
    },
  },

  actions: {},

  getters: {
    // category dialog
    getShowCategoryDialog(state) {
      return state.showCategoryDialog;
    },
    getShowCategoryMobileDialog(state) {
      return state.showCategoryMobileDialog;
    },
    getCategoryDialogStep(state) {
      return state.categoryDialogStep;
    },

    // StokvelId dialog
    getShowStokvelIdDialog(state) {
      return state.showStokvelIdDialog;
    },
    getShowStokvelIdMobileDialog(state) {
      return state.showStokvelIdMobileDialog;
    },
    getStokvelIdDialogStep(state) {
      return state.stokvelIdDialogStep;
    },

    // UmbrellaTrust dialog
    getShowUmbrellaTrustDialog(state) {
      return state.showUmbrellaTrustDialog;
    },
    getShowUmbrellaTrustMobileDialog(state) {
      return state.showUmbrellaTrustMobileDialog;
    },
    getUmbrellaTrustDialogStep(state) {
      return state.umbrellaTrustDialogStep;
    },

    // getShowSplitDialog dialog
    getShowSplitDialog(state) {
      return state.showSplitDialog;
    },
    getShowSplitMobileDialog(state) {
      return state.showSplitMobileDialog;
    },
    getSplitDialogStep(state) {
      return state.splitDialogStep;
    },

    // getAmountForLoans
    getAmountForLoans(state) {
      return state.amountForLoans;
    },

    // getAmountForInvestments
    getAmountForInvestments(state) {
      return state.amountForInvestments;
    },

    // getAmountForCashInBank
    getAmountForCashInBank(state) {
      return state.amountForCashInBank;
    },

    // umbrella side desktop bar
    getShowUmbrellaTrustSidebar(state) {
      return state.showUmbrellaTrustSidebar;
    },

    // StandAlone desktop bar
    getShowStandAloneSidebar(state) {
      return state.showStandAloneSidebar;
    },

    // getTotalOpeningBalance
    getTotalOpeningBalance(state) {
      return state.totalOpeningBalance;
    },

    // getInterestEarnedOnLending
    getInterestEarnedOnLending(state) {
      return state.interestEarnedOnLending;
    },

    // getInterestEarnedOnLoans
    getInterestEarnedOnLoans(state) {
      return state.interestEarnedOnLoans;
    },

    // getInterestEarnedOnInvestments
    getInterestEarnedOnInvestments(state) {
      return state.interestEarnedOnInvestments;
    },

    // getInterestEarnedOnCashInBank
    getInterestEarnedOnCashInBank(state) {
      return state.interestEarnedOnCashInBank;
    },

    // getInterestEarnedOnCashInBank
    getInterestEarnedOnDefalults(state) {
      return state.interestEarnedOnDefalults;
    },
  },
};

export default adminTransaction;
