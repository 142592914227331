const alert = {
  namespaced: true,

  state: {
    // showAlert
    showAlert: false,

    // type
    type: "error",

    // title
    title: "Oops!",

    // message
    message: `Oops! An error occurred`,

    // maxWidth
    maxWidth: 511,

    // maxTitleWidth
    maxTitleWidth: 409,

    // maxMessageWidth
    maxMessageWidth: 409,

    // maxBtnWidth
    maxBtnWidth: 388,

    // timeout
    timeout: 5000,
  },

  mutations: {
    // setShowAlert
    setShowAlert(state, payload) {
      state.showAlert = payload;
    },

    // showAlert
    showAlert(state, payload) {
      // showAlert
      if ("showAlert" in payload) {
        state.showAlert = payload.showAlert;
      } else {
        state.showAlert = true;
      }

      // type
      if ("type" in payload) {
        state.type = payload.type;
      }

      // title
      if ("title" in payload) {
        state.title = payload.title;
      }

      // message
      if ("message" in payload) {
        state.message = payload.message;
      }

      // maxWidth
      if ("maxWidth" in payload) {
        state.maxWidth = payload.maxWidth;
      }

      // maxTitleWidth
      if ("maxTitleWidth" in payload) {
        state.maxTitleWidth = payload.maxTitleWidth;
      }

      // maxMessageWidth
      if ("maxMessageWidth" in payload) {
        state.maxMessageWidth = payload.maxMessageWidth;
      }

      // maxBtnWidth
      if ("maxBtnWidth" in payload) {
        state.maxBtnWidth = payload.maxBtnWidth;
      }

      // timeout
      if ("timeout" in payload) {
        state.timeout = payload.timeout;
      }
    },

    // resetState
    resetState(state) {
      // showAlert
      state.showAlert = false;

      // type
      state.type = "error";

      // title
      state.title = "Oops!";

      // message
      state.message = "Oops! An error occurred";

      // maxWidth
      state.maxWidth = 511;

      // maxTitleWidth
      state.maxTitleWidth = 409;

      // maxMessageWidth
      state.maxMessageWidth = 409;

      // maxBtnWidth
      state.maxBtnWidth = 388;

      // timeout
      state.timeout = 500;
    },
  },

  actions: {},

  getters: {
    // getShowAlert
    getShowAlert(state) {
      return state.showAlert;
    },
  },
};

export default alert;
