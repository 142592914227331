import apiHelperFactory from "@/http/apiHelperFactory";
import helpers from "@/plugins/helpers";

const clientApi = {
  namespaced: true,

  state: {
    // clientId
    clientId: 0,

    // client
    client: {},

    // clients, with pagination
    clients: [],

    // clients, without pagination
    allClients: [],

    numApprovedClients: 0,

    // totalFilteredRecords
    totalFilteredRecords: 0,

    // accountsOverview
    accountsOverview: {},

    // accountsOverviewForClient
    accountsOverviewForClient: {},
  },

  mutations: {
    // setClientId
    setClientId(state, payload) {
      state.clientId = payload;
    },

    // setClient
    setClient(state, payload) {
      state.client = payload;
    },

    // setClients
    setClients(state, payload) {
      state.clients = payload;
    },
    setAllClients(state, payload) {
      state.allClients = payload;
    },

    setNumApprovedClients(state, payload) {
      state.numApprovedClients = payload;
    },

    // setTotalFilteredRecords
    setTotalFilteredRecords(state, payload) {
      state.totalFilteredRecords = payload;
    },

    // setAccountsOverview
    setAccountsOverview(state, payload) {
      state.accountsOverview = payload;
    },

    // setAccountsOverviewForClient
    setAccountsOverviewForClient(state, payload) {
      state.accountsOverviewForClient = payload;
    },

    // resetState
    resetState(state) {
      // clientId
      state.clientId = 0;

      // client
      state.client = {};

      // clients
      state.clients = [];

      state.numApprovedClients = 0;

      // totalFilteredRecords
      state.totalFilteredRecords = 0;

      // accountsOverview
      state.accountsOverview = {};

      // accountsOverviewForClient
      state.accountsOverviewForClient = {};
    },
  },

  actions: {
    // getClientAccountsOverviewReq
    async getClientAccountsOverviewReq({ commit }, payload = {}) {
      // useLoading
      const useLoading = "useLoading" in payload ? payload.useLoading : true;

      // useLoading
      if (useLoading) {
        // startLoading
        commit("app/startLoading", null, { root: true });
      }

      // setUseAppToken
      commit("auth/setUseAppToken", false, { root: true });

      try {
        // getSelfClientAccountsOverviewRes
        const getSelfClientAccountsOverviewRes =
          await apiHelperFactory.getSelfClientAccountsOverview(
            payload.clientId
          );

        // setAccountsOverview
        commit("setAccountsOverview", getSelfClientAccountsOverviewRes.data);

        return getSelfClientAccountsOverviewRes;
      } catch (error) {
        if (error.response) {
          // handle response error
        } else if (error.request) {
          // handle request error
        } else {
          // handle other errors
        }
      } finally {
        // useLoading
        if (useLoading) {
          // stopLoading
          commit("app/stopLoading", null, { root: true });
        }

        // setUseAppToken
        commit("auth/setUseAppToken", false, { root: true });
      }
    },

    // getClientAccountsOverviewForClientReq
    async getClientAccountsOverviewForClientReq({ commit }, payload = {}) {
      // useLoading
      const useLoading = "useLoading" in payload ? payload.useLoading : true;

      // useLoading
      if (useLoading) {
        // startLoading
        commit("app/startLoading", null, { root: true });
      }

      // setUseAppToken
      commit("auth/setUseAppToken", false, { root: true });

      try {
        // getClientAccountsOverviewForClientRes
        const getClientAccountsOverviewForClientRes =
          await apiHelperFactory.getClientAccountsOverview(payload.clientId);

        // setAccountsOverviewForClient
        commit(
          "setAccountsOverviewForClient",
          getClientAccountsOverviewForClientRes.data
        );

        return getClientAccountsOverviewForClientRes;
      } catch (error) {
        if (error.response) {
          // handle response error
        } else if (error.request) {
          // handle request error
        } else {
          // handle other errors
        }
      } finally {
        // useLoading
        if (useLoading) {
          // stopLoading
          commit("app/stopLoading", null, { root: true });
        }

        // setUseAppToken
        commit("auth/setUseAppToken", false, { root: true });
      }
    },

    // getClientsReq
    async getClientsReq({ commit }, payload) {
      // startLoading
      commit("app/startLoading", null, { root: true });

      // setUseAppToken
      commit("auth/setUseAppToken", true, { root: true });

      try {
        // params
        let params = {};
        params = payload;

        // getClientsRes
        const getClientsRes = await apiHelperFactory.getClients(params);

        if (getClientsRes.data && "pageItems" in getClientsRes.data) {
          // setClients
          commit("setClients", getClientsRes.data.pageItems);
          //commit("setAllClients", getClientsRes.data.pageItems);

          // totalFilteredRecords
          if ("totalFilteredRecords" in getClientsRes.data) {
            // setTotalFilteredRecords
            commit(
              "setTotalFilteredRecords",
              getClientsRes.data.totalFilteredRecords
            );
          }
        }

        return getClientsRes;
      } catch (error) {
        if (error.response) {
          let res = error.response;

          // handleError
          helpers.handleError(res.status, res.data);
        } else {
          // handleError
          helpers.handleError();
        }
      } finally {
        // stopLoading
        commit("app/stopLoading", null, { root: true });

        // setUseAppToken
        commit("auth/setUseAppToken", false, { root: true });
      }
    },

    // get all clients without pagination
    async getAllClientsReq({ commit }, payload) {
      console.log("Inside getAllClientsReq");

      // startLoading
      commit("app/startLoading", null, { root: true });

      // setUseAppToken
      commit("auth/setUseAppToken", true, { root: true });

      try {
        // params
        let params1 = {};
        params1 = payload;

        params1.limit = 1;
        params1.offset = 0;

        let totalRecords = 0;

        // fetch only first record just to get totalFilteredRecords
        const getClientsRes1 = await apiHelperFactory.getClients(params1);

        if (getClientsRes1.data && "pageItems" in getClientsRes1.data) {
          totalRecords = getClientsRes1.data.totalFilteredRecords;
          // setTotalFilteredRecords
          commit(
            "setTotalFilteredRecords",
            getClientsRes1.data.totalFilteredRecords
          );
        }

        // params
        let params2 = {};
        params2 = payload;

        // fetch all records (limit = totalFilteredRecords)
        params2.limit = totalRecords;
        // skip no records
        params2.offset = 0;

        // fetch all records
        const getClientsRes2 = await apiHelperFactory.getClients(params2);

        if (getClientsRes2.data && "pageItems" in getClientsRes2.data) {
          // set all clients
          commit("setAllClients", getClientsRes2.data.pageItems);
          console.log("setAllClients", getClientsRes2.data.pageItems);
        }

        let numApproved = 0;

        getClientsRes2.data.pageItems.forEach((client) => {
          if (
            client.status.value === "Approved" ||
            client.status.value == "Active"
          ) {
            numApproved++;
          }
        });

        commit("setNumApprovedClients", numApproved);

        return getClientsRes2;
      } catch (error) {
        if (error.response) {
          let res = error.response;

          // handleError
          helpers.handleError(res.status, res.data);
        } else {
          // handleError
          helpers.handleError();
        }
      } finally {
        // stopLoading
        commit("app/stopLoading", null, { root: true });

        // setUseAppToken
        commit("auth/setUseAppToken", false, { root: true });
      }
    },

    // getClientReq
    async getClientReq({ commit }, payload = {}) {
      // useLoading
      const useLoading = "useLoading" in payload ? payload.useLoading : true;

      // useLoading
      if (useLoading) {
        // startLoading
        commit("app/startLoading", null, { root: true });
      }

      // setUseAppToken
      commit("auth/setUseAppToken", true, { root: true });

      try {
        // getClientRes
        const getClientRes = await apiHelperFactory.getClient(payload.clientId);

        // setClient
        commit("setClient", getClientRes.data);

        return getClientRes;
      } catch (error) {
        if (error.response) {
          let res = error.response;

          // handleError
          helpers.handleError(res.status, res.data);
        } else {
          // handleError
          helpers.handleError();
        }
      } finally {
        // useLoading
        if (useLoading) {
          // stopLoading
          commit("app/stopLoading", null, { root: true });
        }

        // setUseAppToken
        commit("auth/setUseAppToken", false, { root: true });
      }
    },

    // getGetEntityImageDataURIReq
    async getGetEntityImageDataURIReq({ commit }, payload = {}) {
      // useLoading
      const useLoading = "useLoading" in payload ? payload.useLoading : true;

      // useLoading
      if (useLoading) {
        // startLoading
        commit("app/startLoading", null, { root: true });
      }

      // setUseAppToken
      commit("auth/setUseAppToken", true, { root: true });

      try {
        // params
        let params = {
          maxHeight: "maxHeight" in payload ? payload.maxHeight : 150,
        };

        // getGetEntityImageDataURIRes
        const getGetEntityImageDataURIRes =
          await apiHelperFactory.getGetEntityImageDataURI(
            payload.clientId,
            params
          );
        console.log("getGetEntityImageDataURIRes", getGetEntityImageDataURIRes);
        if (
          "status" in getGetEntityImageDataURIRes &&
          getGetEntityImageDataURIRes.status === 200 &&
          "data" in getGetEntityImageDataURIRes
        ) {
          // setImage
          commit("auth/setImage", getGetEntityImageDataURIRes.data, {
            root: true,
          });
        }

        return getGetEntityImageDataURIRes;
      } catch (error) {
        if (error.response) {
          // handle response error
        } else if (error.request) {
          // handle request error
        } else {
          // handle other errors
        }
      } finally {
        // useLoading
        if (useLoading) {
          // stopLoading
          commit("app/stopLoading", null, { root: true });
        }

        // setUseAppToken
        commit("auth/setUseAppToken", false, { root: true });
      }
    },

    // uploadAnImageForAnEntityMultiPartFormDataReq
    async uploadAnImageForAnEntityMultiPartFormDataReq(
      { commit },
      { payload, clientId }
    ) {
      // startLoading
      commit("app/startLoading", null, { root: true });

      // setUseAppToken
      commit("auth/setUseAppToken", true, { root: true });

      try {
        return await apiHelperFactory.uploadAnImageForAnEntityMultiPartFormData(
          payload,
          clientId
        );
      } catch (error) {
        // error.response
        if (error.response) {
          let res = error.response;

          if (res.status === 400) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 401) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 403) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 404) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 500) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else {
            // handleError
            helpers.handleError(res.status, res.data);
          }
        } else {
          // handleError
          helpers.handleError();
        }
      } finally {
        // setUseAppToken
        commit("auth/setUseAppToken", false, { root: true });

        // stopLoading
        commit("app/stopLoading", null, { root: true });
      }
    },
    // deleteAnImageForAnEntityMultiPartFormDataReq
    async deleteAnImageForAnEntityMultiPartFormDataReq(
      { commit },
      { clientId }
    ) {
      // startLoading
      commit("app/startLoading", null, { root: true });

      // setUseAppToken
      commit("auth/setUseAppToken", true, { root: true });

      try {
        return await apiHelperFactory.deleteAnImageForAnEntityMultiPartFormData(
          clientId
        );
      } catch (error) {
        // error.response
        if (error.response) {
          let res = error.response;

          if (res.status === 400) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 401) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 403) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 404) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 500) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else {
            // handleError
            helpers.handleError(res.status, res.data);
          }
        } else {
          // handleError
          helpers.handleError();
        }
      } finally {
        // setUseAppToken
        commit("auth/setUseAppToken", false, { root: true });

        // stopLoading
        commit("app/stopLoading", null, { root: true });
      }
    },

    // activateClientReq
    async activateClientReq({ commit }, payload = {}) {
      // useLoading
      const useLoading = "useLoading" in payload ? payload.useLoading : true;

      // useLoading
      if (useLoading) {
        // startLoading
        commit("app/startLoading", null, { root: true });
      }

      // setUseAppToken
      commit("auth/setUseAppToken", true, { root: true });

      try {
        return await apiHelperFactory.activateClient(
          payload.body,
          payload.clientId
        );
      } catch (error) {
        // error.response
        if (error.response) {
          let res = error.response;

          if (res.status === 400) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 401) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 403) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 404) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 500) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else {
            // handleError
            helpers.handleError(res.status, res.data);
          }
        } else {
          // handleError
          helpers.handleError();
        }
      } finally {
        // useLoading
        if (useLoading) {
          // stopLoading
          commit("app/stopLoading", null, { root: true });
        }

        // stopLoading
        commit("app/stopLoading", null, { root: true });
      }
    },

    // closeClientReq
    async closeClientReq({ commit }, payload = {}) {
      // useLoading
      const useLoading = "useLoading" in payload ? payload.useLoading : true;

      // useLoading
      if (useLoading) {
        // startLoading
        commit("app/startLoading", null, { root: true });
      }

      // setUseAppToken
      commit("auth/setUseAppToken", true, { root: true });

      try {
        return await apiHelperFactory.closeClient(
          payload.body,
          payload.clientId
        );
      } catch (error) {
        // error.response
        if (error.response) {
          let res = error.response;

          if (res.status === 400) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 401) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 403) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 404) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 500) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else {
            // handleError
            helpers.handleError(res.status, res.data);
          }
        } else {
          // handleError
          helpers.handleError();
        }
      } finally {
        // useLoading
        if (useLoading) {
          // stopLoading
          commit("app/stopLoading", null, { root: true });
        }

        // stopLoading
        commit("app/stopLoading", null, { root: true });
      }
    },

    // rejectClientReq
    async rejectClientReq({ commit }, payload = {}) {
      // useLoading
      const useLoading = "useLoading" in payload ? payload.useLoading : true;

      // useLoading
      if (useLoading) {
        // startLoading
        commit("app/startLoading", null, { root: true });
      }

      // setUseAppToken
      commit("auth/setUseAppToken", true, { root: true });

      try {
        return await apiHelperFactory.rejectClient(
          payload.body,
          payload.clientId
        );
      } catch (error) {
        // error.response
        if (error.response) {
          let res = error.response;

          if (res.status === 400) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 401) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 403) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 404) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 500) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else {
            // handleError
            helpers.handleError(res.status, res.data);
          }
        } else {
          // handleError
          helpers.handleError();
        }
      } finally {
        // useLoading
        if (useLoading) {
          // stopLoading
          commit("app/stopLoading", null, { root: true });
        }

        // stopLoading
        commit("app/stopLoading", null, { root: true });
      }
    },
  },

  getters: {
    // getClientId
    getClientId(state) {
      return state.clientId;
    },

    // getClient
    getClient(state) {
      return state.client;
    },

    // getClients
    getClients(state) {
      return state.clients;
    },

    // getAllClients
    getAllClients(state) {
      return state.allClients;
    },

    // getClients. get all clients with Active status
    // getAllClients(state) {    // === original method
    //   let sum = 0;

    //   let data = state.allClients;

    //   data.forEach((item) => {
    //     if (item.status.value === "Active") {
    //       sum += 1;
    //     }
    //   });
    //   return sum;
    // },

    // getActiveClients. get all clients with Active status
    getActiveClients(state) {
      let sum = 0;

      let data = state.allClients;

      data.forEach((item) => {
        if (item.status.value === "Active") {
          sum += 1;
        }
      });
      return sum;
    },

    // getClients. get total number of clients, irrespective of status
    getNumberAllClients(state) {
      let sum = 0;

      let data = state.allClients;

      data.forEach((item) => {
        sum += 1;
      });
      return sum;
    },

    // getClients. get all clients with Active or Approved status
    // getApprovedClients(state) {
    //   let sum = 0;

    //   let data = state.allClients;

    //   data.forEach((item) => {
    //     if (
    //       item.status.value === "Active" ||
    //       item.status.value === "Approved"
    //     ) {
    //       sum += 1;
    //     }
    //   });
    //   return sum;
    // },
    getApprovedClients(state) {
      return state.numApprovedClients;
    },

    // getDeclinedClients , get all clients with Closed or Rejected status
    // offboard
    getDeclinedClients(state) {
      let sum = 0;

      let data = state.allClients;

      data.forEach((item) => {
        if (
          item.status.value === "Closed" ||
          item.status.value === "Rejected"
        ) {
          sum += 1;
        }
      });
      return sum;
    },

    // getPendingClients
    getPendingClients(state) {
      let sum = 0;

      let data = state.allClients;

      data.forEach((item) => {
        if (item.status.value === "Pending") {
          sum += 1;
        }
      });
      return sum;
    },
    // getClients
    getClientStatus(state) {
      return state.clients.filter((user) => {
        return user.active === true;
      });
    },

    // getTotalFilteredRecords
    getTotalFilteredRecords(state) {
      return state.totalFilteredRecords;
    },

    // get total active members from start to previous year (Total Members previous year)
    getTotalClientsPreviousYear(state) {
      let sum = 0;

      let data = state.allClients;

      const currentDate = new Date(); // Get the current date
      let currentYear = currentDate.getFullYear();

      let previousYear = currentYear - 1;

      // Iterate over the clients
      data.forEach((item) => {
        if (
          // only consider active clients
          item.status.value === "Active"
        ) {
          let [year] = item.timeline.activatedOnDate;
          // if client was activated in or before previous year, increase sum
          if (year <= previousYear) sum += 1;
        }
      });
      return sum;
    },

    //get clients onboarded in previous month
    getApprovedPreviousMonth(state) {
      let sum = 0;

      let data = state.allClients;
      console.log("let data = state.allClients", data);

      const currentDate = new Date(); // Get the current date
      let currentYear = currentDate.getFullYear();
      const currentMonth = currentDate.getMonth() + 1; // Month is zero-indexed

      let previousMonth = 0;

      // if current month is January, December of the previous year should be used
      if (currentMonth === 1) {
        currentYear = currentYear - 1;
        previousMonth = 12;
      } else {
        previousMonth = currentMonth - 1;
      }

      // Iterate over the clients
      data.forEach((item) => {
        if (
          // only consider onboarded clients
          item.status.value === "Active" ||
          item.status.value === "Approved"
        ) {
          let [year, month] = item.timeline.activatedOnDate;
          // if client was onboarded in previous month, increase sum
          if (year === currentYear && month === previousMonth) sum += 1;
        }
      });
      return sum;
    },

    //get new clients onboarded in current month
    getNewlyApprovedCurrentMonth(state) {
      let sum = 0;

      let data = state.allClients;

      const currentDate = new Date(); // Get the current date
      let currentYear = currentDate.getFullYear();
      const currentMonth = currentDate.getMonth() + 1; // Month is zero-indexed

      // Iterate over the clients
      data.forEach((item) => {
        if (
          // only consider onboarded clients
          item.status.value === "Active" ||
          item.status.value === "Approved"
        ) {
          let [year, month] = item.timeline.activatedOnDate;
          // if client was onboarded during current month, increase sum
          if (year === currentYear && month === currentMonth) sum += 1;
        }
      });
      return sum;
    },

    //get clients offboarded in current month
    getOffBoardedCurrentMonth(state) {
      let sum = 0;

      let data = state.allClients;

      const currentDate = new Date(); // Get the current date
      let currentYear = currentDate.getFullYear();
      const currentMonth = currentDate.getMonth() + 1; // Month is zero-indexed

      // Iterate over the clients
      data.forEach((item) => {
        if (
          // only consider offboarded clients
          item.status.value === "Closed"
        ) {
          let [year, month] = item.timeline.closedOnDate;
          // if client was offboarded during current month, increase sum
          if (year === currentYear && month === currentMonth) sum += 1;
        }
      });
      return sum;
    },

    //get clients offboarded in previous month
    getOffBoardedPreviousMonth(state) {
      let sum = 0;

      let data = state.allClients;

      const currentDate = new Date(); // Get the current date
      let currentYear = currentDate.getFullYear();
      const currentMonth = currentDate.getMonth() + 1; // Month is zero-indexed

      let previousMonth = 0;

      // if current month is January, December of the previous year should be used
      if (currentMonth === 1) {
        currentYear = currentYear - 1;
        previousMonth = 12;
      } else {
        previousMonth = currentMonth - 1;
      }

      // Iterate over the clients
      data.forEach((item) => {
        if (
          // only consider offboarded clients
          item.status.value === "Closed"
        ) {
          let [year, month] = item.timeline.closedOnDate;
          // if client was offboarded during previous month, increase sum
          if (year === currentYear && month === previousMonth) sum += 1;
        }
      });
      return sum;
    },

    // getAccountsOverview
    getAccountsOverview(state) {
      return state.accountsOverview;
    },

    // getSavingsAccounts
    getSavingsAccounts(state) {
      if (
        state.accountsOverview &&
        "savingsAccounts" in state.accountsOverview
      ) {
        return state.accountsOverview.savingsAccounts;
      }

      return [];
    },

    // getFirstSavingsAccount
    getFirstSavingsAccount(state) {
      if (
        state.accountsOverview &&
        "savingsAccounts" in state.accountsOverview &&
        Array.isArray(state.accountsOverview.savingsAccounts) &&
        state.accountsOverview.savingsAccounts.length > 0
      ) {
        return state.accountsOverview.savingsAccounts.find(
          (account) => account.productName === "Wallet"
        );
      }

      return null;
    },

    // getFirstWalletSavingsAccount
    getFirstWalletSavingsAccount(state) {
      if (
        state.accountsOverview &&
        "savingsAccounts" in state.accountsOverview &&
        Array.isArray(state.accountsOverview.savingsAccounts) &&
        state.accountsOverview.savingsAccounts.length > 0
      ) {
        const st = state.accountsOverview.savingsAccounts.find(
          (account) =>
            account.productName === "Wallet" && account.status.active === true
        );
        return st;
      }

      return null;
    },

    // getFirstWalletSavingsAccount
    getFirstWalletSavingsAccountAll(state) {
      if (
        state.accountsOverviewForClient &&
        "savingsAccounts" in state.accountsOverviewForClient &&
        Array.isArray(state.accountsOverviewForClient.savingsAccounts) &&
        state.accountsOverviewForClient.savingsAccounts.length > 0
      ) {
        const st = state.accountsOverviewForClient.savingsAccounts.find(
          (account) => account.productName === "Wallet"
        );
        return st;
      }

      return null;
    },

    // getFirstContributionSavingsAccount
    getFirstContributionSavingsAccount(state) {
      if (
        state.accountsOverview &&
        "savingsAccounts" in state.accountsOverview &&
        Array.isArray(state.accountsOverview.savingsAccounts) &&
        state.accountsOverview.savingsAccounts.length > 0
      ) {
        console.log(state.accountsOverview.savingsAccounts);
        return state.accountsOverview.savingsAccounts.find(
          (account) => account.productName === "Contribution"
        );
      }

      return null;
    },

    // getFirstContributionSavingsAccount
    getFirstContributionSavingsAccountAll(state) {
      if (
        state.accountsOverviewForClient &&
        "savingsAccounts" in state.accountsOverviewForClient &&
        Array.isArray(state.accountsOverviewForClient.savingsAccounts) &&
        state.accountsOverviewForClient.savingsAccounts.length > 0
      ) {
        console.log(state.accountsOverviewForClient.savingsAccounts);
        return state.accountsOverviewForClient.savingsAccounts.find(
          (account) => account.productName === "Contribution"
        );
      }

      return null;
    },

    // getSavingsAccountById
    getSavingsAccountById: (state) => (id) => {
      if (
        state.accountsOverview &&
        "savingsAccounts" in state.accountsOverview &&
        Array.isArray(state.accountsOverview.savingsAccounts) &&
        state.accountsOverview.savingsAccounts.length > 0
      ) {
        return state.accountsOverview.savingsAccounts.find(
          (savingsAccount) => savingsAccount.id === id
        );
      }

      return null;
    },

    // getAccountsOverviewForClient
    getAccountsOverviewForClient(state) {
      return state.accountsOverviewForClient;
    },

    // getSavingsAccountsForClient
    getSavingsAccountsForClient(state) {
      if (
        state.accountsOverviewForClient &&
        "savingsAccounts" in state.accountsOverviewForClient
      ) {
        return state.accountsOverviewForClient.savingsAccounts;
      }

      return [];
    },

    // getActiveSavingsAccountsForClient
    getActiveSavingsAccountsForClient(state, getters) {
      // savingsAccountsForClient
      const savingsAccountsForClient = getters.getSavingsAccountsForClient;

      // activeSavingsAccounts
      const activeSavingsAccounts = savingsAccountsForClient.filter(
        (account) => account.status.active
      );

      return activeSavingsAccounts;
    },

    // getSubmittedAndPendingApprovalSavingsAccountsForClient
    getSubmittedAndPendingApprovalSavingsAccountsForClient(state, getters) {
      // savingsAccountsForClient
      const savingsAccountsForClient = getters.getSavingsAccountsForClient;

      // submittedAndPendingApprovalSavingsAccounts
      const submittedAndPendingApprovalSavingsAccounts =
        savingsAccountsForClient.filter(
          (account) => account.status.submittedAndPendingApproval
        );

      return submittedAndPendingApprovalSavingsAccounts;
    },

    // getApprovedSavingsAccountsForClient
    getApprovedSavingsAccountsForClient(state, getters) {
      // savingsAccountsForClient
      const savingsAccountsForClient = getters.getSavingsAccountsForClient;

      // approvedSavingsAccounts
      const approvedSavingsAccounts = savingsAccountsForClient.filter(
        (account) => account.status.approved
      );

      return approvedSavingsAccounts;
    },

    // getUnClosedSavingsAccountsForClient
    getUnClosedSavingsAccountsForClient(state, getters) {
      // savingsAccountsForClient
      const savingsAccountsForClient = getters.getSavingsAccountsForClient;

      // unClosedSavingsAccounts
      const unClosedSavingsAccounts = savingsAccountsForClient.filter(
        (account) => !account.status.closed
      );

      return unClosedSavingsAccounts;
    },

    // getFirstSavingsAccountForClient
    getFirstSavingsAccountForClient(state) {
      if (
        state.accountsOverviewForClient &&
        "savingsAccounts" in state.accountsOverviewForClient &&
        Array.isArray(state.accountsOverviewForClient.savingsAccounts) &&
        state.accountsOverviewForClient.savingsAccounts.length > 0
      ) {
        return state.accountsOverviewForClient.savingsAccounts.find(
          (account) => account.productName === "Wallet"
        );
      }

      return null;
    },

    // getFirstSavingsAccountBalanceForClient
    getFirstSavingsAccountBalanceForClient(state, getters) {
      // firstSavingsAccountForClient
      const firstSavingsAccountForClient =
        getters.getFirstSavingsAccountForClient;

      if (
        firstSavingsAccountForClient &&
        "accountBalance" in firstSavingsAccountForClient
      ) {
        return firstSavingsAccountForClient.accountBalance;
      }

      return 0;
    },

    // getFirstWalletSavingsAccountForClient
    getFirstWalletSavingsAccountForClient(state) {
      if (
        state.accountsOverviewForClient &&
        "savingsAccounts" in state.accountsOverviewForClient &&
        Array.isArray(state.accountsOverviewForClient.savingsAccounts) &&
        state.accountsOverviewForClient.savingsAccounts.length > 0
      ) {
        return state.accountsOverviewForClient.savingsAccounts.find(
          (account) => account.productName === "Wallet"
        );
      }

      return null;
    },

    // getFirstWalletSavingsAccountBalanceForClient
    getFirstWalletSavingsAccountBalanceForClient(state, getters) {
      // firstWalletSavingsAccountForClient
      const firstWalletSavingsAccountForClient =
        getters.getFirstWalletSavingsAccountForClient;

      if (
        firstWalletSavingsAccountForClient &&
        "accountBalance" in firstWalletSavingsAccountForClient
      ) {
        return firstWalletSavingsAccountForClient.accountBalance;
      }

      return 0;
    },

    // getFirstContributionSavingsAccountForClient
    getFirstContributionSavingsAccountForClient(state) {
      if (
        state.accountsOverviewForClient &&
        "savingsAccounts" in state.accountsOverviewForClient &&
        Array.isArray(state.accountsOverviewForClient.savingsAccounts) &&
        state.accountsOverviewForClient.savingsAccounts.length > 0
      ) {
        return state.accountsOverviewForClient.savingsAccounts.find(
          (account) => account.productName === "Contribution"
        );
      }

      return null;
    },

    // getFirstContributionSavingsAccountBalanceForClient
    getFirstContributionSavingsAccountBalanceForClient(state, getters) {
      // firstContributionSavingsAccountForClient
      const firstContributionSavingsAccountForClient =
        getters.getFirstContributionSavingsAccountForClient;

      if (
        firstContributionSavingsAccountForClient &&
        "accountBalance" in firstContributionSavingsAccountForClient
      ) {
        return firstContributionSavingsAccountForClient.accountBalance;
      }

      return 0;
    },

    // getSavingsAccountByIdForClient
    getSavingsAccountByIdForClient: (state) => (id) => {
      if (
        state.accountsOverviewForClient &&
        "savingsAccounts" in state.accountsOverviewForClient &&
        Array.isArray(state.accountsOverviewForClient.savingsAccounts) &&
        state.accountsOverviewForClient.savingsAccounts.length > 0
      ) {
        return state.accountsOverviewForClient.savingsAccounts.find(
          (savingsAccount) => savingsAccount.id === id
        );
      }

      return null;
    },

    monthlyContribution(state) {
      const transactions = state.clients;

      // Function to get the total amount for each month over the last 12 months
      function getTotalAmountByMonth(transactions) {
        const currentDate = new Date(); // Get the current date
        const currentYear = currentDate.getFullYear();
        const currentMonth = currentDate.getMonth() + 1; // Month is zero-indexed

        const monthlyTotal = {};

        // Iterate over the transactions
        transactions.forEach((transaction) => {
          const [year, month] = transaction.timeline.submittedOnDate;

          // Check if the transaction is within the last 12 months
          if (
            year >= currentYear - 1 && // Transactions from the last year and current year
            ((year === currentYear - 1 && month >= currentMonth) || // For transactions in the last year, check the month
              (year === currentYear && month <= currentMonth)) // For transactions in the current year, check the month
          ) {
            const key = `${year}-${month}`;
            monthlyTotal[key] = (monthlyTotal[key] || 0) + 1;
          }
        });

        return monthlyTotal;
      }

      const result = getTotalAmountByMonth(transactions);

      function transformObjectToArray(obj) {
        const currentYear = new Date().getFullYear();
        const currentMonth = new Date().getMonth() + 1; // Adding 1 because months are 0-indexed

        const resultArray = [];

        for (let i = 11; i >= 0; i--) {
          const year = currentMonth - i > 0 ? currentYear : currentYear - 1;
          const month = (currentMonth - i + 12) % 12 || 12;

          const key = `${year}-${month}`;

          resultArray.push(obj[key] || 0);
        }

        return resultArray;
      }

      const inputObject = result;
      const resultArray = transformObjectToArray(inputObject);

      return resultArray;
    },

    yearlyContribution(state) {
      const transactions = state.clients;

      // Function to get the total amount for each month over the last 12 months
      function getTotalAmountByMonth(transactions) {
        const currentDate = new Date(); // Get the current date
        const currentYear = currentDate.getFullYear();
        const currentMonth = currentDate.getMonth() + 1; // Month is zero-indexed

        const monthlyTotal = {};

        // Iterate over the transactions
        transactions.forEach((transaction) => {
          const [year, month] = transaction.timeline.submittedOnDate;

          // Check if the transaction is within the last 12 months
          if (
            year >= currentYear - 1 && // Transactions from the last year and current year
            ((year === currentYear - 1 && month >= currentMonth) || // For transactions in the last year, check the month
              (year === currentYear && month <= currentMonth)) // For transactions in the current year, check the month
          ) {
            const key = `${year}`;
            monthlyTotal[key] = (monthlyTotal[key] || 0) + 1;
          }
        });

        return monthlyTotal;
      }

      const result = getTotalAmountByMonth(transactions);

      function transformObjectToArray(obj) {
        const currentYear = new Date().getFullYear();
        const resultArray = [];

        for (let i = 0; i <= 7; i++) {
          const year = currentYear - i;
          const key = `${year}`;
          resultArray.push(obj[key] || 0);
        }

        return resultArray;
      }

      const inputObject = result;
      const resultArray = transformObjectToArray(inputObject);

      return resultArray.reverse();
    },

    monthlyContributionActive(state) {
      let user = state.clients.filter((user) => {
        return user.active === true;
      });
      const transactions = user;

      // Function to get the total amount for each month over the last 12 months
      function getTotalAmountByMonth(transactions) {
        const currentDate = new Date(); // Get the current date
        const currentYear = currentDate.getFullYear();
        const currentMonth = currentDate.getMonth() + 1; // Month is zero-indexed

        const monthlyTotal = {};

        // Iterate over the transactions
        transactions.forEach((transaction) => {
          const [year, month] = transaction.timeline.activatedOnDate;

          // Check if the transaction is within the last 12 months
          if (
            year >= currentYear - 1 && // Transactions from the last year and current year
            ((year === currentYear - 1 && month >= currentMonth) || // For transactions in the last year, check the month
              (year === currentYear && month <= currentMonth)) // For transactions in the current year, check the month
          ) {
            const key = `${year}-${month}`;
            monthlyTotal[key] = (monthlyTotal[key] || 0) + 1;
          }
        });

        return monthlyTotal;
      }

      const result = getTotalAmountByMonth(transactions);

      function transformObjectToArray(obj) {
        const currentYear = new Date().getFullYear();
        const currentMonth = new Date().getMonth() + 1; // Adding 1 because months are 0-indexed

        const resultArray = [];

        for (let i = 11; i >= 0; i--) {
          const year = currentMonth - i > 0 ? currentYear : currentYear - 1;
          const month = (currentMonth - i + 12) % 12 || 12;

          const key = `${year}-${month}`;

          resultArray.push(obj[key] || 0);
        }

        return resultArray;
      }

      const inputObject = result;
      const resultArray = transformObjectToArray(inputObject);

      return resultArray;
    },

    yearlyContributionActive(state) {
      let user = state.clients.filter((user) => {
        return user.active === true;
      });
      const transactions = user;

      // Function to get the total amount for each month over the last 12 months
      function getTotalAmountByMonth(transactions) {
        const currentDate = new Date(); // Get the current date
        const currentYear = currentDate.getFullYear();
        const currentMonth = currentDate.getMonth() + 1; // Month is zero-indexed

        const monthlyTotal = {};

        // Iterate over the transactions
        transactions.forEach((transaction) => {
          const [year, month] = transaction.timeline.activatedOnDate;

          // Check if the transaction is within the last 12 months
          if (
            year >= currentYear - 1 && // Transactions from the last year and current year
            ((year === currentYear - 1 && month >= currentMonth) || // For transactions in the last year, check the month
              (year === currentYear && month <= currentMonth)) // For transactions in the current year, check the month
          ) {
            const key = `${year}`;
            monthlyTotal[key] = (monthlyTotal[key] || 0) + 1;
          }
        });

        return monthlyTotal;
      }

      const result = getTotalAmountByMonth(transactions);

      function transformObjectToArray(obj) {
        const currentYear = new Date().getFullYear();
        const resultArray = [];

        for (let i = 0; i <= 7; i++) {
          const year = currentYear - i;
          const key = `${year}`;
          resultArray.push(obj[key] || 0);
        }

        return resultArray;
      }

      const inputObject = result;
      const resultArray = transformObjectToArray(inputObject);

      return resultArray.reverse();
    },

    monthlyContributionNotActive(state) {
      let user = state.clients.filter((user) => {
        return user.active === false;
      });
      const transactions = user;

      // Function to get the total amount for each month over the last 12 months
      function getTotalAmountByMonth(transactions) {
        const currentDate = new Date(); // Get the current date
        const currentYear = currentDate.getFullYear();
        const currentMonth = currentDate.getMonth() + 1; // Month is zero-indexed

        const monthlyTotal = {};

        // Iterate over the transactions
        transactions.forEach((transaction) => {
          const [year, month] = transaction.timeline.activatedOnDate;

          // Check if the transaction is within the last 12 months
          if (
            year >= currentYear - 1 && // Transactions from the last year and current year
            ((year === currentYear - 1 && month >= currentMonth) || // For transactions in the last year, check the month
              (year === currentYear && month <= currentMonth)) // For transactions in the current year, check the month
          ) {
            const key = `${year}-${month}`;
            monthlyTotal[key] = (monthlyTotal[key] || 0) + 1;
          }
        });

        return monthlyTotal;
      }

      const result = getTotalAmountByMonth(transactions);

      function transformObjectToArray(obj) {
        const currentYear = new Date().getFullYear();
        const currentMonth = new Date().getMonth() + 1; // Adding 1 because months are 0-indexed

        const resultArray = [];

        for (let i = 11; i >= 0; i--) {
          const year = currentMonth - i > 0 ? currentYear : currentYear - 1;
          const month = (currentMonth - i + 12) % 12 || 12;

          const key = `${year}-${month}`;

          resultArray.push(obj[key] || 0);
        }

        return resultArray;
      }

      const inputObject = result;
      const resultArray = transformObjectToArray(inputObject);

      return resultArray;
    },

    yearlyContributionNotActive(state) {
      let user = state.clients.filter((user) => {
        return user.active === false;
      });
      const transactions = user;

      // Function to get the total amount for each month over the last 12 months
      function getTotalAmountByMonth(transactions) {
        const currentDate = new Date(); // Get the current date
        const currentYear = currentDate.getFullYear();
        const currentMonth = currentDate.getMonth() + 1; // Month is zero-indexed

        const monthlyTotal = {};

        // Iterate over the transactions
        transactions.forEach((transaction) => {
          const [year, month] = transaction.timeline.activatedOnDate;

          // Check if the transaction is within the last 12 months
          if (
            year >= currentYear - 1 && // Transactions from the last year and current year
            ((year === currentYear - 1 && month >= currentMonth) || // For transactions in the last year, check the month
              (year === currentYear && month <= currentMonth)) // For transactions in the current year, check the month
          ) {
            const key = `${year}`;
            monthlyTotal[key] = (monthlyTotal[key] || 0) + 1;
          }
        });

        return monthlyTotal;
      }

      const result = getTotalAmountByMonth(transactions);

      function transformObjectToArray(obj) {
        const currentYear = new Date().getFullYear();
        const resultArray = [];

        for (let i = 0; i <= 7; i++) {
          const year = currentYear - i;
          const key = `${year}`;
          resultArray.push(obj[key] || 0);
        }

        return resultArray;
      }

      const inputObject = result;
      const resultArray = transformObjectToArray(inputObject);

      return resultArray.reverse();
    },
  },
};

export default clientApi;
