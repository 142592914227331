import apiHelperFactory from "@/http/apiHelperFactory";
// import helpers from "@/plugins/helpers";

const paymentTypeApi = {
  namespaced: true,

  state: {
    // paymentTypes
    paymentTypes: [],
  },

  mutations: {
    // setPaymentTypes
    setPaymentTypes(state, payload) {
      state.paymentTypes = payload;
    },

    // resetState
    resetState(state) {
      // paymentTypes
      state.paymentTypes = [];
    },
  },

  actions: {
    // getPaymentTypesReq
    async getPaymentTypesReq({ commit }, payload = {}) {
      // useLoading
      const useLoading = "useLoading" in payload ? payload.useLoading : true;

      // useLoading
      if (useLoading) {
        // startLoading
        commit("app/startLoading", null, { root: true });
      }

      // setUseAppToken
      commit("auth/setUseAppToken", true, { root: true });

      try {
        // getPaymentTypesRes
        const getPaymentTypesRes = await apiHelperFactory.getPaymentTypes();

        // setPaymentTypes
        commit("setPaymentTypes", getPaymentTypesRes.data);

        return getPaymentTypesRes;
      } catch (error) {
        if (error.response) {
          // handle response error
        } else if (error.request) {
          // handle request error
        } else {
          // handle other errors
        }
      } finally {
        // useLoading
        if (useLoading) {
          // stopLoading
          commit("app/stopLoading", null, { root: true });
        }

        // setUseAppToken
        commit("auth/setUseAppToken", false, { root: true });
      }
    },
  },

  getters: {
    // getPaymentTypes
    getPaymentTypes(state) {
      return state.paymentTypes;
    },

    // getFirstPaymentType
    getFirstPaymentType(state) {
      return state.paymentTypes.length > 0 ? state.paymentTypes[0] : null;
    },

    // getPaymentTypeNames
    getPaymentTypeNames(state) {
      return state.paymentTypes.map((paymentType) => paymentType.name);
    },

    //getPaymentTypeNameById
    getPaymentTypeNameById: (state) => (id) => {
      const paymentType = state.paymentTypes.find(
        (paymentType) => paymentType.id === id
      );

      return paymentType ? paymentType.name : null;
    },

    //getPaymentTypeById
    getPaymentTypeById: (state) => (id) => {
      const paymentType = state.paymentTypes.find(
        (paymentType) => paymentType.id === id
      );

      return paymentType ? paymentType : null;
    },

    // getPaymentTypeIdByName
    getPaymentTypeIdByName: (state) => (name) => {
      const paymentType = state.paymentTypes.find(
        (paymentType) => paymentType.name === name
      );

      return paymentType ? paymentType.id : null;
    },

    // getPaymentTypeByName
    getPaymentTypeByName: (state) => (name) => {
      const paymentType = state.paymentTypes.find(
        (paymentType) => paymentType.name === name
      );

      return paymentType ? paymentType : null;
    },
  },
};

export default paymentTypeApi;
