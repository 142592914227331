import apiHelperFactory from "@/http/apiHelperFactory";
// import helpers from "@/plugins/helpers";

const roleApi = {
  namespaced: true,

  state: {
    // roles
    roles: [],
  },

  mutations: {
    // setRoles
    setRoles(state, payload) {
      state.roles = payload;
    },

    // resetState
    resetState(state) {
      // roles
      state.roles = [];
    },
  },

  actions: {
    // getRolesReq
    async getRolesReq({ commit }, payload = {}) {
      // useLoading
      const useLoading = "useLoading" in payload ? payload.useLoading : true;

      // useLoading
      if (useLoading) {
        // startLoading
        commit("app/startLoading", null, { root: true });
      }

      // setUseAppToken
      commit("auth/setUseAppToken", true, { root: true });

      try {
        // getRolesRes
        const getRolesRes = await apiHelperFactory.getRoles();

        // setRoles
        commit("setRoles", getRolesRes.data);

        return getRolesRes;
      } catch (error) {
        if (error.response) {
          // handle response error
        } else if (error.request) {
          // handle request error
        } else {
          // handle other errors
        }
      } finally {
        // useLoading
        if (useLoading) {
          // stopLoading
          commit("app/stopLoading", null, { root: true });
        }

        // setUseAppToken
        commit("auth/setUseAppToken", false, { root: true });
      }
    },
  },

  getters: {
    // getRoles
    getRoles(state) {
      return state.roles;
    },

    // getRoleNames
    getRoleNames(state) {
      return state.roles.map((role) => role.name);
    },

    //getRoleNameById
    getRoleNameById: (state) => (id) => {
      const role = state.roles.find((role) => role.id === id);

      return role ? role.name : null;
    },

    //getRoleById
    getRoleById: (state) => (id) => {
      const role = state.roles.find((role) => role.id === id);

      return role ? role : null;
    },

    // getRoleIdByName
    getRoleIdByName: (state) => (name) => {
      const role = state.roles.find((role) => role.name === name);

      return role ? role.id : null;
    },

    // getRoleByName
    getRoleByName: (state) => (name) => {
      const role = state.roles.find((role) => role.name === name);

      return role ? role : null;
    },
  },
};

export default roleApi;
