import apiHelperFactory from "@/http/apiHelperFactory";
// import helpers from "@/plugins/helpers";

const importApi = {
  namespaced: true,

  state: {
    // imports
    imports: [],
  },

  mutations: {
    // setImports
    setImports(state, payload) {
      state.imports = payload;
    },

    // resetState
    resetState(state) {
      // imports
      state.imports = [];
    },
  },

  actions: {
    // getImportsReq
    async getImportsReq({ commit }, payload = {}) {
      // useLoading
      const useLoading = "useLoading" in payload ? payload.useLoading : true;

      // useLoading
      if (useLoading) {
        // startLoading
        commit("app/startLoading", null, { root: true });
      }

      // setUseAppToken
      commit("auth/setUseAppToken", true, { root: true });

      try {
        // params
        let params = {};

        // entityType
        if ("entityType" in payload) {
          params.entityType = payload.entityType;
        }

        // importsRes
        const importsRes = await apiHelperFactory.getImports(params);

        // setImports
        commit("setImports", importsRes.data);

        return importsRes;
      } catch (error) {
        if (error.response) {
          // handle response error
        } else if (error.request) {
          // handle request error
        } else {
          // handle other errors
        }
      } finally {
        // useLoading
        if (useLoading) {
          // stopLoading
          commit("app/stopLoading", null, { root: true });
        }

        // setUseAppToken
        commit("auth/setUseAppToken", false, { root: true });
      }
    },
  },

  getters: {
    // getImports
    getImports(state) {
      return state.imports;
    },
  },
};

export default importApi;
