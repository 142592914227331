const adminSetting = {
  namespaced: true,

  state: {
    // loanSetting
    showLoanSetting: true,
    showLoanSettingDialog: false,
    loanSettingStep: 1,
  },

  mutations: {
    // loanSetting
    setShowLoanSetting(state, payload) {
      state.showLoanSetting = payload;
    },
    setShowLoanSettingDialog(state, payload) {
      state.showLoanSettingDialog = payload;
    },
    setLoanSettingStep(state, payload) {
      state.loanSettingStep = payload;
    },

    // resetState
    resetState(state) {
      // loanSetting
      state.showLoanSetting = true;
      state.showLoanSettingDialog = false;
      state.loanSettingStep = 1;
    },
  },

  actions: {},

  getters: {
    // loanSetting
    getShowLoanSetting(state) {
      return state.showLoanSetting;
    },
    getShowLoanSettingDialog(state) {
      return state.showLoanSettingDialog;
    },
    getLoanSettingStep(state) {
      return state.loanSettingStep;
    },
  },
};

export default adminSetting;
