import apiHelperFactory from "@/http/apiHelperFactory";
import helpers from "@/plugins/helpers";

const codeValueApi = {
  namespaced: true,

  state: {
    // code
    code: {},

    // codeValues
    codeValues: [],

    // codeValuesList
    codeValuesList: [],
  },

  mutations: {
    // setCode
    setCode(state, payload) {
      state.code = payload;
    },

    // setCodeValues
    setCodeValues(state, payload) {
      state.codeValues = payload;
    },

    // setCodeValuesList
    setCodeValuesList(state, payload) {
      state.codeValuesList = payload;
    },

    // addCodeValues
    addCodeValues(state, payload) {
      // Find index of an existing item with the same code.name
      const index = state.codeValuesList.findIndex(
        (codeValue) => codeValue.code.name === payload.code.name
      );

      if (index === -1) {
        // If codeValue doesn't exist, add it
        state.codeValuesList.push(payload);
      } else {
        // If codeValue already exists, you can choose to update it or do nothing.
        // Here's how you would update it:
        state.codeValuesList[index] = payload;
      }
    },

    // resetState
    resetState(state) {
      // code
      state.code = {};

      // codeValues
      state.codeValues = [];

      // codeValuesList
      state.codeValuesList = [];
    },
  },

  actions: {
    // getCodeValuesReq
    async getCodeValuesReq({ commit }, payload = {}) {
      // useLoading
      const useLoading = "useLoading" in payload ? payload.useLoading : true;

      // useLoading
      if (useLoading) {
        // startLoading
        commit("app/startLoading", null, { root: true });
      }

      // setUseAppToken
      commit("auth/setUseAppToken", true, { root: true });

      try {
        // getCodeValuesRes
        const getCodeValuesRes = await apiHelperFactory.getCodeValues(
          payload.codeId
        );

        // setCodeValues
        commit("setCodeValues", getCodeValuesRes.data);

        return getCodeValuesRes;
      } catch (error) {
        if (error.response) {
          // handle response error
        } else if (error.request) {
          // handle request error
        } else {
          // handle other errors
        }
      } finally {
        // useLoading
        if (useLoading) {
          // stopLoading
          commit("app/stopLoading", null, { root: true });
        }

        // setUseAppToken
        commit("auth/setUseAppToken", false, { root: true });
      }
    },

    // getCodeValuesByCodeName
    async getCodeValuesByCodeName(
      { dispatch, commit, rootGetters },
      payload = {}
    ) {
      // useLoading
      const useLoading = "useLoading" in payload ? payload.useLoading : true;

      // useLoading
      if (useLoading) {
        // startLoading
        commit("app/startLoading", null, { root: true });
      }

      // setUseAppToken
      commit("auth/setUseAppToken", true, { root: true });

      // use the getter from codeApi module to get the code by its name
      const code = rootGetters["codeApi/getCodeByName"](payload.codeName);

      if (code && code.id) {
        try {
          // call getCodeValuesReq
          const getCodeValuesRes = await dispatch("getCodeValuesReq", {
            codeId: code.id,
            useLoading: false,
          });

          // check if getCodeValuesRes is successful and contains data
          if (getCodeValuesRes && getCodeValuesRes.data) {
            // use addCodeValues to store the details
            commit("addCodeValues", {
              code: code,
              codeValues: getCodeValuesRes.data,
            });
          }
        } catch (error) {
          // consoleError
          helpers.consoleError(error, "Error fetching code values:");
        } finally {
          // useLoading
          if (useLoading) {
            // stopLoading
            commit("app/stopLoading", null, { root: true });
          }

          // setUseAppToken
          commit("auth/setUseAppToken", false, { root: true });
        }
      }
    },

    // createCodeValueReq
    async createCodeValueReq({ commit }, payload = {}) {
      // useLoading
      const useLoading = "useLoading" in payload ? payload.useLoading : true;

      // useLoading
      if (useLoading) {
        // startLoading
        commit("app/startLoading", null, { root: true });
      }

      // setUseAppToken
      commit("auth/setUseAppToken", true, { root: true });

      try {
        return await apiHelperFactory.createCodeValue(
          payload.body,
          payload.codeId
        );
      } catch (error) {
        // error.response
        if (error.response) {
          let res = error.response;

          if (res.status === 400) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 401) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 403) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 404) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 500) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else {
            // handleError
            helpers.handleError(res.status, res.data);
          }
        } else {
          // handleError
          helpers.handleError();
        }
      } finally {
        // useLoading
        if (useLoading) {
          // stopLoading
          commit("app/stopLoading", null, { root: true });
        }

        // setUseAppToken
        commit("auth/setUseAppToken", false, { root: true });
      }
    },
  },

  getters: {
    // getCode
    getCode(state) {
      return state.code;
    },

    // getCodeValues
    getCodeValues(state) {
      return state.codeValues;
    },

    // getCodeValuesList
    getCodeValuesList(state) {
      return state.codeValuesList;
    },

    // getCodeValuesByCodeName
    getCodeValuesByCodeName: (state) => (codeName) => {
      const codeValue = state.codeValuesList.find(
        (codeValue) => codeValue.code.name === codeName
      );

      return codeValue ? codeValue.codeValues : null;
    },

    // getNameOfCodeValue
    getNameOfCodeValue: (state) => (codeName, codeValueId) => {
      // Convert codeValueId to integer
      const intCodeValueId = parseInt(codeValueId, 10);

      // Find the codeValue object with the matching code name
      const codeValueObj = state.codeValuesList.find(
        (codeValue) => codeValue.code.name === codeName
      );

      // If codeValueObj was found, find the codeValue inside codeValues with the matching ID
      if (codeValueObj) {
        const codeValue = codeValueObj.codeValues.find(
          (cv) => cv.id === intCodeValueId
        );

        // If codeValue was found, return its name
        return codeValue ? codeValue.name : null;
      }

      return null;
    },

    // getIdOfCodeValue
    getIdOfCodeValue: (state) => (codeName, codeValueName) => {
      // Find the codeValue object with the matching code name
      const codeValueObj = state.codeValuesList.find(
        (codeValue) => codeValue.code.name === codeName
      );

      // If codeValueObj was found, find the codeValue inside codeValues with the matching name
      if (codeValueObj) {
        const codeValue = codeValueObj.codeValues.find(
          (cv) => cv.name === codeValueName
        );

        // If codeValue was found, return its ID
        return codeValue ? codeValue.id : null;
      }

      return null;
    },

    // getCodeValue
    getCodeValue: (state) => (codeName, codeValueId) => {
      // Find the codeValue object with the matching code name
      const codeValueObj = state.codeValuesList.find(
        (codeValue) => codeValue.code.name === codeName
      );

      // If codeValueObj was found, find the codeValue inside codeValues with the matching ID
      if (codeValueObj) {
        return codeValueObj.codeValues.find((cv) => cv.id === codeValueId);
      }

      return null;
    },

    // getNamesOfCodeValues
    getNamesOfCodeValues: (state) => (codeName) => {
      // Find the codeValue object with the matching code name
      const codeValueObj = state.codeValuesList.find(
        (codeValue) => codeValue.code.name === codeName
      );

      // If codeValueObj was found, map codeValues to an array of names
      if (codeValueObj) {
        return codeValueObj.codeValues.map((cv) => cv.name);
      }

      return [];
    },
  },
};

export default codeValueApi;
