import apiHelperFactory from "@/http/apiHelperFactory";
import helpers from "@/plugins/helpers";

const clientDetailApi = {
  namespaced: true,

  state: {
    // clientDetails
    clientDetails: [],

    stokvelType: "",
  },

  mutations: {
    // setClientDetails
    setClientDetails(state, payload) {
      state.clientDetails = payload;
    },

    // setStokvelType
    setStokvelType(state, payload) {
      state.stokvelType = payload;
    },

    // resetState
    resetState(state) {
      // clientDetails
      state.clientDetails = [];

      // stokvelType
      state.stokvelType = "";
    },
  },

  actions: {
    // getClientDetailsReq
    async getClientDetailsReq({ commit }, payload = {}) {
      // useLoading
      const useLoading = "useLoading" in payload ? payload.useLoading : true;

      // useLoading
      if (useLoading) {
        // startLoading
        commit("app/startLoading", null, { root: true });
      }

      // setUseAppToken
      commit("auth/setUseAppToken", true, { root: true });

      try {
        // getClientDetailsRes
        const getClientDetailsRes = await apiHelperFactory.getClientDetails(
          payload.clientId
        );

        // setClientDetails
        commit("setClientDetails", getClientDetailsRes.data);

        return getClientDetailsRes;
      } catch (error) {
        if (error.response) {
          // handle response error
        } else if (error.request) {
          // handle request error
        } else {
          // handle other errors
        }
      } finally {
        // useLoading
        if (useLoading) {
          // stopLoading
          commit("app/stopLoading", null, { root: true });
        }

        // setUseAppToken
        commit("auth/setUseAppToken", false, { root: true });
      }
    },

    // createClientDetailReq
    async createClientDetailReq({ commit }, { payload, clientId }) {
      // startLoading
      commit("app/startLoading", null, { root: true });

      // setUseAppToken
      commit("auth/setUseAppToken", true, { root: true });

      try {
        return await apiHelperFactory.createClientDetail(payload, clientId);
      } catch (error) {
        // error.response
        if (error.response) {
          let res = error.response;

          if (res.status === 400) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 401) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 403) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 404) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 500) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else {
            // handleError
            helpers.handleError(res.status, res.data);
          }
        } else {
          // handleError
          helpers.handleError();
        }
      } finally {
        // setUseAppToken
        commit("auth/setUseAppToken", false, { root: true });

        // stopLoading
        commit("app/stopLoading", null, { root: true });
      }
    },

    // updateClientDetailReq
    async updateClientDetailReq({ commit }, { payload, clientId }) {
      // startLoading
      commit("app/startLoading", null, { root: true });

      // setUseAppToken
      commit("auth/setUseAppToken", true, { root: true });

      try {
        return await apiHelperFactory.updateClientDetail(payload, clientId);
      } catch (error) {
        // error.response
        if (error.response) {
          let res = error.response;

          if (res.status === 400) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 401) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 403) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 404) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 500) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else {
            // handleError
            helpers.handleError(res.status, res.data);
          }
        } else {
          // handleError
          helpers.handleError();
        }
      } finally {
        // setUseAppToken
        commit("auth/setUseAppToken", false, { root: true });

        // stopLoading
        commit("app/stopLoading", null, { root: true });
      }
    },

    // verifyUserReq
    async verifyUserReq({ commit }, { payload }) {
      // startLoading
      commit("app/startLoading", null, { root: true });

      // setUseAppToken
      commit("auth/setUseAppToken", true, { root: true });

      try {
        return await apiHelperFactory.verifyUser(payload);
      } catch (error) {
        // error.response
        if (error.response) {
          let res = error.response;

          if (res.status === 400) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 401) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 403) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 404) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 500) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else {
            // handleError
            helpers.handleError(res.status, res.data);
          }
        } else {
          // handleError
          helpers.handleError();
        }
      } finally {
        // setUseAppToken
        commit("auth/setUseAppToken", false, { root: true });

        // stopLoading
        commit("app/stopLoading", null, { root: true });
      }
    },
  },

  getters: {
    // getClientDetails
    getClientDetails(state) {
      return state.clientDetails;
    },

    // getStokvelType
    getStokvelType(state) {
      return state.stokvelType;
    },

    // getFirstClientDetail
    getFirstClientDetail(state) {
      return state.clientDetails.length > 0 ? state.clientDetails[0] : null;
    },

    // getAbout
    getAbout(state, getters) {
      // firstClientDetail
      const firstClientDetail = getters.getFirstClientDetail;

      if (firstClientDetail && "about" in firstClientDetail) {
        return firstClientDetail.about;
      }

      return null;
    },

    // getCountry
    getCountry(state, getters) {
      // firstClientDetail
      const firstClientDetail = getters.getFirstClientDetail;

      if (firstClientDetail && "country" in firstClientDetail) {
        return firstClientDetail.country;
      }

      return "Lesotho";
    },
  },
};

export default clientDetailApi;
