const snackbar = {
  namespaced: true,

  state: {
    // showSnackbar
    showSnackbar: false,

    // type
    type: "error",

    // message
    message: `Oops! An error occurred`,

    // timeout
    timeout: 5000,
  },

  mutations: {
    // setShowSnackbar
    setShowSnackbar(state, payload) {
      state.showSnackbar = payload;
    },

    // setType
    setType(state, payload) {
      state.type = payload;
    },

    // setMessage
    setMessage(state, payload) {
      state.message = payload;
    },

    // setTimeout
    setTimeout(state, payload) {
      state.timeout = payload;
    },

    // showMessage
    showMessage(state, payload) {
      // showSnackbar
      if ("showSnackbar" in payload) {
        state.showSnackbar = payload.showSnackbar;
      } else {
        state.showSnackbar = true;
      }

      // type
      if ("type" in payload) {
        state.type = payload.type;
      }

      // message
      if ("message" in payload) {
        state.message = payload.message;
      }

      // timeout
      if ("timeout" in payload) {
        state.timeout = payload.timeout;
      }
    },

    // resetState
    resetState(state) {
      // showSnackbar
      state.showSnackbar = false;

      // type
      state.type = "error";

      // message
      state.message = "Oops! An error occurred";

      // timeout
      state.timeout = 500;
    },
  },

  actions: {},

  getters: {
    // getShowSnackbar
    getShowSnackbar(state) {
      return state.showSnackbar;
    },

    // getType
    getType(state) {
      return state.type;
    },

    // getMessage
    getMessage(state) {
      return state.message;
    },

    // getTimeout
    getTimeout(state) {
      return state.timeout;
    },
  },
};

export default snackbar;
