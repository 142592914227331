import apiHelperFactory from "@/http/apiHelperFactory";
import helpers from "@/plugins/helpers";

const chargeApi = {
  namespaced: true,

  state: {
    // action
    action: "ChargesList",

    // chargeId
    chargeId: 0,

    // charge
    charge: {},

    // charges
    charges: [],
  },

  mutations: {
    // setAction
    setAction(state, payload) {
      state.action = payload;
    },

    // setChargeId
    setChargeId(state, payload) {
      state.chargeId = payload;
    },

    // setCharge
    setCharge(state, payload) {
      state.charge = payload;
    },

    // setCharges
    setCharges(state, payload) {
      state.charges = payload;
    },

    // resetState
    resetState(state) {
      // chargeId
      state.chargeId = 0;

      // charge
      state.charge = {};

      // charges
      state.charges = [];

      // action
      state.action = "ChargesList";
    },
  },

  actions: {
    // getChargesReq
    async getChargesReq({ commit }, payload = {}) {
      // useLoading
      const useLoading = "useLoading" in payload ? payload.useLoading : true;

      // useLoading
      if (useLoading) {
        // startLoading
        commit("app/startLoading", null, { root: true });
      }

      // setUseAppToken
      commit("auth/setUseAppToken", true, { root: true });

      try {
        // getChargesRes
        const getChargesRes = await apiHelperFactory.getCharges();

        // setCharges
        commit("setCharges", getChargesRes.data);

        return getChargesRes;
      } catch (error) {
        if (error.response) {
          // handle response error
        } else if (error.request) {
          // handle request error
        } else {
          // handle other errors
        }
      } finally {
        // useLoading
        if (useLoading) {
          // stopLoading
          commit("app/stopLoading", null, { root: true });
        }

        // setUseAppToken
        commit("auth/setUseAppToken", false, { root: true });
      }
    },

    // createChargeReq
    async createChargeReq({ commit }, payload = {}) {
      // useLoading
      const useLoading = "useLoading" in payload ? payload.useLoading : true;

      // useLoading
      if (useLoading) {
        // startLoading
        commit("app/startLoading", null, { root: true });
      }

      // setUseAppToken
      commit("auth/setUseAppToken", true, { root: true });

      try {
        return await apiHelperFactory.createCharge(payload.body);
      } catch (error) {
        // error.response
        if (error.response) {
          let res = error.response;

          if (res.status === 400) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 401) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 403) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 404) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 500) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else {
            // handleError
            helpers.handleError(res.status, res.data);
          }
        } else {
          // handleError
          helpers.handleError();
        }
      } finally {
        // useLoading
        if (useLoading) {
          // stopLoading
          commit("app/stopLoading", null, { root: true });
        }

        // setUseAppToken
        commit("auth/setUseAppToken", false, { root: true });
      }
    },

    // updateChargeReq
    async updateChargeReq({ commit }, payload = {}) {
      // useLoading
      const useLoading = "useLoading" in payload ? payload.useLoading : true;

      // useLoading
      if (useLoading) {
        // startLoading
        commit("app/startLoading", null, { root: true });
      }

      // setUseAppToken
      commit("auth/setUseAppToken", true, { root: true });

      try {
        return await apiHelperFactory.updateCharge(
          payload.body,
          payload.chargeId
        );
      } catch (error) {
        // error.response
        if (error.response) {
          let res = error.response;

          if (res.status === 400) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 401) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 403) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 404) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 500) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else {
            // handleError
            helpers.handleError(res.status, res.data);
          }
        } else {
          // handleError
          helpers.handleError();
        }
      } finally {
        // useLoading
        if (useLoading) {
          // stopLoading
          commit("app/stopLoading", null, { root: true });
        }

        // setUseAppToken
        commit("auth/setUseAppToken", false, { root: true });
      }
    },

    // deleteChargeReq
    async deleteChargeReq({ commit }, payload = {}) {
      // useLoading
      const useLoading = "useLoading" in payload ? payload.useLoading : true;

      // useLoading
      if (useLoading) {
        // startLoading
        commit("app/startLoading", null, { root: true });
      }

      // setUseAppToken
      commit("auth/setUseAppToken", true, { root: true });

      try {
        return await apiHelperFactory.deleteCharge(payload.chargeId);
      } catch (error) {
        // error.response
        if (error.response) {
          let res = error.response;

          if (res.status === 400) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 401) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 403) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 404) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 500) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else {
            // handleError
            helpers.handleError(res.status, res.data);
          }
        } else {
          // handleError
          helpers.handleError();
        }
      } finally {
        // useLoading
        if (useLoading) {
          // stopLoading
          commit("app/stopLoading", null, { root: true });
        }

        // setUseAppToken
        commit("auth/setUseAppToken", false, { root: true });
      }
    },
  },

  getters: {
    // getAction
    getAction(state) {
      return state.action;
    },

    // getChargeId
    getChargeId(state) {
      return state.chargeId;
    },

    // getCharge
    getCharge(state) {
      return state.charge;
    },

    // getChargeById
    getChargeById: (state) => (id) => {
      // charge
      const charge = state.charges.find((charge) => charge.id === id);

      return charge ? charge : {};
    },

    // getCharges
    getCharges(state) {
      return state.charges;
    },

    // getChargesForLoans
    getChargesForLoans(state) {
      return state.charges.filter(
        (charge) => charge.chargeAppliesTo.value === "Loan"
      );
    },

    // getChargeNamesForLoans
    getChargeNamesForLoans(state) {
      return state.charges
        .filter((charge) => charge.chargeAppliesTo.value === "Loan")
        .map((charge) => charge.name);
    },

    // getChargeNames
    getChargeNames(state) {
      return state.charges.map((charge) => charge.name);
    },

    // getChargeNamesWithAmount
    getChargeNamesWithAmount(state) {
      return state.charges.map(
        (charge) => `${charge.name} (${charge.amount.toFixed(2)})`
      );
    },

    // getChargeIdByName
    getChargeIdByName: (state) => (name) => {
      // chargeName
      const chargeName = name.split(" (")[0];

      // charge
      const charge = state.charges.find((charge) => charge.name === chargeName);

      return charge ? charge.id : null;
    },
  },
};

export default chargeApi;
