import apiHelperFactoryForBank from "@/http/apiHelperFactoryForBank";
import helpers from "@/plugins/helpers";

const investmentApiBank = {
  namespaced: true,

  state: {
    // investments
    investments: [],

    numPending: 0,
    numApproved: 0,

    // selectedValue
    selectedValue: null,
  },

  mutations: {
    // setInvestments
    setInvestments(state, payload) {
      state.investments = payload;
    },

    setNumPending(state, payload) {
      state.numPending = payload;
    },

    setNumApproved(state, payload) {
      state.numApproved = payload;
    },

    // setSelectedValue
    setSelectedValue(state, payload) {
      state.selectedValue = payload;
    },

    // resetState
    resetState(state) {
      // investments
      state.investments = [];
      state.numApproved = 0;
      state.numPending = 0;
    },
  },

  actions: {
    // getInvestmentsReq
    async getInvestmentsReq({ commit }, payload = {}) {
      // useLoading
      const useLoading = "useLoading" in payload ? payload.useLoading : true;

      // useLoading
      if (useLoading) {
        // startLoading
        commit("app/startLoading", null, { root: true });
      }

      // setUseAppToken
      commit("auth/setUseAppToken", true, { root: true });

      try {
        // getInvestmentsRes
        const getInvestmentsRes = await apiHelperFactoryForBank.getInvestments(
          payload
        );

        // setInvestments
        commit("setInvestments", getInvestmentsRes.data);

        let numApproved = 0;
        let numPending = 0;

        getInvestmentsRes.data.forEach((investment) => {
          if (investment.status && investment.status == "Approved") {
            numApproved++;
          }
          if (
            investment.status &&
            (investment.status == "Pending" || investment.status == "New")
          ) {
            numPending++;
          }
        });

        commit("setNumApproved", numApproved);
        commit("setNumPending", numPending);

        return getInvestmentsRes;
      } catch (error) {
        if (error.response) {
          // handle response error
        } else if (error.request) {
          // handle request error
        } else {
          // handle other errors
        }
      } finally {
        // useLoading
        if (useLoading) {
          // stopLoading
          commit("app/stopLoading", null, { root: true });
        }

        // setUseAppToken
        commit("auth/setUseAppToken", false, { root: true });
      }
    },

    // createBankInvestmentReq
    async createBankInvestmentReq({ commit }, { payload }) {
      // useLoading
      const useLoading = "useLoading" in payload ? payload.useLoading : true;

      // useLoading
      if (useLoading) {
        // startLoading
        commit("app/startLoading", null, { root: true });
      }

      // setUseAppToken
      commit("auth/setUseAppToken", true, { root: true });

      try {
        return await apiHelperFactoryForBank.createBankInvestment(payload);
      } catch (error) {
        // error.response
        if (error.response) {
          let res = error.response;

          if (res.status === 400) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 401) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 403) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 404) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 500) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else {
            // handleError
            helpers.handleError(res.status, res.data);
          }
        } else {
          // handleError
          helpers.handleError();
        }
      } finally {
        // useLoading
        if (useLoading) {
          // stopLoading
          commit("app/stopLoading", null, { root: true });
        }

        // setUseAppToken
        commit("auth/setUseAppToken", false, { root: true });
      }
    },

    // createStokvelWithDocumentReq
    async createStokvelWithDocumentReq({ commit }, { payload }) {
      // useLoading
      const useLoading = "useLoading" in payload ? payload.useLoading : true;
      // useLoading
      if (useLoading) {
        // startLoading
        commit("app/startLoading", null, { root: true });
      }

      // setUseAppToken
      commit("auth/setUseAppToken", true, { root: true });

      try {
        return await apiHelperFactoryForBank.createStokvelWithDocument(payload);
      } catch (error) {
        // error.response
        if (error.response) {
          let res = error.response;

          if (res.status === 400) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 401) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 403) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 404) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 500) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else {
            // handleError
            helpers.handleError(res.status, res.data);
          }
        } else {
          // handleError
          helpers.handleError();
        }
      } finally {
        // useLoading
        if (useLoading) {
          // stopLoading
          commit("app/stopLoading", null, { root: true });
        }

        // setUseAppToken
        commit("auth/setUseAppToken", false, { root: true });
      }
    },

    // updateInvestmentReq
    async updateInvestmentReq({ commit }, { payload, investmentId }) {
      // useLoading
      const useLoading = "useLoading" in payload ? payload.useLoading : true;

      // useLoading
      if (useLoading) {
        // startLoading
        commit("app/startLoading", null, { root: true });
      }

      // setUseAppToken
      commit("auth/setUseAppToken", true, { root: true });

      try {
        return await apiHelperFactoryForBank.updateInvestment(
          payload,
          investmentId
          // id
        );
      } catch (error) {
        // error.response
        if (error.response) {
          let res = error.response;

          if (res.status === 400) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 401) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 403) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 404) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 500) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else {
            // handleError
            helpers.handleError(res.status, res.data);
          }
        } else {
          // handleError
          helpers.handleError();
        }
      } finally {
        // useLoading
        if (useLoading) {
          // stopLoading
          commit("app/stopLoading", null, { root: true });
        }

        // setUseAppToken
        commit("auth/setUseAppToken", false, { root: true });
      }
    },
  },

  getters: {
    // getInvestments
    getInvestments(state) {
      return state.investments;
    },

    // getSelectedValue
    getSelectedValue(state) {
      return state.selectedValue;
    },

    // getFirstInvestment
    getFirstInvestment(state) {
      return state.investments.length > 0 ? state.investments[0] : null;
    },

    // getInvestmentById
    getInvestmentById: (state) => (id) => {
      // charge
      const investment = state.investments.find(
        (investment) => investment.id === id
      );

      return investment ? investment : {};
    },

    getInvestmentsByTenantIdentifier: (state) => (tenantIdentifier) => {
      let investments = [];

      state.investments.forEach((investment) => {
        if (investment.tenantIdentifier === tenantIdentifier) {
          investments.push(investment);
        }
      });

      return investments;
    },

    // getMappedInvestments super admin / superadmin
    getMappedInvestments(state) {
      // asset_manager
      return state.investments.map((investment) => {
        return {
          id: investment.id,
          // stokvelName: investment.assetManager,
          assetManager: investment.assetManager,
          amount: investment.amount,
          status: investment.status,
          type: investment.type,
          // stockvelId: investment.stockvelId,
          tenantIdentifier: investment.tenantIdentifier,
          opened: false,
          selected: false,
        };
      });
    },

    async getTotalValueOfInvestments(state, getters) {
      let investments = await getters.getMappedInvestments;
      let sum = 0;
      console.log("getTotalValueOfInvestments", investments);
      investments.forEach((investment) => {
        sum += investment.amount;
      });

      return sum;
    },

    async getTotalValueOfInvestmentsPrevYear(state, getters) {
      let sum = 0;
      let investments = await getters.getInvestments;

      const currentDate = new Date(); // Get the current date
      let currentYear = currentDate.getFullYear();

      let previousYear = currentYear - 1;

      // Iterate over the investments
      investments.forEach((investment) => {
        let date = investment.createdAt; // returns date in ISO format: 2023-09-28T11:53:51.716766

        // year is first 4 characters of date (positions are zero indexed)
        let year = date.substr(0, 4);

        // investments that were created in the previous year
        if (year <= previousYear) sum += investment.amount;
      });
      return sum;
    },

    getNumberPendingInvestments(state) {
      return state.numPending;
    },

    getNumberApprovedInvestments(state) {
      return state.numApproved;
    },

    // getNumberPendingInvestments(state, getters) {
    //   let investments = state.investments;
    //   console.log("investments", investments);
    //   return investments.reduce((sum, investment) => {
    //     if (
    //       investment.status &&
    //       (investment.status == "Pending" || investment.status == "New")
    //     ) {
    //       return sum + 1;
    //     }
    //     return sum;
    //   }, 0);
    // },

    // getNumberApprovedInvestments(state, getters) {
    //   let investments = state.investments;
    //   return investments.reduce((sum, investment) => {
    //     if (investment.status && investment.status == "Approved") {
    //       return sum + 1;
    //     }
    //     return sum;
    //   }, 0);
    // },
  },
};

export default investmentApiBank;
