const memberForgotPassword = {
  namespaced: true,

  state: {
    slider: "slider-1",
    showMobileForm: false,
  },

  mutations: {
    // setSlider
    setSlider(state, payload) {
      state.slider = payload;
    },

    // setShowMobileForm
    setShowMobileForm(state, payload) {
      state.showMobileForm = payload;
    },

    // resetState
    resetState(state) {
      state.slider = "slider-1";
      state.showMobileForm = false;
    },
  },

  actions: {},

  getters: {
    // getSlider
    getSlider(state) {
      return state.slider;
    },

    // getShowMobileForm
    getShowMobileForm(state) {
      return state.showMobileForm;
    },
  },
};

export default memberForgotPassword;
