import store from "../store";
import router from "@/router";
import moment from "moment";
import * as XLSX from "xlsx";
import { parsePhoneNumberFromString } from "libphonenumber-js";

const countryCallingCodeToIso = {
  "+27": "ZA", // South Africa
  "+266": "LS", // Lesotho
};

// formatMoney
function formatMoney(money, includeDecimal = true, removeR = false) {
  try {
    // result
    let result = "";

    // Handle empty string
    if (money === "") {
      return "R";
    }

    // Handle money as a string - remove spaces and currency symbol
    if (typeof money === "string") {
      money = money.replace(/[\sR]/g, "");
    }

    // Attempt to convert money to a number
    let numberMoney = Number(money);

    // Check if money is a number or a string representing a number
    if (isNaN(numberMoney)) {
      throw new Error(
        `Invalid input: ${money} is not a number or a string representation of a number`
      );
    }

    // Handle includeDecimal
    if (includeDecimal) {
      let fixedMoney = numberMoney.toFixed(2);
      result = fixedMoney.replace(/\B(?=(\d{3})+(?!\d))/g, " ");
      result = "R" + result;
    } else {
      result = "R" + numberMoney.toLocaleString().replaceAll(",", " ");
    }

    // Handle removeR
    if (removeR) {
      result = result.replaceAll("R", "");
    }

    return result;
  } catch (error) {
    if (error) {
      // console.error(error);
    }

    return "";
  }
}

// getMaxContentHeight
function getMaxContentHeight(breakpointName) {
  switch (breakpointName) {
    case "xs":
      return "350";
    case "sm":
      return "270";
    case "md":
      return "250";
    case "lg":
      return "250";
    default:
      return "510";
  }
}

// randomBoolean
function randomBoolean() {
  return Math.random() >= 0.5;
}

// Utility function to sort an array of objects by the specified property and order
function sortArrayByProperty(array, property, order) {
  return array.sort((a, b) => {
    if (a[property] < b[property]) {
      return order === "asc" ? -1 : 1;
    }

    if (a[property] > b[property]) {
      return order === "asc" ? 1 : -1;
    }

    return 0;
  });
}

// truncateString
function truncateString(str, maxLength) {
  if (str.length > maxLength) {
    return str.slice(0, maxLength) + "...";
  } else {
    return str;
  }
}

// toCamelCase
function toCamelCase(str) {
  return str
    .replace(/\s+/g, " ") // Remove extra spaces
    .toLowerCase() // Convert to lowercase
    .replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, (match, index) => {
      if (+match === 0) return ""; // Remove extra spaces
      return index === 0 ? match.toLowerCase() : match.toUpperCase(); // Convert first character to uppercase
    });
}

// toPascalCase
function toPascalCase(str) {
  return str
    .replace(/\s+/g, " ") // Remove extra spaces
    .replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, (match) => {
      if (+match === 0) return ""; // Remove extra spaces
      return match.toUpperCase(); // Convert first character to uppercase
    });
}

// toSnakeCase
function toSnakeCase(str) {
  return str
    .replace(/\s+/g, " ") // Remove extra spaces
    .replace(/([a-z])([A-Z])/g, "$1 $2") // Add space between camelCase words
    .toLowerCase() // Convert to lowercase
    .replace(/\s+/g, "_"); // Replace spaces with underscores
}

// noFunctionality
function noFunctionality() {
  store.commit("alert/showAlert", {
    showAlert: true,
    type: "error",
    title: "No Functionality",
    message: "This functionality is not available yet.",
  });
}

// handleError
/**
 * @param {number} status - The number
 * @param {Object} data - The object
 */
function handleError(
  status = 500,
  data = {
    message: "Something went wrong please try again!",
  },
  customTitle = null,
  customMessage = null
) {
  // console.log("handleError status", status);
  // console.log("handleError message", data.message);

  let title = "";
  let message = "";

  if (status == 400) {
    // customTitle
    if (customTitle) {
      title = customTitle;
    } else {
      // Invalid Parameter or Data Integrity Issue.
      title = "Invalid Parameter or Data Integrity Issue.";
    }

    // customMessage
    if (customMessage) {
      message = customMessage;
    } else if ("errors" in data && data.errors.length > 0) {
      let errors = data.errors;

      message = errors[0].defaultUserMessage;
    } else if ("defaultUserMessage" in data) {
      message = data.defaultUserMessage;
    } else {
      // Invalid input detected. Please review your data
      message = "Invalid input detected. Please review your data.";
    }
  } else if (status == 401) {
    // errors
    if ("errors" in data) {
      //
    }

    // customTitle
    if (customTitle) {
      title = customTitle;
    } else {
      // Authentication Error.
      title = "Authentication Error.";
    }

    // customMessage
    if (customMessage) {
      message = customMessage;
    } else if (
      data.errors &&
      data.errors.length > 0 &&
      "defaultUserMessage" in data.errors[0]
    ) {
      message = data.errors[0].defaultUserMessage;
    } else if ("defaultUserMessage" in data) {
      message = data.defaultUserMessage;
    } else {
      // Access denied. Please confirm your authorization.
      message = "Access denied. Please confirm your authorization.";
    }
  } else if (status == 403) {
    // customTitle
    if (customTitle) {
      title = customTitle;
    } else {
      // Unauthorized Request.
      title = "Unauthorized Request.";
    }

    // customMessage
    if (customMessage) {
      message = customMessage;
    } else if (
      data.errors &&
      data.errors.length > 0 &&
      "defaultUserMessage" in data.errors[0]
    ) {
      message = data.errors[0].defaultUserMessage;
    } else if ("defaultUserMessage" in data) {
      message = data.defaultUserMessage;
    } else {
      // Access denied. Please confirm your authorization.
      message = "Access denied. Please confirm your authorization.";
    }
  } else if (status == 404) {
    // customTitle
    if (customTitle) {
      title = customTitle;
    } else {
      // Resource Not Found
      title = "Resource Not Found";
    }

    // customMessage
    if (customMessage) {
      message = customMessage;
    } else if (
      data.errors &&
      data.errors.length > 0 &&
      "defaultUserMessage" in data.errors[0]
    ) {
      message = data.errors[0].defaultUserMessage;
    } else if ("defaultUserMessage" in data) {
      message = data.defaultUserMessage;
    } else {
      // Resource unavailable. Please check the URL
      message = "Resource unavailable. Please check the URL";
    }
  } else if (status == 422) {
    // customTitle
    if (customTitle) {
      title = customTitle;
    } else {
      // Unprocessable Content
      title = "Unprocessable Content";
    }

    // customMessage
    if (customMessage) {
      message = customMessage;
    } else if (
      data.errors &&
      data.errors.length > 0 &&
      "defaultUserMessage" in data.errors[0]
    ) {
      message = data.errors[0].defaultUserMessage;
    } else if ("message" in data) {
      message = data.message;
    } else if ("defaultUserMessage" in data) {
      message = data.defaultUserMessage;
    } else {
      // The server can't process your request, although it understands it.
      message =
        "The server can't process your request, although it understands it.";
    }
  } else if (status == 500) {
    // customTitle
    if (customTitle) {
      title = customTitle;
    } else {
      // Internal Server Error!
      title = "Internal Server Error!";
    }

    // customMessage
    if (customMessage) {
      message = customMessage;
    } else if (
      data.errors &&
      data.errors.length > 0 &&
      "defaultUserMessage" in data.errors[0]
    ) {
      message = data.errors[0].defaultUserMessage;
    } else if ("defaultUserMessage" in data) {
      message = data.defaultUserMessage;
    } else {
      // Apologies, internal server issue. Please retry
      message = "Apologies, internal server issue. Please retry";
    }
  } else if (status == 503) {
    // customTitle
    if (customTitle) {
      title = customTitle;
    } else {
      // Service Temporarily Unavailable!!
      title = "Service Temporarily Unavailable!!";
    }

    // customMessage
    if (customMessage) {
      message = customMessage;
    } else if ("defaultUserMessage" in data) {
      message = data.defaultUserMessage;
    } else {
      // Service down for maintenance, check back later.
      message = "Service down for maintenance, check back later.";
    }
  } else {
    // customTitle
    if (customTitle) {
      title = customTitle;
    } else {
      // Internal Server Error!
      title = "Internal Server Error!!!";
    }

    // customMessage
    if (customMessage) {
      message = customMessage;
    } else if ("defaultUserMessage" in data) {
      message = data.defaultUserMessage;
    } else {
      // Apologies, internal server issue. Please retry
      message = "Apologies, internal server issue. Please retry";
    }
  }

  // alert/showAlert
  store.commit("alert/showAlert", {
    showAlert: true,
    type: "error",
    title: title,
    message: message,
  });
}

// validateSAID
function validateSAID(idNumber) {
  // Check the length
  if (idNumber.length !== 13) {
    return false;
  }

  // Check the date
  let year = parseInt(idNumber.slice(0, 2), 10);
  let currentYear = new Date().getFullYear() % 100;
  year += year > currentYear ? 1900 : 2000; // interpret as 1900s or 2000s year
  let month = parseInt(idNumber.slice(2, 4), 10) - 1; // Months are zero-based
  let day = parseInt(idNumber.slice(4, 6), 10);
  let genderDigit = parseInt(idNumber.slice(6, 10), 10);

  if (
    month < 0 ||
    month > 11 ||
    day < 1 ||
    day > 31 ||
    genderDigit < 0 ||
    genderDigit > 9999
  ) {
    return false;
  }

  // Check date validity considering month and leap year
  let birthDate = new Date(year, month, day);
  if (
    !(
      birthDate &&
      birthDate.getMonth() === month &&
      birthDate.getDate() === day
    )
  ) {
    return false;
  }

  // Luhn algorithm for check digit
  let checkDigit = idNumber[12];
  let checkSum = 0;
  let multiplier = 2;

  for (let i = 11; i >= 0; --i) {
    let digit = idNumber[i] * multiplier;
    checkSum += digit > 9 ? digit - 9 : digit;
    multiplier = multiplier === 2 ? 1 : 2;
  }

  let computedCheckDigit = (10 - (checkSum % 10)) % 10;

  if (computedCheckDigit !== parseInt(checkDigit, 10)) {
    return false;
  }

  // If we've made it this far, the ID number is valid
  return true;
}

// validateLesothoID
function validateLesothoID(idNumber) {
  // idNumber
  if (idNumber) {
    // pattern
    const pattern = /^\d{10,20}$/;

    return pattern.test(idNumber);
  }

  return true;
}

// validateUsernameOrEmail
function validateUsernameOrEmail(v) {
  const usernameRegex = /^[a-zA-Z0-9@._]+$/;
  const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
  return usernameRegex.test(v) || emailRegex.test(v);
}

// getInitials
function getInitials(fullName) {
  fullName = fullName.trim().replace(/\s+/g, " ");
  let names = fullName.split(" ");
  let initials = names[0].substring(0, 1).toUpperCase();

  if (names.length > 1) {
    initials += names[names.length - 1].substring(0, 1).toUpperCase();
  }

  return initials;
}

// hideStart
function hideStart(inputString, visibleLength = 2) {
  // Determine how many characters to hide
  const hideLength = inputString.replace(/\s+/g, "").length - visibleLength;

  // Check if there is enough characters to hide
  if (hideLength <= 0) return inputString;

  // Replace the characters
  let hideCount = 0;
  let outputString = "";
  for (let i = 0; i < inputString.length; i++) {
    if (inputString[i] !== " " && hideCount < hideLength) {
      outputString += "*";
      hideCount++;
    } else {
      outputString += inputString[i];
    }
  }

  return outputString;
}

// hideEnd
function hideEnd(inputString, visibleLength = 2) {
  // Determine how many characters to hide
  const hideLength = inputString.replace(/\s+/g, "").length - visibleLength;

  // Check if there is enough characters to hide
  if (hideLength <= 0) return inputString;

  // Replace the characters
  let hideCount = 0;
  let outputString = "";
  for (let i = inputString.length - 1; i >= 0; i--) {
    if (inputString[i] !== " " && hideCount < hideLength) {
      outputString = "*" + outputString;
      hideCount++;
    } else {
      outputString = inputString[i] + outputString;
    }
  }

  return outputString;
}

function hideMiddle(inputString, visibleLengthStart = 2, visibleLengthEnd = 2) {
  // Determine how many characters to hide
  const hideLength =
    inputString.replace(/\s+/g, "").length -
    visibleLengthStart -
    visibleLengthEnd;

  // Check if there is enough characters to hide
  if (hideLength <= 0) return inputString;

  // Replace the characters
  let hideCount = 0;
  let outputString = "";
  let leftVisibleCount = visibleLengthStart;
  let rightVisibleCount = visibleLengthEnd;
  for (let i = 0; i < inputString.length; i++) {
    if (inputString[i] !== " ") {
      if (leftVisibleCount > 0) {
        outputString += inputString[i];
        leftVisibleCount--;
      } else if (hideCount < hideLength) {
        outputString += "*";
        hideCount++;
      } else if (rightVisibleCount > 0) {
        outputString += inputString[i];
        rightVisibleCount--;
      }
    } else {
      outputString += inputString[i];
    }
  }

  return outputString;
}

// consoleLog
function consoleLog(data = {}, message = "No Message") {
  // message
  console.log("message", message);

  // data
  console.log("data", data);
}

// consoleError
function consoleError(data = {}, message = "No Message") {
  // message
  console.log("message", message);

  // data
  console.error("data", data);
}

// stringifyJson
function stringifyJson(jsonData) {
  try {
    return JSON.stringify(jsonData);
  } catch (error) {
    console.error("Failed to stringify JSON data:", error);

    return null;
  }
}

// isObjectEmpty
function isObjectEmpty(obj) {
  if (typeof obj === "object" && obj !== null && obj.constructor === Object) {
    return Object.keys(obj).length === 0;
  }

  return false;
}

// formatDate
function formatDate(dateArray, format = "DD MMMM YYYY") {
  // Check if dateArray is valid
  if (!dateArray || dateArray.length !== 3) {
    return "";
  }

  // Create a new date object
  let date = new Date(dateArray[0], dateArray[1] - 1, dateArray[2]);

  // Use moment.js to format the date
  return moment(date).format(format);
}

// isDaysAfter
function isDaysAfter(dateString, numberOfDays, format = "DD MMMM YYYY") {
  try {
    if (!dateString || !numberOfDays || !format) {
      throw new Error("Invalid input parameters.");
    }

    // Parse the date string
    let date = moment(dateString, format);

    // Add the specified number of days to the date
    let daysAfter = date.clone().add(numberOfDays, "days");

    // Get today's date
    let today = moment();

    // Check if today is the specified number of days after the original date
    return today.isSameOrAfter(daysAfter, "day");
  } catch (error) {
    if (error) {
      // console.error(error);
    }

    return false;
  }
}

// addDays
function addDays(dateString, numberOfDays, format = "DD MMMM YYYY") {
  try {
    if (!dateString || !numberOfDays || !format) {
      throw new Error("Invalid input parameters.");
    }

    // Parse the date string
    let date = moment(dateString, format);

    // Add the specified number of days to the date
    let newDate = date.clone().add(numberOfDays, "days");

    // Return the new date in the original format
    return newDate.format(format);
  } catch (error) {
    if (error) {
      // console.error(error);
    }

    return null;
  }
}

// jsonStringToObject
function jsonStringToObject(jsonString) {
  try {
    if (!jsonString) {
      throw new Error("Invalid input parameter.");
    }

    // Parse the JSON string into a JavaScript object
    let obj = JSON.parse(jsonString);

    // Return the object
    return obj;
  } catch (error) {
    if (error) {
      // console.error(error);
    }

    return null;
  }
}

// isEmptyArray
function isEmptyArray(data) {
  try {
    // Check if data is an array
    if (!Array.isArray(data)) {
      throw new Error("The passed argument is not an array.");
    }
    // Check if the array is empty
    return data.length === 0;
  } catch (error) {
    if (error) {
      // console.error(error);
    }

    // If an error occurs, we consider it as no data
    return true;
  }
}

// mapLoanStatus
function mapLoanStatus(status) {
  try {
    // Validate input
    if (typeof status !== "string" || status.trim() === "") {
      return "Unknown";
    }

    if (status === "Submitted and pending approval") {
      return "Pending Approval";
    }

    /* if (status == "Active") {
      return "Active";
    } else if (status == "Closed (written off)") {
      return "Paid";
    } else if (status == "Declined") {
      return "New";
    } else if (status == "Rejected" || status == "Withdrawn by applicant") {
      return "New";
    } else if (status == "Submitted and pending approval") {
      return "Active";
    }

    return "New"; */
    return status;
  } catch (error) {
    if (error) {
      // console.error(error);
    }

    // If an error occurs, we consider it as no data
    return "Unknown";
  }
}

// mapLoanStatusPercentage
function mapLoanStatusPercentage(status) {
  try {
    // Validate input
    if (typeof status !== "string" || status.trim() === "") {
      return 0;
    }

    if (status === "Active") {
      return 90;
    } else if (status === "Approved") {
      return 70;
    } else if (status === "Closed (obligations met)") {
      return 100;
    } else if (status === "Closed (rescheduled)") {
      return 100;
    } else if (status === "Closed (written off)") {
      return 100;
    } else if (status === "Closed Restructured") {
      return 100;
    } else if (status === "Invalid") {
      return 7;
    } else if (status === "Overpaid") {
      return 100;
    } else if (status === "Rejected") {
      return 0;
    } else if (status === "Restructured") {
      return 75;
    } else if (
      status === "Submitted and pending approval" ||
      status === "Pending Approval"
    ) {
      return 50;
    } else if (status === "Transfer in progress") {
      return 90;
    } else if (status === "Transfer on hold") {
      return 90;
    } else if (status === "Withdrawn by applicant") {
      return 0;
    } else {
      return 0;
    }
  } catch (error) {
    if (error) {
      // console.error(error);
    }

    return 0;
  }
}

// mapLoanColor
function mapLoanColor(status) {
  try {
    // Validate input
    if (typeof status !== "string" || status.trim() === "") {
      return "yellow900";
    }

    if (status === "Active") {
      return "green500";
    } else if (status === "Approved") {
      return "green500";
    } else if (status === "Closed (obligations met)") {
      return "green500";
    } else if (status === "Closed (rescheduled)") {
      return "green500";
    } else if (status === "Closed (written off)") {
      return "green500";
    } else if (status === "Closed Restructured") {
      return "green500";
    } else if (status === "Invalid") {
      return "red900";
    } else if (status === "Overpaid") {
      return "green500";
    } else if (status === "Rejected") {
      return "red900";
    } else if (status === "Restructured") {
      return "yellow900";
    } else if (
      status === "Submitted and pending approval" ||
      status === "Pending Approval"
    ) {
      return "yellow900";
    } else if (status === "Transfer in progress") {
      return "green500";
    } else if (status === "Transfer on hold") {
      return "green500";
    } else if (status === "Withdrawn by applicant") {
      return "red900";
    } else {
      return "yellow900";
    }
  } catch (error) {
    if (error) {
      // console.error(error);
    }

    return 0;
  }
}

// getLoanStatusId
function getLoanStatusId(status) {
  try {
    // Validate input
    if (typeof status !== "string" || status.trim() === "") {
      return 0;
    }

    if (status === "Active") {
      return 300;
    } else if (status === "Approved") {
      return 200;
    } else if (status === "Closed (obligations met)") {
      return 600;
    } else if (status === "Closed (rescheduled)") {
      return 602;
    } else if (status === "Closed (written off)") {
      return 601;
    } else if (status === "Closed Restructured") {
      return 709;
    } else if (status === "Invalid") {
      return 0;
    } else if (status === "Overpaid") {
      return 700;
    } else if (status === "Rejected") {
      return 500;
    } else if (status === "Restructured") {
      return 702;
    } else if (
      status === "Submitted and pending approval" ||
      status === "Pending Approval"
    ) {
      return 100;
    } else if (status === "Transfer in progress") {
      return 303;
    } else if (status === "Transfer on hold") {
      return 304;
    } else if (status === "Withdrawn by applicant") {
      return 400;
    } else {
      return 0;
    }
  } catch (error) {
    if (error) {
      // console.error(error);
    }

    return 0;
  }
}

// getLoanStatuses
function getLoanStatuses() {
  return [
    {
      title: "Active",
      textColor: "green500--text",
      normalClass: "paid-status",
      activeClass: "paid-status-active",
      active: false,
    },
    {
      title: "Approved",
      textColor: "green500--text",
      normalClass: "paid-status mt-1",
      activeClass: "paid-status-active",
      active: false,
    },
    {
      title: "Closed (obligations met)",
      textColor: "green500--text",
      normalClass: "paid-status mt-1",
      activeClass: "paid-status-active",
      active: false,
    },
    {
      title: "Closed (rescheduled)",
      textColor: "green500--text",
      normalClass: "paid-status mt-1",
      activeClass: "paid-status-active",
      active: false,
    },
    {
      title: "Closed (written off)",
      textColor: "green500--text",
      normalClass: "paid-status mt-1",
      activeClass: "paid-status-active",
      active: false,
    },
    {
      title: "Closed Restructured",
      textColor: "green500--text",
      normalClass: "paid-status mt-1",
      activeClass: "paid-status-active",
      active: false,
    },
    {
      title: "Invalid",
      textColor: "red900--text",
      normalClass: "new-status mt-1",
      activeClass: "new-status-active",
      active: false,
    },
    {
      title: "Overpaid",
      textColor: "green500--text",
      normalClass: "paid-status mt-1",
      activeClass: "paid-status-active",
      active: false,
    },
    {
      title: "Rejected",
      textColor: "red900--text",
      normalClass: "new-status mt-1",
      activeClass: "new-status-active",
      active: false,
    },
    {
      title: "Restructured",
      textColor: "yellow900--text",
      normalClass: "active-status mt-1",
      activeClass: "active-status-active",
      active: false,
    },
    {
      title: "Pending Approval",
      textColor: "yellow900--text",
      normalClass: "active-status mt-1",
      activeClass: "active-status-active",
      active: false,
    },
    {
      title: "Transfer in progress",
      textColor: "green500--text",
      normalClass: "paid-status mt-1",
      activeClass: "paid-status-active",
      active: false,
    },
    {
      title: "Transfer on hold",
      textColor: "green500--text",
      normalClass: "paid-status mt-1",
      activeClass: "paid-status-active",
      active: false,
    },
    {
      title: "Withdrawn by applicant",
      textColor: "red900--text",
      normalClass: "new-status mt-1",
      activeClass: "new-status-active",
      active: false,
    },
  ];
}

// getLoanBackgroundColor
function getLoanBackgroundColor(title) {
  try {
    // Convert the title to lowercase and trim white spaces
    title = title.toLowerCase().trim();

    // Get the statuses from the function
    const statuses = getLoanStatuses();

    // Find the statue with the matching title
    const status = statuses.find(
      (status) => status.title.toLowerCase().trim() === title
    );

    // If a matching statue is found, return the first part of the normalClass
    if (status && "normalClass" in status) {
      return status.normalClass.split(" ")[0];
    }
  } catch (error) {
    if (error) {
      // console.error(error);
    }

    return "";
  }
}

// readExcelFile
async function readExcelFile(file) {
  return new Promise((resolve, reject) => {
    if (!(file instanceof File || file instanceof Blob)) {
      reject(new Error("The provided object is not a File or Blob object"));
    }

    const reader = new FileReader();

    reader.onload = (e) => {
      try {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: "array" });
        const worksheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[worksheetName];
        const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

        resolve(jsonData);
      } catch (err) {
        reject(err);
      }
    };

    reader.onerror = (err) => {
      reject(err);
    };

    reader.readAsArrayBuffer(file);
  });
}

// editExcelFile
async function editExcelFile(file, edits) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: "array" });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];

      // Iterate through edits and apply them to the worksheet
      for (let i = 0; i < edits.length; i++) {
        const { cellAddress, newCellValue } = edits[i];

        const cell = sheet[cellAddress];

        if (cell) {
          // Change the cell's value
          if (typeof newCellValue === "boolean") {
            cell.t = "b";
            cell.v = newCellValue ? 1 : 0;
          } else {
            cell.v = newCellValue;
          }
        } else {
          // Create new cell if not exist
          if (typeof newCellValue === "boolean") {
            sheet[cellAddress] = { t: "b", v: newCellValue ? 1 : 0 };
          } else {
            sheet[cellAddress] = { t: "s", v: newCellValue };
          }
        }
      }

      // Convert workbook to binary string
      const binaryString = XLSX.write(workbook, {
        bookType: "xls",
        type: "binary",
      });

      // Convert binary string to Blob
      const binaryArray = new Uint8Array(binaryString.length);

      for (let i = 0; i < binaryString.length; i++) {
        binaryArray[i] = binaryString.charCodeAt(i);
      }

      const blob = new Blob([binaryArray], {
        type: "application/vnd.ms-excel",
      });

      resolve(blob);
    };

    reader.onerror = reject;

    reader.readAsArrayBuffer(file);
  });
}

// createExcelFile
async function createExcelFile(data) {
  // Convert data to a worksheet
  const ws = XLSX.utils.aoa_to_sheet(data);

  // Create a new workbook and add the worksheet
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

  // Convert workbook to binary array
  const wbout = XLSX.write(wb, { bookType: "xlsx", type: "array" });

  // Create a Blob from the binary array
  const blob = new Blob([wbout], { type: "application/octet-stream" });

  // Return the Blob object
  // return blob;

  // Create a link element to download the file
  const link = document.createElement("a");
  link.href = URL.createObjectURL(blob);
  link.download = "data.xlsx";
  link.click();

  // Clean up
  URL.revokeObjectURL(link.href);
}

// validatePhoneNumber
function validatePhoneNumber(phoneNumber, countryCode) {
  // Remove all white spaces from the phone number
  phoneNumber = phoneNumber.replace(/\s+/g, "");

  // If no country code is provided, you can default to a specific country code like '+27'
  countryCode = countryCode || "+27";

  // Map the country calling code to the corresponding ISO code
  const isoCountryCode = countryCallingCodeToIso[countryCode];

  try {
    const parsedPhoneNumber = parsePhoneNumberFromString(
      phoneNumber,
      isoCountryCode
    );

    if (!parsedPhoneNumber) return false;

    return parsedPhoneNumber.isValid();
  } catch (error) {
    // Handle any parsing errors
    return false;
  }
}

// unauthorizedAccess
function unauthorizedAccess() {
  // showAlert
  store.commit("alert/showAlert", {
    showAlert: true,
    type: "error",
    title: "Unauthorized Access",
    message: "Denied entry due to lack of proper authorization or permission.",
  });
}

// checkAdminAccess
function checkAdminAccess() {
  if (store.state.authBank.authenticated && !store.state.auth.authenticated) {
    // role
    const role = store.state.authBank.role;

    if (role === "Super Admin") {
      // GlobalAdminOverview
      router.push({ name: "GlobalAdminOverview" });
    } else if (role === "Admin") {
      // GlobalAdminMyStokvels
      router.push({ name: "GlobalAdminMyStokvels" });
    }
  } else {
    // isSuperUser
    const isSuperUser = store.getters["auth/getIsSuperUser"];

    // canGlobalAdmin
    const canGlobalAdmin = store.getters["auth/getCan"]("Global_Admin");

    // canAdmin
    const canAdmin = store.getters["auth/getCan"]("Admin");

    if (!canGlobalAdmin && !canAdmin && !isSuperUser) {
      // unauthorizedAccess
      unauthorizedAccess();

      // MemberOverview
      router.push({ name: "MemberOverview" });
    }
  }
}

// checkSuperAdminAccess
function checkSuperAdminAccess() {
  // authBank.authenticated
  if (store.state.authBank.authenticated) {
    // role
    const role = store.state.authBank.role;

    if (role !== "Super Admin" && role !== "Admin") {
      // auth/logOut
      store.dispatch("auth/logOut");

      // setShowMobileSidebar
      store.commit("mobileSidebar/setShowMobileSidebar", false);

      // Home
      router.push({ name: "Home" });
    }

    return;
  } else if (!store.state.authBank.authenticated) {
    // AdminOverview
    router.push({ name: "AdminOverview" });

    return;
  }

  // isSuperUser
  const isSuperUser = store.getters["auth/getIsSuperUser"];

  // canGlobalAdmin
  const canGlobalAdmin = store.getters["auth/getCan"]("Global_Admin");

  // canAdmin
  const canAdmin = store.getters["auth/getCan"]("Admin");

  if (canGlobalAdmin) {
    // unauthorizedAccess
    unauthorizedAccess();

    // AdminOverview
    router.push({ name: "AdminOverview" });
  } else if (canAdmin) {
    // unauthorizedAccess
    unauthorizedAccess();

    // AdminOverview
    router.push({ name: "AdminOverview" });
  } else if (!isSuperUser) {
    // unauthorizedAccess
    unauthorizedAccess();

    // MemberOverview
    router.push({ name: "MemberOverview" });
  }
}

// checkSuperAdminOnlyAccess
function checkSuperAdminOnlyAccess() {
  if (store.state.authBank.authenticated) {
    // role
    const role = store.state.authBank.role;

    if (role !== "Super Admin") {
      // GlobalAdminMyStokvels
      router.push({ name: "GlobalAdminMyStokvels" });
    }

    return;
  }
}

// checkMemberAccess
function checkMemberAccess() {
  if (store.state.authBank.authenticated) {
    // role
    const role = store.state.authBank.role;

    if (role === "Super Admin") {
      // GlobalAdminOverview
      router.push({ name: "GlobalAdminOverview" });
    } else if (role === "Admin") {
      // GlobalAdminMyStokvels
      router.push({ name: "GlobalAdminMyStokvels" });
    }

    return;
  }
}

// checkPublicAccess
function checkPublicAccess() {
  if (store.state.authBank.authenticated) {
    // role
    const role = store.state.authBank.role;

    if (role === "Super Admin") {
      // GlobalAdminOverview
      router.push({ name: "GlobalAdminOverview" });
    } else if (role === "Admin") {
      // GlobalAdminMyStokvels
      router.push({ name: "GlobalAdminMyStokvels" });
    }

    return;
  } else if (store.state.auth.authenticated) {
    // AdminOverview
    router.push({ name: "AdminOverview" });
  }
}

export default {
  formatMoney,
  getMaxContentHeight,
  randomBoolean,
  sortArrayByProperty,
  truncateString,
  toCamelCase,
  toPascalCase,
  toSnakeCase,
  noFunctionality,
  handleError,
  validateSAID,
  validateLesothoID,
  validateUsernameOrEmail,
  getInitials,

  hideStart,
  hideEnd,
  hideMiddle,

  consoleLog,
  consoleError,

  stringifyJson,
  isObjectEmpty,

  formatDate,
  isDaysAfter,
  addDays,
  jsonStringToObject,
  isEmptyArray,

  mapLoanStatus,
  mapLoanStatusPercentage,
  mapLoanColor,
  getLoanStatusId,

  getLoanStatuses,
  getLoanBackgroundColor,

  readExcelFile,
  editExcelFile,
  createExcelFile,

  validatePhoneNumber,

  unauthorizedAccess,
  checkAdminAccess,
  checkSuperAdminAccess,
  checkSuperAdminOnlyAccess,
  checkMemberAccess,
  checkPublicAccess,
};
