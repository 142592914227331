<template>
  <v-app>
    <v-main>
      <loading-overlay
        :active="app.loading"
        :is-full-page="true"
        :can-cancel="false"
        :color="'#000'"
        :background-color="'#192028'"
        :opacity="0.25"
        loader="dots"
      ></loading-overlay>

      <router-view />
    </v-main>

    <!-- MainAlert -->
    <MainAlert />
  </v-app>
</template>

<script>
import { AbilityBuilder, Ability } from "@casl/ability";
import { mapMutations, mapState } from "vuex";

import MainAlert from "@/components/shared/alerts/MainAlert.vue";

export default {
  name: "App",

  components: {
    MainAlert,
  },

  data: () => ({
    //
  }),

  computed: {
    ...mapState({ app: "app" }),
  },

  created() {
    // set permissions
    if (this.$store.state.auth.permissions) {
      let permissions = this.$store.state.auth.permissions;

      const { can, rules } = new AbilityBuilder(Ability);

      can(permissions);

      this.$ability.update(rules);
    }

    this.$store.commit("alert/resetState");

    // stopLoading
    this.$store.commit("app/stopLoading");

    this.resetMobileSidebar();

    // setUseAppWebUserToken
    this.$store.commit("auth/setUseAppWebUserToken", false);
  },

  methods: {
    ...mapMutations({
      // resetMobileSidebar
      resetMobileSidebar: "mobileSidebar/resetState",
    }),
  },
};
</script>
