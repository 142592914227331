<template>
  <div>
    <v-dialog
      v-if="showAlert"
      v-model="alert.showAlert"
      class="br-16 bs-none"
      content-class="elevation-0"
      :max-width="alert.maxWidth"
      overlay-color="black600"
      overlay-opacity="0.25"
      persistent
    >
      <v-card class="bg-none br-16" :max-width="alert.maxWidth" flat>
        <div class="main-card br-16 pa-4 pa-sm-8">
          <!-- Close -->
          <v-layout justify-end align-start>
            <!-- Close -->
            <v-img
              @click="close()"
              class="pointer"
              src="@/assets/img/member/loans/apply-for-a-loan/successful-close.png"
              max-width="24"
              max-height="24"
              contain
            />
          </v-layout>

          <!-- Main -->
          <v-layout class="mx-4 mt-n3 text-center" column align-center>
            <!-- Image -->
            <v-img
              :src="imgSrc"
              :max-width="imgWidthAndHeight"
              :max-height="imgWidthAndHeight"
              contain
            />

            <!-- Title -->
            <v-card
              class="mt-6"
              color="transparent"
              :max-width="alert.maxTitleWidth"
              flat
            >
              <!-- Title -->
              <h3 class="h3 fw-800 fs-m-20 lh-m-22">
                {{ alert.title }}
              </h3>
            </v-card>

            <!-- Message -->
            <v-card
              class="mt-6"
              color="transparent"
              :max-width="alert.maxMessageWidth"
              flat
            >
              <!-- Message -->
              <h6 class="b-1 fs-m-12 lh-m-16">
                {{ alert.message }}
              </h6>
            </v-card>

            <!-- Btn -->
            <v-card
              color="transparent"
              width="100%"
              :max-width="alert.maxBtnWidth"
              flat
            >
              <!-- Btn -->
              <v-btn
                @click="close()"
                class="btn mt-6 mb-5"
                width="100%"
                :height="btnHeight"
                elevation="0"
              >
                <h5 class="h5 white--text text-transform-none">Okay</h5>
              </v-btn>
            </v-card>
          </v-layout>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";

export default {
  name: "AllianceUiMainAlert",

  data() {
    return {};
  },

  computed: {
    ...mapState({ alert: "alert" }),

    // showAlert
    showAlert() {
      return this.alert.showAlert;
    },

    // imgWidthAndHeight
    imgWidthAndHeight() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "90px";
        default:
          return "118px";
      }
    },

    // btnHeight
    btnHeight() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "48px";
        default:
          return "56px";
      }
    },

    // imgSrc
    imgSrc() {
      if (this.alert.type == "success") {
        return require("@/assets/img/member/loans/apply-for-a-loan/successful-img.png");
      } else if (this.alert.type == "error") {
        return require("@/assets/img/member/loans/apply-for-a-loan/error-img.png");
      } else if (this.alert.type == "warning") {
        return require("@/assets/img/member/loans/apply-for-a-loan/successful-img.png");
      } else if (this.alert.type == "info") {
        return require("@/assets/img/member/loans/apply-for-a-loan/successful-img.png");
      } else {
        return require("@/assets/img/member/loans/apply-for-a-loan/successful-img.png");
      }
    },
  },

  watch: {
    // alert.showAlert
    "alert.showAlert"(val) {
      this.setShowAlert(val);
    },
  },

  mounted() {},

  methods: {
    ...mapMutations({
      // setShowAlert
      setShowAlert: "alert/setShowAlert",

      // resetAlertState
      resetAlertState: "alert/resetState",
    }),

    // close
    close() {
      this.resetAlertState();
    },
  },
};
</script>

<style lang="scss" scoped>
//
</style>
