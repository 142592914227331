const investmentProcessing = {
  namespaced: true,

  state: {
    // showInvestmentDialog
    showInvestmentDialog: false,

    // investmentDialogStep
    investmentDialogStep: 1,

    // investmentAmount
    investmentAmount: 0,

    // investmentBalance
    investmentBalance: 0,

    // decline
    // showInvestmentProcessingDeclineDialog
    showInvestmentProcessingDeclineDialog: false,
    investmentProcessingDeclineDialogStep: 1,
    showInvestmentProcessingDeclineMobileForm: false,

    // approve
    showInvestmentProcessingApproveDialog: false,
    investmentProcessingApproveDialogStep: 1,
    showInvestmentProcessingApproveMobileForm: false,

    // calculator
    showCalculatorDialog: true,
    calculatorDialogStep: 1,

    // amount
    amount: 38000,
    amountMin: 2000,
    amountInterval: 50,
    amountMax: 50000,

    // Loan amount
    loanAmountRequest: 30000,

    // months
    months: 6,
    monthsMin: 0,
    monthsInterval: 1,
    monthsMax: 72,
  },

  mutations: {
    // setShowInvestmentDialog
    setShowInvestmentDialog(state, payload) {
      state.showInvestmentDialog = payload;
    },

    // setInvestmentDialogStep
    setInvestmentDialogStep(state, payload) {
      state.investmentDialogStep = payload;
    },

    // setInvestmentAmount
    setInvestmentAmount(state, payload) {
      state.investmentAmount = payload;
    },

    // setInvestmentBalance
    setInvestmentBalance(state, payload) {
      state.investmentBalance = payload;
    },

    // decline
    setShowInvestmentProcessingDeclineDialog(state, payload) {
      state.showInvestmentProcessingDeclineDialog = payload;
    },

    setInvestmentProcessingDeclineDialogStep(state, payload) {
      state.investmentProcessingDeclineDialogStep = payload;
    },

    setShowInvestmentProcessingDeclineMobileForm(state, payload) {
      state.showInvestmentProcessingDeclineMobileForm = payload;
    },

    // approve
    setShowInvestmentProcessingApproveDialog(state, payload) {
      state.showInvestmentProcessingApproveDialog = payload;
    },

    setInvestmentProcessingApproveDialogStep(state, payload) {
      state.investmentProcessingApproveDialogStep = payload;
    },

    setShowInvestmentProcessingApproveMobileForm(state, payload) {
      state.showInvestmentProcessingApproveMobileForm = payload;
    },

    // calculator
    setShowCalculatorDialog(state, payload) {
      state.showCalculatorDialog = payload;
    },

    setShowCalculatorDialogStep(state, payload) {
      state.calculatorDialogStep = payload;
    },

    // setAmount
    setAmount(state, payload) {
      state.amount = payload;
    },

    // setLoanAmount
    setLoanAmount(state, payload) {
      state.loanAmountRequest = payload;
    },

    // setMonths
    setMonths(state, payload) {
      state.months = payload;
    },

    // clearCalculator
    clearCalculator(state) {
      state.amount = 38000;
      state.loanAmountRequest = 30000;
      state.months = 6;
      state.interestRate = 8;
    },

    // resetState
    resetState(state) {
      // showInvestmentDialog
      state.showInvestmentDialog = false;

      // investmentDialogStep
      state.investmentDialogStep = 1;

      // investmentAmount
      state.investmentAmount = 0;

      // investmentBalance
      state.investmentBalance = 0;

      // decline
      state.showInvestmentProcessingDeclineDialog = false;
      state.investmentProcessingDeclineDialogStep = 1;
      state.showInvestmentProcessingDeclineMobileForm = false;

      // approve
      state.showInvestmentProcessingApproveDialog = false;
      state.investmentProcessingApproveDialogStep = 1;
      state.showInvestmentProcessingApproveMobileForm = false;

      // calculator
      state.showCalculatorDialog = false;
      state.calculatorDialogStep = 1;

      // amount
      state.amount = 38000;
      state.amountMin = 2000;
      state.amountInterval = 50;
      state.amountMax = 50000;

      // Loan amount
      state.loanAmountRequest = 30000;

      // months
      state.months = 6;
      state.monthsMin = 0;
      state.monthsInterval = 1;
      state.monthsMax = 72;
    },
  },

  actions: {},

  getters: {
    // getShowInvestmentDialog
    getShowInvestmentDialog(state) {
      return state.showInvestmentDialog;
    },

    // getInvestmentDialogStep
    getInvestmentDialogStep(state) {
      return state.investmentDialogStep;
    },

    // getInvestmentAmount
    getInvestmentAmount(state) {
      return state.investmentAmount;
    },

    // getInvestmentBalance
    getInvestmentBalance(state) {
      return state.investmentBalance;
    },

    // decline
    getShowInvestmentProcessingDeclineDialog(state) {
      return state.showInvestmentProcessingDeclineDialog;
    },

    getInvestmentProcessingDeclineStep(state) {
      return state.investmentProcessingDeclineDialogStep;
    },

    getShowInvestmentProcessingDeclineMobileForm(state) {
      return state.showInvestmentProcessingDeclineMobileForm;
    },

    // approve
    getShowInvestmentProcessingApproveDialog(state) {
      return state.showInvestmentProcessingApproveDialog;
    },

    getInvestmentProcessingApproveStep(state) {
      return state.investmentProcessingApproveDialogStep;
    },

    getShowInvestmentProcessingApproveMobileForm(state) {
      return state.showInvestmentProcessingApproveMobileForm;
    },

    // Calculator
    getShowCalculatorDialog(state) {
      return state.showCalculatorDialog;
    },

    getCalculatorStep(state) {
      return state.calculatorDialogStep;
    },

    // getAmount
    getAmount(state) {
      return state.amount;
    },

    // getAmount
    getLoanAmount(state) {
      return state.loanAmountRequest;
    },

    // getMonths
    getMonths(state) {
      return state.months;
    },
  },
};

export default investmentProcessing;
