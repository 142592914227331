const transaction = {
  namespaced: true,

  state: {
    // showWithdrawalDialog
    showWithdrawalDialog: false,

    // withdrawalDialogStep
    withdrawalDialogStep: 1,

    // withdrawalAmount
    withdrawalAmount: "",

    // showTransactionDialog
    showTransactionDialog: false,

    // showTopUpDialog
    showTopUpDialog: false,

    // transactionDialogStep
    transactionDialogStep: 2,

    // topUpDialogStep
    topUpDialogStep: 1,

    // showTopUpMobileForm
    showTopUpMobileForm: false,

    // showTransactionMobileForm
    showTransactionMobileForm: false,
  },

  mutations: {
    // setShowWithdrawalDialog
    setShowWithdrawalDialog(state, payload) {
      state.showWithdrawalDialog = payload;
    },

    // setWithdrawalDialogStep
    setWithdrawalDialogStep(state, payload) {
      state.withdrawalDialogStep = payload;
    },

    // setWithdrawalAmount
    setWithdrawalAmount(state, payload) {
      state.withdrawalAmount = payload;
    },

    // setShowTransactionDialog
    setShowTransactionDialog(state, payload) {
      state.showTransactionDialog = payload;
    },

    // ShowTopUpDialog
    setShowTopUpDialog(state, payload) {
      state.showTopUpDialog = payload;
    },

    // setTransactionDialogStep
    setTransactionDialogStep(state, payload) {
      state.transactionDialogStep = payload;
    },

    setTopUpDialogStep(state, payload) {
      state.topUpDialogStep = payload;
    },

    // setShowTopUpMobileForm
    setShowTopUpMobileForm(state, payload) {
      state.showTopUpMobileForm = payload;
    },

    // setShowTransactionMobileForm
    setShowTransactionMobileForm(state, payload) {
      state.showTransactionMobileForm = payload;
    },

    // resetState
    resetState(state) {
      // showWithdrawalDialog
      state.showWithdrawalDialog = false;

      // withdrawalDialogStep
      state.withdrawalDialogStep = 1;

      // withdrawalAmount
      state.withdrawalAmount = "";

      // showTransactionDialog
      state.showTransactionDialog = false;

      // showTopUpDialog
      state.showTopUpDialog = false;

      // transactionDialogStep
      state.transactionDialogStep = 2;

      // topUpDialogStep
      state.topUpDialogStep = 1;

      // showTopUpMobileForm
      state.showTopUpMobileForm = false;

      // showTransactionMobileForm
      state.showTransactionMobileForm = false;
    },
  },

  actions: {},

  getters: {
    // getShowWithdrawalDialog
    getShowWithdrawalDialog(state) {
      return state.showWithdrawalDialog;
    },

    // getWithdrawalDialogStep
    getWithdrawalDialogStep(state) {
      return state.withdrawalDialogStep;
    },

    // getWithdrawalAmount
    getWithdrawalAmount(state) {
      return state.withdrawalAmount;
    },

    // getShowTransactionDialog
    getShowTransactionDialog(state) {
      return state.showTransactionDialog;
    },

    // getShowTransactionDialog
    getShowTopUpDialog(state) {
      return state.showTopUpDialog;
    },
    // getTransactionStep
    getTransactionStep(state) {
      return state.transactionDialogStep;
    },

    // getTransactionStep
    getTopUpStep(state) {
      return state.topUpDialogStep;
    },

    getShowTopUpMobileForm(state) {
      return state.showTopUpMobileForm;
    },

    getShowTransactionMobileForm(state) {
      return state.showTransactionMobileForm;
    },
  },
};

export default transaction;
