import apiHelperFactory from "@/http/apiHelperFactory";
import helpers from "@/plugins/helpers";

const employmentVerificationApi = {
  namespaced: true,

  state: {
    // employmentVerificationId
    employmentVerificationId: 0,

    // employmentVerifications
    employmentVerifications: [],
  },

  mutations: {
    // setEmploymentVerificationId
    setEmploymentVerificationId(state, payload) {
      state.employmentVerificationId = payload;
    },

    // setEmploymentVerifications
    setEmploymentVerifications(state, payload) {
      state.employmentVerifications = payload;
    },

    // resetState
    resetState(state) {
      // employmentVerificationId
      state.employmentVerificationId = 0;

      // employmentVerifications
      state.employmentVerifications = [];
    },
  },

  actions: {
    // getEmploymentVerificationsReq
    async getEmploymentVerificationsReq({ commit }, payload = {}) {
      // useLoading
      const useLoading = "useLoading" in payload ? payload.useLoading : true;

      // useLoading
      if (useLoading) {
        // startLoading
        commit("app/startLoading", null, { root: true });
      }

      // setUseAppToken
      commit("auth/setUseAppToken", true, { root: true });

      try {
        // getEmploymentVerificationsRes
        const getEmploymentVerificationsRes =
          await apiHelperFactory.getEmploymentVerifications(payload.clientId);

        // setEmploymentVerifications
        commit(
          "setEmploymentVerifications",
          getEmploymentVerificationsRes.data
        );

        return getEmploymentVerificationsRes;
      } catch (error) {
        if (error.response) {
          // handle response error
        } else if (error.request) {
          // handle request error
        } else {
          // handle other errors
        }
      } finally {
        // useLoading
        if (useLoading) {
          // stopLoading
          commit("app/stopLoading", null, { root: true });
        }

        // setUseAppToken
        commit("auth/setUseAppToken", false, { root: true });
      }
    },

    // createEmploymentVerificationReq
    async createEmploymentVerificationReq({ commit }, { payload, clientId }) {
      // startLoading
      commit("app/startLoading", null, { root: true });

      // setUseAppToken
      commit("auth/setUseAppToken", true, { root: true });

      try {
        return await apiHelperFactory.createEmploymentVerification(
          payload,
          clientId
        );
      } catch (error) {
        // error.response
        if (error.response) {
          let res = error.response;

          if (res.status === 400) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 401) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 403) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 404) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 500) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else {
            // handleError
            helpers.handleError(res.status, res.data);
          }
        } else {
          // handleError
          helpers.handleError();
        }
      } finally {
        // setUseAppToken
        commit("auth/setUseAppToken", false, { root: true });

        // stopLoading
        commit("app/stopLoading", null, { root: true });
      }
    },

    // updateEmploymentVerificationReq
    async updateEmploymentVerificationReq(
      { commit },
      { payload, clientId, employmentVerificationId }
    ) {
      // startLoading
      commit("app/startLoading", null, { root: true });

      // setUseAppToken
      commit("auth/setUseAppToken", true, { root: true });

      try {
        return await apiHelperFactory.updateEmploymentVerification(
          payload,
          clientId,
          employmentVerificationId
        );
      } catch (error) {
        // error.response
        if (error.response) {
          let res = error.response;

          if (res.status === 400) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 401) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 403) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 404) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 500) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else {
            // handleError
            helpers.handleError(res.status, res.data);
          }
        } else {
          // handleError
          helpers.handleError();
        }
      } finally {
        // setUseAppToken
        commit("auth/setUseAppToken", false, { root: true });

        // stopLoading
        commit("app/stopLoading", null, { root: true });
      }
    },

    // deleteEmploymentVerificationReq
    async deleteEmploymentVerificationReq({ commit }, { payload }) {
      // startLoading
      commit("app/startLoading", null, { root: true });

      // setUseAppToken
      commit("auth/setUseAppToken", true, { root: true });

      try {
        return await apiHelperFactory.deleteEmploymentVerification(
          payload.clientId,
          payload.employmentVerificationId
        );
      } catch (error) {
        // error.response
        if (error.response) {
          let res = error.response;

          if (res.status === 400) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 401) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 403) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 404) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 500) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else {
            // handleError
            helpers.handleError(res.status, res.data);
          }
        } else {
          // handleError
          helpers.handleError();
        }
      } finally {
        // setUseAppToken
        commit("auth/setUseAppToken", false, { root: true });

        // stopLoading
        commit("app/stopLoading", null, { root: true });
      }
    },
  },

  getters: {
    // getEmploymentVerificationId
    getEmploymentVerificationId(state) {
      return state.employmentVerificationId;
    },

    // getEmploymentVerifications
    getEmploymentVerifications(state) {
      return state.employmentVerifications;
    },

    // getFirstEmploymentVerification
    getFirstEmploymentVerification(state) {
      return state.employmentVerifications.length > 0
        ? state.employmentVerifications[0]
        : null;
    },

    // getEmploymentVerificationById
    getEmploymentVerificationById: (state) => (id) => {
      let foundEmploymentVerification = state.employmentVerifications.find(
        (employmentVerification) => employmentVerification.id === id
      );

      return foundEmploymentVerification ? foundEmploymentVerification : null;
    },
  },
};

export default employmentVerificationApi;
