const authSliders = {
  namespaced: true,

  state: {
    slider: "slider-1",
    sliderMobile: "slider-1",
    step: "step-1",

    showMobileForm: false,
  },

  mutations: {
    // setStep
    setStep(state, payload) {
      state.step = payload;
    },

    // setSlider
    setSlider(state, payload) {
      state.slider = payload;
    },

    // setSliderMobile
    setSliderMobile(state, payload) {
      state.sliderMobile = payload;
    },

    // setShowMobileForm
    setShowMobileForm(state, payload) {
      state.showMobileForm = payload;
    },

    // resetState
    resetState(state) {
      state.step = "step-1";

      state.slider = "slider-1";
      state.sliderMobile = "slider-1";
      state.showMobileForm = false;
    },
  },

  actions: {},

  getters: {
    // getStep
    getStep(state) {
      return state.step;
    },

    // getSlider
    getSlider(state) {
      return state.slider;
    },

    // getSliderMobile
    getSliderMobile(state) {
      return state.sliderMobile;
    },

    // getShowMobileForm
    getShowMobileForm(state) {
      return state.showMobileForm;
    },
  },
};

export default authSliders;
